import { IonCol, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import { close } from 'ionicons/icons';
import type { ComponentProps } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../..';
import { networking } from '../../../../api/networking';
import { useAppSelector } from '../../../../hooks';

interface ComingSoonProps {
  open: boolean | undefined;
  title: string;
  message: string;
  dismiss: () => void;
  icon?: ComponentProps<typeof IonIcon>['icon'];
  startButton?: {
    label: string;
    onClick: () => void;
  }
}

const ComingSoon: React.FC<ComingSoonProps> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [interestShown, setInterestShown] = useState<boolean>(false);
  const projectId = useAppSelector(state => state.project.selectedProject?.id);

  const handleDismiss = () => {
    setInterestShown(false);
    props.dismiss();
  };

  const handleShowInterestSubmit = (feature: string) => {
    if (interestShown) {
      handleDismiss();
      return;
    }
    setLoading(true);
    networking.post('/api/v1/me/request_feature', {
      project_id: projectId || '',
      feature_request: feature
    })
      .then(() => {
        setInterestShown(true);
      })
      .catch((error: Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BigUp.Modal.Wrapper
      isOpen={props.open ?? false}
      dismiss={handleDismiss}
      height='400px'
      header={{
        title: props.title,
        end: {
          onClick: handleDismiss,
          icon: close
        },
      }}
    >
      <IonGrid>
        <IonRow className='ion-justify-content-center'>
          <IonCol size='auto'>
            <BigUp.Widget.ButtonContainer >
              <IonIcon
                icon={interestShown ? BigUp.Icons.Svg.Checkmark : props.icon}
                size='large'
              />
            </BigUp.Widget.ButtonContainer>
          </IonCol>
        </IonRow>
        <IonRow className='ion-justify-content-center'>
          <IonCol size='10' className='ion-text-center'>
            <IonText>
              <h3
                className='ion-no-margin'
                style={{
                  fontWeight: 'bold',
                  fontSize: 34
                }}>
                {interestShown
                  ? t('Thank you!')
                  : t('Coming soon!')
                }
              </h3>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className='ion-justify-content-center'>
          <IonCol size='12' className='ion-text-center'>
            <IonText color={'medium'} >
              <p className='ion-no-margin'>
                {interestShown
                  ? t('We will let you know by email when this feature is available.')
                  : t('We\'re working hard to get this feature done.')
                }
              </p>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow className='ion-justify-content-center'>
          <IonCol size='12'>
            <BigUp.Buttons.Primary
              loading={loading}
              expand='block'
              title={interestShown ? t('Close') : t('I want to know more')}
              onClick={() => handleShowInterestSubmit(props.title)}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </BigUp.Modal.Wrapper>
  );
};

export default ComingSoon;
