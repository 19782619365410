import { useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import BigUp from '../../UI';
import toasters from '../../UI/Toasts';

interface CreateButtonProps {
  onRowCreated: (row: any) => void;
  type: 'projects' | 'clients';
  id: string;
  category: any;
}
const CreateButton: React.FC<CreateButtonProps> = ({ category, id, onRowCreated, type }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const methods = useForm({});
  const { category_id } = useParams<{ category_id: string }>();

  const createRow = (data: any) => {
    store.dispatch(setIsLoading({ name: 'creatingRow', value: true }));
    networking.post(`/api/v1/work_preparations/rows`, {
      name: data.name,
      category_id
    })
      .then((res: E2U.V1.Response.Success<any>) => {
        onRowCreated(res.data.data);
        setModalOpen(false);
      })
      .catch(() => {
        toasters.createToast({
          message: t('Something went wrong when trying to create row. Please try again later or contact support.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'creatingRow', value: false }));
      });
  };

  return (
    <>
      <BigUp.TableHeader
        title={t('Rows for work preparation category {name}', { name: category?.name ?? '' })}
        button={{
          size: 'small',
          title: t('Create new row'),
          onClick: () => setModalOpen(true)
        }}
      />
      <FormProvider {...methods}>
        <BigUp.Modal.InputModal
          maxHeight='230px'
          modal={{
            isOpen: modalOpen,
            onDismiss: () => setModalOpen(false),
            title: t('Create new row'),
          }}
          button={{
            type: 'submit',
            title: t('Save'),
            disabled: methods.formState.isSubmitting || !methods.formState.isValid,
            loading: false
          }}
          textAlignment='center'
          input={{
            name: 'name',
            type: 'text',
            placeholder: t('Enter name'),
            register: 'name',
            validation: {
              required: true,
              minLength: 1
            }
          }}
          onSubmit={methods.handleSubmit(createRow)}
        />
      </FormProvider >
    </>
  );
};

export default CreateButton;
