import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import BirthdatePicker from './BirthdatePicker';
import registration_inputs from './config';
import type { RegistrationInputsProps } from './types';
import BigUp from '../../components/UI';
import { useAppSelector } from '../../hooks';

const RegistrationInputs: React.FC<RegistrationInputsProps> = ({ isBankId, onKeyUp }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const user = useAppSelector(state => state.authentication.user);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  return (
    <>
      {(!isBankId && location.pathname !== '/registration' && !user?.ssn) && (
        <IonRow>
          <IonCol>
            <IonCard className={'ion-no-margin ion-margin-bottom'}>
              <IonCardHeader>
                <IonCardTitle>{t('Authenticate with BankID for security')}</IonCardTitle>
                <IonCardSubtitle>{t('You are currently not authorized using BankID. Please connect your account for security and faster login.')}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <BigUp.Buttons.BankIdAuthenticate />
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      )}
      <IonRow >
        {registration_inputs.name_inputs.map((input, index) => {
          return (
            <IonCol key={input.id || index} size='12' sizeSm='6' style={{ paddingRight: isDesktop ? 4 : 0 }}>
              <BigUp.OutlinedInput onKeyUp={onKeyUp} enterkeyhint='next'{...input} />
            </IonCol>
          );
        })}
      </IonRow>
      <IonRow>
        <IonCol>
          {isBankId
            ? <BigUp.OutlinedInput
              onKeyUp={onKeyUp}
              enterkeyhint='next'
              formatter={false}
              {...registration_inputs.swedish_personal_number} />
            : location.pathname === '/registration' && <BirthdatePicker />
          }
        </IonCol>
      </IonRow>
      {registration_inputs.contact_inputs.map((input, index) => {
        return (
          <IonRow key={index}>
            <IonCol>
              <BigUp.OutlinedInput onKeyUp={onKeyUp} enterkeyhint='next'{...input} />
            </IonCol>
          </IonRow>
        );
      })}
    </>
  );
};

export default RegistrationInputs;
