import styles from './WidgetContainer.module.scss';

interface WidgetContainerProps {
  children: React.ReactNode;
  dimensions?: {
    width: number,
    height: number
  }
}
const WidgetContainer: React.FC<WidgetContainerProps> = (props) => {
  return (
    <div
      className={styles.widgetContainer}
      style={{
        width: props.dimensions?.width || '190px',
        height: props.dimensions?.height || '107px',
      }}
    >
      {props.children}
    </div>
  );
};

export default WidgetContainer;
