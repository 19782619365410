import {
  useIonAlert, useIonRouter
} from '@ionic/react';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import SectionForm from './Form';
import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import ActionColumn from '../../../pages/Financials/Subpanels/Columns/ActionColumn';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import BigUp from '../../UI';
import toasters from '../../UI/Toasts';

interface SectionsSettingPageProps {
  type: 'projects' | 'clients';
  sections: any[];
  onSectionChange: () => void;
}

const SectionsList: React.FC<SectionsSettingPageProps> = ({ onSectionChange, sections, type }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState<any>();

  const selectedProject = useAppSelector(state => state.project.selectedProject);

  const { client_id, node_id } = useParams<{ client_id: string, node_id: string }>();

  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);

  const id = useMemo(() => type === 'projects' ? selectedProjectId : client_id, [
    selectedProjectId,
    type,
    client_id
  ]);
  const nodeType = type === 'projects' ? 'project_nodes' : 'client_nodes';

  const router = useIonRouter();

  const doDeleteSection = (section: any) => {
    networking.delete(`/api/v1/work_preparations/sections/${section}`)
      .then(() => {
        onSectionChange();
        toasters.createToast({
          message: t('Section successfully removed')
        }, 'success');
      })
      .catch(() => {
        toasters.createToast({
          message: t('Something went wrong when trying to remove section.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'deletingSection', value: false }));
      });
  };

  const doRenameSection = (section: any, newName: string) => {
    store.dispatch(setIsLoading({
      name: 'renamingSection',
      value: true
    }));
    networking.put(`/api/v1/work_preparations/sections/${section.id}`, {
      name: newName
    })
      .then(() => {
        onSectionChange();
        toasters.createToast({
          message: t('Section successfully renamed')
        }, 'success');
      })
      .catch(() => {
        toasters.createToast({
          message: t('Failed to rename section. Please try again later or contact our support.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'renamingSection', value: false }));
      });
  };

  const handleSectionEdit = (section: any) => {
    setSelectedSection(section);
    setModalOpen(true);
  };

  const navigateSection = (section: any) => {
    if (type === 'projects') {
      router.push(`/tools/${id}/settings/work-preparation/section/${section.id}`);
    } else {
      router.push(`/clients/${id}/settings/work-preparation/section/${section.id}`);
    }
  };

  const handleSectionCreated = (section: any) => {
    setSelectedSection(undefined);
    onSectionChange();
  };

  return (
    <>
      <BigUp.Table
        sourceUrl={`/api/v1/work_preparations/${nodeType}/${node_id}/sections?limit=9999`}
        callbacks={{
          onRowClick: (row: any) => navigateSection(row)
        }}
        columns={[
          {
            key: 'name',
            label: t('Name'),
            alignment: 'left',
            sizes: {
              xs: '4',
              sm: '4',
              md: '4',
              lg: '4',
              xl: '4'
            },
          },
          {
            key: 'required_note',
            label: t('Required'),
            alignment: 'center',
            body: (row: any) => <>{
              row.required_note === 1
                ? <p style={{ margin: 0 }}>{t('Yes')} </p>
                : <p style={{ margin: 0 }}> {t('No')}</p>
            }
            </>,
            sizes: {
              xs: '4',
              sm: '4',
              md: '4',
              lg: '4',
              xl: '4'
            },
          },
          {
            key: 'actions',
            label: t('Actions'),
            sortable: false,
            body: <ActionColumn
              type="sections"
              attributes={{} as any}
              callbacks={{
                onDetails: (row: any) => navigateSection(row),
                onEdit: (row: any) => handleSectionEdit(row),
                onDelete: (row) => doDeleteSection(row)
              }}
            />,
            sizes: {
              xs: '4',
              sm: '4',
              md: '4',
              lg: '4',
              xl: '4'
            },
            alignment: 'right'
          }
        ]}
      />
      <SectionForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onSectionCreated={handleSectionCreated}
        type={type}
        id={id ?? ''}
        section={selectedSection}
      />
    </>
  );
};

export default SectionsList;
