
import styles from './styles.module.scss';

const SeperatorLine: React.FC = () => {
  return (
    <div className={styles['button-seperator']}>
      <div className={styles['full-seperator-line']} />
    </div>
  );
};

export default SeperatorLine;
