import {
  IonCol,
  IonGrid, IonLoading,
  IonRow, IonSkeletonText,
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../../api/networking';
import SkeletonTextThreeLines from '../../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../../../components/Toasts/Toasts';
import BigUp from '../../../../../components/UI';
import CreateButton from '../../../../../components/WorkPreparation/Sections/CreateButton';
import SectionsList from '../../../../../components/WorkPreparation/Sections/List';
import { useAppSelector } from '../../../../../hooks';
import { setIsLoading } from '../../../../../reducers/loading';
import store from '../../../../../store';

interface SectionsSettingPageProps {
  type: 'projects' | 'clients';
}

const SectionsSettingPage: React.FC<SectionsSettingPageProps> = ({ type }) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { client_id, node_id } = useParams<{ client_id: string, node_id: string }>();
  const id = useMemo(() => type === 'projects' ? selectedProjectId : client_id, [
    selectedProjectId,
    type,
    client_id
  ]);
  const [sections, setSections] = useState<any[]>([]);
  const [node, setNode] = useState<any>();
  const loadingSections = useAppSelector(state => state.loading.isLoading.fetchingSections);
  const loadingNode = useAppSelector(state => state.loading.isLoading.fetchingNode);

  const fetchSections = () => {
    if (!id) {
      return;
    }
    store.dispatch(setIsLoading({
      name: 'fetchingSections',
      value: true
    }));
    const nodeType = type === 'projects' ? 'project_nodes' : 'client_nodes';
    networking.get(`/api/v1/work_preparations/${nodeType}/${node_id}/sections?limit=9999`)
      .then((res: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<any>>) => {
        setSections(res.data.data.records);
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'fetchingSections',
          value: false
        }));
      });
  };

  const fetchNode = () => {
    if (!node_id) {
      return;
    }
    store.dispatch(setIsLoading({
      name: 'fetchingNode',
      value: true
    }));
    networking.get(`/api/v1/work_preparations/project_nodes/${node_id}`)
      .then((res: E2U.V1.Response.Success<any>) => {
        setNode(res.data.data);
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to fetch node.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'fetchingNode',
          value: false
        }));
      });
  };

  useEffect(() => {
    fetchSections();
    fetchNode();
  }, []);

  useEffect(() => {
    fetchSections();
  }, [type, id]);

  useEffect(() => {
    fetchNode();
  }, [node_id]);

  return (
    <IonGrid className='ion-no-paddding'>
      <CreateButton
        onSectionCreated={() => fetchSections()}
        type={type}
        id={selectedProjectId ?? ''}
      />
      {(loadingSections || loadingNode)
        ? (
          <IonRow className='ion-no-padding'>
            <IonCol>
              <SkeletonTextThreeLines />
            </IonCol>
          </IonRow>
        )
        : (
          <IonRow className='ion-no-padding'>
            <IonCol>
              <SectionsList
                type={type}
                onSectionChange={() => fetchSections()}
                sections={sections}
              />
            </IonCol>
          </IonRow>
        )}
    </IonGrid>
  );
};

export default SectionsSettingPage;
