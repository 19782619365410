import { IonBadge, IonCol, IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonModal, IonRow } from '@ionic/react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './Registration.module.scss';
import i18n from '../../i18n';
import type { DatetimeRegisterReturn } from '../NoticeBoard/Form/form.interface';

const BirthdatePicker: React.FC = () => {
  const { t } = useTranslation();
  const methods = useFormContext();

  function registerDatetime(register: UseFormRegisterReturn) {
    return register as DatetimeRegisterReturn;
  }

  const MINIMUM_AGE = 13;
  const minAllowedDate = DateTime.now().minus({ years: MINIMUM_AGE }).toISODate();
  const defaultDate = DateTime.now().minus({ years: 30 }).toISODate();
  const [selectedDate, setSelectedDate] = useState<string | null>(defaultDate);

  const handleDateChange = (e: CustomEvent) => {
    const selectedDateISO = e.detail.value as string;
    const selectedDate = DateTime.fromISO(selectedDateISO);
    const minDate = DateTime.now().minus({ years: MINIMUM_AGE });

    if (selectedDate > minDate) {
      methods.setError('birth_date', {
        type: 'validate',
        message: t('You must be at least 13 years old.'),
      });
    } else {
      methods.clearErrors('birth_date');
      const formattedDate = selectedDate.toISODate();
      methods.setValue('birth_date', formattedDate);
      setSelectedDate(formattedDate);
    }
  };

  return (
    <IonRow className='ion-align-items-center ion-justify-content-between'>
      <IonCol className='ion-no-padding'>
        <div className={styles['date-picker-outlined-container']}>
          <IonLabel
            className="ion-no-margin"
            style={{
              fontWeight: 500,
              color: '#666',
              letterSpacing: '0.14px',
            }}>
            {t('Birth date')}
          </IonLabel>
          <IonItem className="ion-no-padding" lines='none'>
            <IonDatetimeButton
              style={{
                border: '1px solid var(--custom-input-outline-color)',
                borderRadius: '10px',
                background: 'none',
                width: '100%'
              }}
              datetime="birth_date"
              className="ion-no-margin"
            />
          </IonItem>
        </div>
        <IonBadge color="danger" hidden={!methods.formState.errors.birth_date}>
          {methods.formState.errors.birth_date?.message as string}
        </IonBadge>
        <IonModal keepContentsMounted={true}>
          <IonDatetime
            id="birth_date"
            value={selectedDate}
            defaultValue={defaultDate}
            max={minAllowedDate}
            showDefaultButtons
            presentation="date"
            color="primary"
            onIonChange={handleDateChange}
            {...registerDatetime(
              methods.register('birth_date', {
                required: i18n.t('Please enter your birth date'),
                validate: (value) => {
                  const selectedDate = DateTime.fromISO(value);
                  const minDate = DateTime.now().minus({ years: MINIMUM_AGE });
                  return selectedDate <= minDate || t('You must be at least 13 years old.');
                },
              })
            )}
          />
        </IonModal>
      </IonCol>
    </IonRow>
  );
};

export default BirthdatePicker;
