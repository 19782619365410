import {
  IonContent,
  IonModal,
  IonSearchbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MultiSelectorChips from './Chips/MultiSelectorChips';
import type {
  MultiSelectorItemProps,
  MultiSelectorProps
} from './interfaces';
import './MultiSelector.ionic.scss';
import styles from './MultiSelector.module.scss';
import MultiSelectorGroup from './MultiSelectorGroup';
import MultiSelectorItem from './MultiSelectorItem';
import MultiSelectorParent from './MultiSelectorParent';
import useCrudResource from '../../../hooks/useCrudResource';
import useModal from '../../../hooks/useModal';
import type { SourceUrlProps } from '../../../interfaces/SourceUrlProps';
import BigUp from '../index';
import MultiSelectorTrigger from './MultiSelectorTrigger';

import classNames from 'classnames';
import equal from 'fast-deep-equal/react';

import SkeletonItem from '../../SkeletonComponents/SkeletonItem';

const MultiSelector: React.FC<React.PropsWithChildren<any> & MultiSelectorProps> = (props) => {
  const { t } = useTranslation();
  const { fetch: crudFetch, parseResponse: parseCrudResponse } = useCrudResource();
  const { closeModal, isModalOpen, toggleModal } = useModal();

  const { callbacks, group, sourceUrl } = props;
  const { selectedItems: _, ...triggerProps } = props?.triggerProps ?? {};

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [containsIcons, setContainsIcons] = useState<boolean>(false);
  const [containsChildren, setContainsChildren] = useState<boolean>(false);
  const [items, setItems] = useState<MultiSelectorItemProps[]>(props.items ?? []);
  const timeouts: {
    [key: string]: ReturnType<typeof setTimeout> | undefined;
  } = {
    search: undefined
  };
  const selectedGroups = useMemo(() => {
    return items.filter(
      (item) => !item.children || item.children.every((child) => selectedItems.includes(child.value))
    );
  }, [selectedItems]);

  const trigger = useMemo(() => {
    return props?.trigger
      ? React.cloneElement(props.trigger, { onClick: () => toggleModal() })
      : (
        (props?.triggerProps?.chips?.type === 'range' && selectedItems.length > 0)
          ? <></>
          : (
            props?.triggerProps
              ? (
                <MultiSelectorTrigger
                  selectedItems={selectedItems}
                  onClick={toggleModal}
                  items={items}
                  {...triggerProps}
                />
              )
              : (
                <MultiSelectorTrigger
                  selectedItems={selectedItems}
                  onClick={toggleModal}
                  items={items}
                />
              )
          )
      );
  }, [props.trigger, props.triggerProps, selectedItems, toggleModal]);

  const breakpoints = [0.25, 0.5, 0.75, 0.95];
  const breakpointDefault = 0.95; // @todo: calculate based on amount of items?

  const fetchItems = (url: string | SourceUrlProps) => {
    if (!selectedItems.length) {
      setIsLoading(true);
    }

    crudFetch(url)
      .then((responseRaw) => parseCrudResponse(responseRaw))
      .then((response) => {
        const callback = callbacks?.parseResponse;

        if (callback) {
          setItems(callback(response));
        } else {
          /**
           * Save some time for developers and parse the response automatically
           * assuming id & name are always present
           *
           * @todo: update once we have better typings
           */
          setItems(response.map((item) => ({
            value: item.id,
            label: item.name
          })));
        }
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const updateSelection = (value: any) => {
    setSelectedItems((selectedItems) => {
      const children: any[] = [];

      items.forEach((item) => {
        if (item.value === value) {
          item.children?.every((child) => children.push(child.value));

          return false;
        }
      });

      if (selectedItems.includes(value)) { // "toggle" logic
        return assureGroupIsSelected(selectedItems.filter((item) => (
          item !== value && !children.includes(item)
        )));
      }

      return assureGroupIsSelected([...selectedItems, value, ...children]);
    });
  };

  const handleDeselectItems = (items: string[]) => {
    const newSelectedItems = assureGroupIsSelected(selectedItems.filter((item) => !items.includes(item)));
    setSelectedItems(newSelectedItems);
    handleUpdateParent(newSelectedItems);
  };

  const assureGroupIsSelected = (value?: any[]) => {
    const valuesToRemove: any[] = [];
    const valuesToProcess: any[] = value ?? selectedItems;
    items.forEach((item) => {
      if (!item.children || !item.children.length) {
        return;
      }
      if (item.children.every((child) => valuesToProcess.includes(child.value))) {
        valuesToProcess.push(item.value);
      } else if (valuesToProcess.includes(item.value)) {
        valuesToRemove.push(item.value);
      }
    });
    if (valuesToRemove.length === 0) {
      return [...new Set(valuesToProcess)];
    }
    return [...new Set(valuesToProcess.filter((item) => !valuesToRemove.includes(item)))];
  };

  const handleDefaultValue = () => {
    const selected = assureGroupIsSelected(props.defaultValue);
    if (!props.defaultValue || equal(selectedItems, selected)) {
      return;
    }
    setSelectedItems(selected);
  };

  const getSelectedChildren = (forceValue?: any[]) => {
    const children: any[] = [];
    items.forEach((item) => {
      if (!item.children || !item.children.length) {
        if ((forceValue ?? selectedItems).includes(item.value)) {
          children.push(item.value);
        }
        return;
      }
      item.children.forEach((child) => {
        if ((forceValue ?? selectedItems).includes(child.value)) {
          children.push(child.value);
        }
      });
    });
    return children;
  };

  const handleUpdateParent = (forceValues?: any[]) => {
    /**
     * Pass values to callback so that mighty developer can do something about it
     */
    if (callbacks?.handleUserSelection) {
      let selected = forceValues ?? selectedItems;
      if (props.excludeSelectedParentFromCallback) {
        selected = getSelectedChildren(selected);
      }

      callbacks.handleUserSelection(selected);
    }
  };

  const handleOnSave = () => {
    handleUpdateParent();
    closeModal();
    // @todo: apply/send filters
  };

  const handleAbort = () => {
    if (props.defaultValue) {
      handleDefaultValue();
    }
    closeModal();
  };

  /**
   * Fetch remote filters once modal is open
   */
  useEffect(() => {
    if (sourceUrl && isModalOpen) {
      fetchItems(sourceUrl);
    }
  }, [isModalOpen, sourceUrl]);

  useEffect(() => {
    if (searchQuery !== null) {
      if (timeouts.search) {
        clearTimeout(timeouts.search);
      }

      timeouts.search = setTimeout(() => {
        fetchItems({
          url: sourceUrl.url,
          args: {
            ...sourceUrl.args,
            search: searchQuery
          }
        });
      }, 300);
    }

    return () => {
      if (timeouts.search) {
        clearTimeout(timeouts.search);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    /**
     * We need to know if there is any icon present and/or if there are children
     * (that have toggle icon) in the list to achieve desired styling.
     */

    setContainsChildren(items.some((item) => item.children));
    setContainsIcons(items.some((item) => item.icon || item.children?.some((child) => child.icon)));
  }, [items]);

  useEffect(() => {
    /**
     * Notify parent (MultiSelectorGroup) component about the changes
     */
    if (group?.callbacks?.notify) {
      if (!props?.identifier) {
        // @todo: remove once better interface is in place, preventing such sitation in the first place
        console.warn('No identifier provided for MultiSelector within MultiSelectorGroup. This is probably not what you want');
      }

      group.callbacks.notify(selectedItems, props?.identifier);
    }
  }, [selectedItems]);

  useEffect(() => {
    handleDefaultValue();
  }, []);

  useEffect(() => {
    handleDefaultValue();
  }, [props.defaultValue, items]);

  return (
    <>
      {!['left', 'right'].includes(props.triggerProps?.chips?.triggerPlacement)
        ? trigger
        : (
          <>
            {(!props.triggerProps?.chips?.triggerPlacement || props.triggerProps?.chips?.triggerPlacement === 'left') && trigger}
            {(!props.triggerProps?.chips?.showAsSelect && !props.triggerProps?.chips?.hide) && (<MultiSelectorChips
              selectedItems={selectedItems}
              items={items}
              selectedGroups={selectedGroups}
              toggleModal={toggleModal}
              triggerProps={triggerProps}
              onDeselectItems={handleDeselectItems}
            />)}
            {(props?.triggerProps?.chips?.triggerPlacement && props.triggerProps?.chips?.triggerPlacement === 'right') && trigger}
          </>
        )}
      <IonModal
        isOpen={isModalOpen}
        onIonModalDidDismiss={closeModal}
        initialBreakpoint={breakpointDefault}
        breakpoints={breakpoints}
        className={classNames('multiSelector', (containsIcons || containsChildren ? null : 'no-icons'))}>
        <BigUp.Modal.ModalHeader
          title={props.title}
          end={{
            button: {
              title: t('Save'),
            },
            onClick: () => handleOnSave()
          }}
          start={{
            icon: closeOutline,
            onClick: () => handleAbort()
          }}
        />
        <IonContent>
          {(props.triggerProps?.chips?.triggerPlacement === 'inner-top') && (
            <div className={styles.list__selected}>
              <MultiSelectorChips
                selectedItems={selectedItems}
                items={items}
                selectedGroups={selectedGroups}
                toggleModal={toggleModal}
                triggerProps={triggerProps}
                onDeselectItems={handleDeselectItems}
              />
            </div>
          )}
          <div className={styles.search}>
            <IonSearchbar
              onIonInput={(e) => setSearchQuery(e.detail.value as string)}
              placeholder={t('Search')}
              className={styles.search_box}></IonSearchbar>
          </div>

          {isLoading
            ? (
              <SkeletonItem
                sizes={{
                  sizeXs: '12',
                  sizeSm: '12',
                  sizeMd: '12',
                  sizeLg: '12'
                }}
                amount={5}
              />
            )
            : items.map((item: MultiSelectorItemProps, index) => {
              const children = item?.children ?? [];

              if (children.length > 0) {
                return (
                  <MultiSelectorParent
                    key={index}
                    items={children}
                    parent={item}
                    selectedItems={selectedItems}
                    callbacks={{
                      updateSelection
                    }}
                    searchQuery={searchQuery ?? undefined}
                    isLoading={isLoading}
                  />
                );
              }

              return (
                <MultiSelectorItem
                  key={index}
                  item={item}
                  selectedItems={selectedItems}
                  toggleValue={updateSelection}
                  showAvatar={props.triggerProps?.chips?.type === 'avatars'}
                />
              );
            })}
        </IonContent>
      </IonModal>
    </>
  );
};

export {
  MultiSelector,
  MultiSelectorGroup,
  MultiSelectorParent,
  MultiSelectorItem,
  MultiSelectorTrigger
};

export default MultiSelector;
