import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import { t } from 'i18next';
import React from 'react';

import DesktopWrapper from '../../components/DesktopWrapper';
import BigUp from '../../components/UI';
import useAcl from '../../hooks/useAcl';

const ConstructionSitesList: React.FC = () => {
  // todo: acl (check if can_create_construction_sites)
  const { project } = useAcl();
  const router = useIonRouter();

  return (
    <DesktopWrapper>
      {/* {isLoading && <SkeletonTextThreeLines />} */}
      <IonGrid style={{ width: '100%' }}>
        <IonRow className={'ion-align-items-center ion-justify-content-between'}>
          <IonCol size='auto'>
            <BigUp.Title label={t('Construction Sites')} />
          </IonCol>
          <IonCol
            size='auto'
            className='ion-text-end'
          >
            {/* <AclCurtain requiredAbilities={['can_create_construction_sites']}> */}
            <BigUp.Buttons.Secondary
              title={t('Create new')}
              color='default'
              size='small'
              onClick={() => router.push(`construction-sites/create`)}
            />
            {/* </AclCurtain> */}
          </IonCol>
        </IonRow>
        {project && (
          <IonRow className={'ion-align-items-center ion-justify-content-between'}>
            <IonCol size='12'>
              <BigUp.Table
                columns={[
                  {
                    key: 'name',
                    label: t('Name'),
                    alignment: 'left'
                  },
                  {
                    key: 'code',
                    label: t('Code')
                  },
                  {
                    key: 'description',
                    label: t('Description')
                  }
                ]}
                sourceUrl={`/api/v1/projects/${project.id}/construction_sites`} />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </DesktopWrapper>
  );
};

export default ConstructionSitesList;
