import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SectionForm from './Form';
import BigUp from '../../UI';

interface CreateButtonProps {
  onSectionCreated: (section: any) => void;
  type: 'projects' | 'clients';
  id: string;
}

const CreateButton: React.FC<CreateButtonProps> = ({ id, onSectionCreated, type }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSectionCreated = (section: any) => {
    setIsModalOpen(false);
    onSectionCreated(section);
  };

  return (
    <>
      <BigUp.TableHeader
        title={t('Create new section')}
        button={{
          size: 'small',
          title: t('Add section'),
          onClick: () => setIsModalOpen(true)
        }}
      />
      <SectionForm
        onSectionCreated={handleSectionCreated}
        type={type}
        id={id}
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default CreateButton;
