import { IonCol, IonGrid, IonIcon, IonLabel, IonRow, useIonActionSheet, useIonPopover, useIonRouter } from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ComingSoonList } from './comingSoon';
import { comingSoonItems } from './comingSoon';
import styles from './ToolList.module.scss';
import { BigUp } from '../../../components/UI';
import type { SubmenuItem } from '../../../components/UI/SideMenu/V2/MenuTypings';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { toolsSubmenuItems } from '../../../components/UI/SideMenu/V2/Submenus/ToolsSubmenu';
import { useAppSelector } from '../../../hooks';
import useAcl from '../../../hooks/useAcl';
import { setSelectedProject } from '../../../reducers/project';
import store from '../../../store';
import { capitalize } from '../../../tools/capitalizeString';

interface ListPageProps {
  title?: string | undefined;
  description: string;
}

const ToolList: React.FC<ListPageProps> = () => {
  const [nextPath, setNextPath] = useState<string | undefined>(undefined);
  const [comingSoonModalOpen, setComingSoonModalOpen] = useState<ComingSoonList>({
    open: false,
    title: '',
    message: '',
    icon: ''
  });
  const { t } = useTranslation();
  const router = useIonRouter();
  const { projects } = useAcl();
  const { isOnboarded } = useAcl();

  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const isLoadingUser = useAppSelector(state => state.loading.isLoading.user);
  const isLoadingAuthCheck = useAppSelector(state => state.loading.isLoading.auth_check);
  const isLoadingAbilities = useAppSelector(state => state.loading.isLoading.abilities);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const [present] = useIonPopover(BigUp.Popovers.ProjectSelectionPopover, {
    nextPath,
    onNavigate: (to: string) => {
      router.push(to);
      setNextPath(undefined);
    },
  });
  const [presentActionSheet] = useIonActionSheet();

  const isLoading = isLoadingUser || isLoadingAuthCheck || isLoadingAbilities;

  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);

  const homeScreenNavigation: SubmenuItem[] = useMemo(() => toolsSubmenuItems.map(
    item => ({
      ...item,
      route: item.route.replace('/:uuid?', `/${selectedProjectId ?? ''}/`).replace(/\/+/g, '\/'),
      base_route: item.route,
    })
  ), [selectedProjectId]);

  const handleToolSelected = (e: Event, item: SubmenuItem) => {
    if (!selectedProjectId) {
      if (item.project_optional) {
        router.push(item.route);
      } else {
        setNextPath(item.base_route);

        if (isDesktop) {
          present({
            event: e,
            dismissOnSelect: true,
          });
        } else {
          presentActionSheetHandler(e, item);
        }
      }
    } else {
      router.push(selectedProject ? item.route : '/tools');
    }
  };

  const presentActionSheetHandler = (e: Event, item: SubmenuItem) => {
    presentActionSheet({
      header: t('Select project'),
      cssClass: styles['project-select-action-sheet'],
      buttons: projects.length
        ? projects.map((project, i) => ({
          text: project.name ?? '',
          key: i,
          handler: () => {
            store.dispatch(setSelectedProject(project));
            router.push((item.base_route ?? item.route).replace(/:uuid\??/, project.id ?? ''));
          }
        }))
        : [
          {
            text: t('Create new project'),
            handler: () => {
              router.push('/tools/create-project');
            }
          }
        ]
    });
  };

  const redirectIfNotOnboarded = () => {
    if (!isOnboarded() && !isLoading) {
      router.push('/introduction');
    }
  };

  useEffect(() => {
    redirectIfNotOnboarded();
  }, [isLoading]);

  return (
    <>
      <SideMenuV2Layout paddedPage={false}>
        <IonGrid className='ion-padding-top ion-margin-top'>
          {(selectedProject?.id && isDesktop) && (
            <IonRow className='ion-justify-content-start'>
              <IonCol size='' className='ion-padding-start' style={{ paddingLeft: '60px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <BigUp.Title >
                    <BigUp.Label.V2Thick label={t('Welcome to project ')} />
                    <BigUp.Label.Regular color={'medium'} className='ion-no-margin' label={capitalize(selectedProject.name)} />
                  </BigUp.Title>
                </div>
              </IonCol>
            </IonRow>
          )}
          <IonRow className='ion-justify-content-start'>

            {homeScreenNavigation.map((item, index) => (
              <IonCol size={'3'} sizeLg={'2'} key={index} className='ion-margin-top'>
                <div className={styles.widgetContainer}>
                  <BigUp.Widget.ButtonContainer onClick={(e: Event) => handleToolSelected(e, item)}>
                    <IonIcon icon={item.icon} style={{ fontSize: '36px' }} />
                  </BigUp.Widget.ButtonContainer>
                  <IonLabel className='ion-no-margin' color={'medium'}>
                    {item.title}
                  </IonLabel>
                </div>
              </IonCol>
            ))}
            {comingSoonItems.map((item, i) => {
              return (
                <IonCol size={'3'} sizeLg={'2'} key={i} className='ion-margin-top'>
                  <div className={styles.widgetContainer}>
                    <BigUp.Widget.ButtonContainer
                      onClick={(e: React.MouseEvent) => setComingSoonModalOpen({
                        open: true,
                        title: item.title,
                        message: item.message,
                        icon: item.icon
                      })} >
                      <IonIcon icon={item.icon} style={{ fontSize: '36px' }} />
                    </BigUp.Widget.ButtonContainer>
                    <IonLabel className='ion-no-margin' color={'medium'}>
                      {item.title}
                    </IonLabel>
                  </div>
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      </SideMenuV2Layout>

      <BigUp.Modal.ComingSoon
        icon={comingSoonModalOpen.icon}
        open={comingSoonModalOpen.open}
        title={comingSoonModalOpen.title}
        message={comingSoonModalOpen.message}
        dismiss={() => setComingSoonModalOpen({ open: false, title: '', message: '', icon: '' })}
      />
    </>
  );
};

export default ToolList;
