import type { DataItemProps } from '../../../components/UI/Table/interfaces';
import i18n from '../../../i18n';

export const columns: DataItemProps[] = [
  {
    key: 'production_budget_total',
    label: i18n.t('Production budget'),
    sortable: true,
    sizes: {
      xl: '2',
    }
  },
  {
    key: 'changes_total',
    label: i18n.t('Changes'),
    sortable: true,
    sizes: {
      xl: '2',
    }
  },
  {
    key: 'deviations_total',
    label: i18n.t('Deviations'),
    sortable: true,
    sizes: {
      xl: '2',
    }
  },
  {
    key: 'final_forecast_total',
    label: i18n.t('Final forecast'),
    sortable: true,
    sizes: {
      xl: '2',
    }
  },
  {
    key: 'accrude_cost_total',
    label: i18n.t('Accrued cost'),
    sortable: true,
    sizes: {
      xl: '2',
    }
  },
  {
    key: 'workup_rate',
    label: i18n.t('Workup rate'),
    sortable: true,
    sizes: {
      xl: '2',
    },
  }
];
