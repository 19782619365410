import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { createSelector } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../..';
import { networking } from '../../../../../../api/networking';
import bigUpFolder from '../../../../../../components/icons/bigUpFolder/bigupFolder.svg';
import { useAppSelector } from '../../../../../../hooks';
import { setSearch } from '../../../../../../reducers/files';
import store from '../../../../../../store';
import { formatToNiceDate } from '../../../../../../tools/formatDates';
import EmptyList from '../../../../EmptyList';
import ContentWrapper from '../ContentWrapper';
import { marginTop } from './constants';
import Documents from './Documents';
import { type NavigationProps } from './types';
import CardButton from '../../../../Buttons/CardButton';

const Categories: React.FC<NavigationProps> = ({ categories, navigation, ...props }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const projectId = useAppSelector((state) => state.project.selectedProject?.id);

  const selectFileReducers = createSelector(
    (state) => state.fileSelection.toggleListView,
    (state) => state.fileSelection.search,
    (toggleListView, search) => ({
      toggleListView,
      search,
    })
  );

  const fileReducers = useAppSelector(selectFileReducers);

  const navigateToDocument = (category: E2U.V1.Models.TagCategory) => {
    store.dispatch(setSearch(''));
    navigation?.push(() => (
      <Documents navigation={navigation} category={category}{...props} />
    ));
  };

  const fetchDocumentTypes = () => {
    if (!projectId) return;
    setLoading(true);
    networking
      .get(`/api/v1/projects/${projectId}/document_types`, {
        params: {
          search: fileReducers.search,
          per_page: 9999,
          'appends[]': 'files_count',
        },
      })
      .then((response) => {
        setDocumentTypes(response.data.data.records);
      })
      .catch((error: Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDocumentTypes();
  }, [projectId, fileReducers.search]);

  return (
    <ContentWrapper>
      <>
        {!fileReducers.toggleListView
          ? (
            <BigUp.ListComponent.DetailedListWrapper loading={loading}>
              {(documentTypes && documentTypes.length === 0) && !loading && (
                <EmptyList
                  icon={{
                    icon: bigUpFolder,
                    size: 100,
                  }}
                  title={t('No categories found')}
                  message={t('Try adjusting your search or check back later.')}
                />
              )}

              {(!loading && documentTypes && documentTypes.every(type => type.files_count === 0)) && (
                <EmptyList
                  icon={{
                    icon: bigUpFolder,
                    size: 100,
                  }}
                  title={t('No files could be found')}
                  message={t('Try adjusting your search or check back later.')}
                />
              )}

              {documentTypes.map((type: any) => (
                (type.files_count > 0) && (
                  <BigUp.ListComponent.DetailedListItem
                    key={type.id}
                    type={type}
                    onClick={() => navigateToDocument(type)}
                    title={type.name}
                    subtitle=
                      {
                        t(`{date} - {count} objects`, {
                          date: formatToNiceDate(type.updated_at),
                          count: type.files_count ?? '0',
                        })
                      }
                  />
                )
              ))}

            </BigUp.ListComponent.DetailedListWrapper>
          )
          : (
            <IonGrid style={{ marginTop }}>
              <IonRow className='ion-padding-top'>
                {documentTypes.map((type: any) => (
                  (type.files_count > 0) && (
                    <IonCol size='4' sizeSm='3' key={type.id}>
                      <CardButton key={type.id} type={type} onClick={() => navigateToDocument(type)} />
                    </IonCol>
                  )
                ))}
              </IonRow>
            </IonGrid>
          )
        }
      </>
    </ContentWrapper>
  );
};

export default Categories;
