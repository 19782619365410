
import type { useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { TFunction } from 'i18next';
import type { Dispatch, SetStateAction } from 'react';

import styles from './Preparations.module.scss';
import FilterChips from '../../../components/UI/Chips/FilterChips';
import type { DataItemProps, TableProps } from '../../../components/UI/Table/interfaces';
import { formatToYYYYMMDD } from '../../../tools/formatDates';
import Contact from '../../EmergencyContacts/ContactTile';
import { Tile } from '../../Tools/ControlOfExecution/Tiles';

interface StatusTypes {
  started_at: string | null;
  finished_at: string | null;
  [key: string]: any
}

const getStatusLabel = (row: StatusTypes, t: TFunction): string => {
  if (!row.started_at) return t('Not started');
  if (row.started_at && !row.finished_at) return t('Draft');
  if (row.started_at && row.finished_at) return t('Signed');
  return t('Unknown status');
};

export const createTableColumns = (t: TFunction): DataItemProps[] => [
  {
    key: 'project.name',
    label: t('Project'),
    sortable: true,
    alignment: 'left',
    sizes: { xs: '4', sm: '3', md: '3', lg: '2', xl: '2' },
  },
  {
    key: 'description',
    label: t('Name'),
    sortable: true,
    alignment: 'left',
    sizes: { xs: '4', sm: '3', md: '3', lg: '2', xl: '2' },
    body: <Tile.Description attributes={{} as E2U.V1.Models.ControlOfExecution} />,
  },
  {
    key: 'created_by_id',
    label: t('Created by'),
    alignment: 'left',
    sizes: { xs: '6', sm: '3', md: '3', lg: '3', xl: '2' },
    body: (row: any) => {
      if (row.created_by_id) {
        row.user_id = row.created_by_id;
      }
      return <Contact attributes={{ user_id: row.created_by_id }} />;
    },
  },
  {
    key: 'date',
    sortable: true,
    label: t('Date'),
    alignment: 'left',
    sizes: { xs: '4', sm: '3', md: '3', lg: '2', xl: '2' },
    body: (row: any) => <>{formatToYYYYMMDD(row.date)}</>,
  },
  {
    key: 'finished_at',
    sortable: true,
    label: t('Status'),
    alignment: 'left',
    sizes: { xs: '4', sm: '3', md: '3', lg: '2', xl: '2' },
    body: (row: any) => <span>{getStatusLabel(row, t)}</span>,
  },
  {
    key: 'work_operation',
    sortable: true,
    label: t('Work operation'),
    alignment: 'left',
    sizes: { xs: '4', sm: '3', md: '3', lg: '2', xl: '1' },
  },
  {
    key: 'activity_codes',
    label: t('Activity codes'),
    alignment: 'center',
    sizes: { xs: '4', sm: '3', md: '3', lg: '2', xl: '1' },
    body: (row: { activity_codes: E2U.V1.Models.ActivityCode[] }) => (
      <>{row.activity_codes.length}</>
    ),
  },
];

export const createTableProps = (
  lastControlFetch: number,
  sourceUrl: string,
  tableColumns: DataItemProps[],
  router: ReturnType<typeof useIonRouter>,
  setRows: Dispatch<SetStateAction<never[]>>
): TableProps => ({
  timestamp: lastControlFetch,
  sourceUrl: {
    url: sourceUrl,
    args: {
      'with[]': ['project', 'activityCodes'],
    },
  },
  columns: tableColumns,
  reducers: {
    created_at: (value: string) => formatToYYYYMMDD(value),
  },
  callbacks: {
    onRowClick: (row: any) => router.push(`/tools/preparation/${row.id}`),
    onRenderComplete: (rows: any) => {
      setRows(rows);
    }
  },
  collapsible: {
    key: 'activity_codes',
    rowIsEnabled: (row: any) => row.activity_codes.length > 0,
    componentKey: 'activity_codes',
    component: (row) => (
      <div className={styles['activity-codes-container']}>
        <FilterChips
          data={row.activity_codes}
          renderChipLabel={(item: E2U.V1.Models.ActivityCode) => (
            <p>
              {item.code} - {item.name}
            </p>
          )}
        />
      </div>
    ),
  },
});
