import { IonCol, IonGrid, IonIcon, IonItem, IonList, IonListHeader, IonRow } from '@ionic/react';
import { addSharp } from 'ionicons/icons';
import React from 'react';

import BigUp from '..';
import styles from './ItemList.module.scss';
import type { ItemListProps } from './types';

const errorBorder = `1px solid var(--ion-color-danger)`;

const ItemListExtraRow: React.FC<{
  extraRow: ItemListProps['extraRow'],
  items: ItemListProps['items']
}> = ({ extraRow, items }) => {
  return (
    (extraRow)
      ? (<IonItem
        {...extraRow.item}
        color="light"
        className={styles['extra-row']}
        style={{
          ...extraRow.item?.style,
          ...extraRow.item?.errorOutline && {
            border: errorBorder,
            borderTopLeftRadius: items[items.length - 1]?.errorOutline ? 0 : 10,
            borderTopRightRadius: items[items.length - 1]?.errorOutline ? 0 : 10,
          }
        }}
      >
        {extraRow.content}
      </IonItem>)
      : <></>
  );
};

const ItemList: React.FC<ItemListProps> = ({ actionButton, children, customItem = false, extraRow, header, headerOptions, items }) => {
  return (
    <IonList inset={true} style={{ margin: 0 }}>
      {header && (
        <IonListHeader className='ion-no-padding' style={{ paddingLeft: 8, paddingBottom: 4, paddingRight: 8, fontSize: '18px' }}>
          <BigUp.Label.Thick label={header.title} color={header.color || 'medium'} />
          {header.actionIcon && (

            <IonIcon icon={header.actionIcon.icon || addSharp} color='medium' size='large' role='button' onClick={header.actionIcon.onClick} />
          )}
        </IonListHeader>
      )}
      {items.map((item, index) => {
        const radius = 10;
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        const previousError = !items[index - 1]?.errorOutline;
        const nextError = items[index + 1]?.errorOutline;

        const borders = {
          borderTopLeftRadius: isFirst ? radius : 0,
          borderTopRightRadius: isFirst ? radius : 0,
          borderBottomLeftRadius: isLast && !extraRow?.hasExtraRow ? radius : 0,
          borderBottomRightRadius: isLast && !extraRow?.hasExtraRow ? radius : 0,
          ...item?.errorOutline && {
            borderTop: errorBorder,
            borderLeft: errorBorder,
            borderRight: errorBorder,
            borderTopLeftRadius: isFirst || previousError ? radius : 0,
            borderTopRightRadius: isFirst || previousError ? radius : 0,
            ...!extraRow?.item?.errorOutline && {
              borderBottom: errorBorder,
              borderBottomLeftRadius: nextError || (isLast && extraRow?.item?.errorOutline) ? 0 : radius,
              borderBottomRightRadius: nextError || (isLast && extraRow?.item?.errorOutline) ? 0 : radius,
            }
          }
        };

        return (
          customItem
            ? (
              <>
                {item.content}
              </>
            )
            : (
              <IonItem key={item.id} color="light" style={borders} className='ion-text-nowrap'>
                {item.content}
              </IonItem>
            )
        );
      })}

      {(extraRow && Array.isArray(extraRow) ? extraRow : [extraRow]).map((row, index) => (
        <ItemListExtraRow key={index} extraRow={row} items={items} />
      ))}

      {actionButton && (
        <IonGrid className='ion-no-padding'>
          <IonRow>
            <IonCol className='ion-text-end'>
              <BigUp.Buttons.Regular
                title={actionButton.title}
                onClick={actionButton.onClick}
                fill='clear'
                size='small'
                icon={{
                  icon: addSharp,
                  slot: 'start'
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {children}
    </IonList>
  );
};

export default ItemList;
