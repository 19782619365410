import { useIonRouter } from '@ionic/react';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import ActionColumn from '../../../pages/Financials/Subpanels/Columns/ActionColumn';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import BigUp from '../../UI';
import toasters from '../../UI/Toasts';

interface NodesSettingPageProps {
  type: 'projects' | 'clients';
  onNodeChange: () => void;
}

const NodesList: React.FC<NodesSettingPageProps> = ({ onNodeChange, type }) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [nodeId, setNodeId] = useState<string | undefined>(undefined);

  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: ''
    }
  });
  const router = useIonRouter();
  const { client_id } = useParams<{ client_id: string }>();

  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);

  const id = useMemo(() => type === 'projects'
    ? selectedProjectId
    : client_id,
  [selectedProjectId, type, client_id]
  );

  const doDeleteNode = (node: any) => {
    networking.delete(`/api/v1/work_preparations/${type === 'projects' ? 'project' : 'client'}_nodes/${node}`)
      .then(() => {
        onNodeChange();
        toasters.createToast({
          message: t('Template successfully removed')
        }, 'success');
      })
      .catch(() => {
        toasters.createToast({
          message: t('Something went wrong when trying to remove template.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'deletingNode', value: false }));
      });
  };

  const handleUpdateNodeName = (data: any) => {
    networking.put(`/api/v1/work_preparations/${type === 'projects' ? 'project' : 'client'}_nodes/${nodeId}`, {
      name: data.name
    })
      .then(() => {
        onNodeChange();
        toasters.createToast({
          message: t('Template successfully renamed')
        }, 'success');
      })
      .catch(() => {
        toasters.createToast({
          message: t('Failed to rename template. Please try again later or contact our support.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'renamingNode', value: false }));
      });
  };

  const navigateNode = (node: any) => {
    if (type === 'projects') {
      router.push(`/tools/${id}/settings/work-preparation/node/${node.id}`);
    } else {
      router.push(`/clients/${id}/settings/work-preparation/node/${node.id}`);
    }
  };

  return (
    <>
      <BigUp.Table
        sourceUrl={`/api/v1/${type}/${id}/work_preparation_nodes?limit=9999 `}
        callbacks={{
          onRowClick: (node: any) => navigateNode(node)
        }}
        columns={[
          {
            key: 'name',
            label: t('Name'),
            alignment: 'left',
            sizes: {
              xs: '9',
              sm: '10',
              md: '10',
              lg: '10',
              xl: '10'
            },
          }, {
            key: 'actions',
            label: t('Actions'),
            sortable: false,
            body: <ActionColumn
              type="template"
              attributes={{} as any}
              callbacks={{
                onDetails: navigateNode,
                onEdit: (node) => {
                  setModalOpen(true);
                  setNodeId(node.id);
                  methods.setValue('name', node?.name ?? '');
                },
                onDelete: (row) => doDeleteNode(row)
              }}
            />,
            sizes: {
              xs: '3',
              sm: '2',
              md: '2',
              lg: '2',
              xl: '2'
            },
            alignment: 'right'
          }
        ]}
      />
      <FormProvider {...methods}>
        <BigUp.Modal.InputModal
          maxHeight='230px'
          modal={{
            isOpen: modalOpen,
            onDismiss: () => setModalOpen(false),
            title: t('Rename template'),
          }}
          button={{
            type: 'submit',
            title: t('Save'),
            disabled: methods.formState.isSubmitting || !methods.formState.isValid,
            loading: false
          }}
          textAlignment='center'
          input={{
            name: 'name',
            type: 'text',
            placeholder: t('Enter name'),
            register: 'name',
            validation: {
              required: true,
              minLength: 1
            }
          }}
          onSubmit={methods.handleSubmit(handleUpdateNodeName)}
        />
      </FormProvider >
    </>
  );
};

export default NodesList;
