import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { addSharp } from 'ionicons/icons';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../../components/UI';
import MultiSelector from '../../../../components/UI/MultiSelector';
import { useAppSelector } from '../../../../hooks';

const Assignees: React.FC = () => {
  const methods = useFormContext();
  const { t } = useTranslation();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);
  const assignees: string[]|undefined = methods.watch('assignees');

  return (
    <IonGrid>
      <IonRow className={'ion-justify-content-between ion-align-items-center'}>
        <IonCol size={'auto'}>
          <BigUp.Label.Thick label={t('Participants')} color={'medium'} />
        </IonCol>
        <IonCol size={(assignees?.length ?? 0) > 0 ? '12' : 'auto'}>
          { !selectedProjectId
            ? <p className={'ion-no-margin'}><i><small>{ t('Select project to choose participants') }</small></i></p>
            : (<MultiSelector
              title={ t('Select participants') }
              identifier='participants'
              triggerProps={ {
                icon: addSharp,
                label: t('Select'),
                hideChevron: true,
                chips: {
                  triggerPlacement: 'right',
                  type: 'avatars'
                }
              } }
              sourceUrl={ {
                url: `/api/v1/projects/${selectedProjectId}/users`,
              } }
              callbacks={ {
                parseResponse: (response: E2U.V1.Models.User[]) => {
                  return response.flatMap((item) => ({
                    shortLabel: `${(item.first_name ?? '')[0]}.${(item.last_name ?? '')[0]}.`,
                    label: item.name,
                    value: item.id,
                  }));
                },
                handleUserSelection: (items: string[]) => {
                  methods.setValue('assignees', items);
                }
              } }
              excludeSelectedParentFromCallback={ true }
              defaultValue={ assignees ?? [] }
            />) }
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Assignees;
