import { IonCol, IonGrid, IonRow } from '@ionic/react';

import BigUp from '..';
import styles from './styles/Count.module.scss';
import type { WidgetCount } from './types';
import { generateNameColor } from '../../../tools/generateColor';

const Count: React.FC<WidgetCount.CountProps> = (props) => {
  return (
    <BigUp.Widget.WidgetContainer dimensions={{ height: 140, width: 240 }}>
      <IonGrid>
        <IonRow className="ion-justify-content-between" style={{ height: 50 }}>
          <IonCol size="auto" className="ion-padding">
            <div style={{ background: generateNameColor(props.avatar) }} className={styles['count__avatar-container']}>
              <p className={`ion-no-margin ${styles['count__avatar-text']}`}>{props.avatar}</p>
            </div>
          </IonCol>
          <IonCol size="auto" className="ion-padding-end ion-padding-top">
            <BigUp.Label.V2Thick label={props.label} />
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-end ion-align-items-center" style={{ height: 20 }}>
          <IonCol size="auto" className="ion-padding-end">
            <div className={styles['count__count-container']}>
              <p className={styles['count__current-count']}>{props.count.current}</p>
              <p className={styles['count__total-count']}>/{props.count.total}</p>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </BigUp.Widget.WidgetContainer>
  );
};

export default Count;
