import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLoading,
  IonMenu, IonRippleEffect,
  IonRow, IonText, useIonPopover, useIonRouter
} from '@ionic/react';
import classNames from 'classnames';
import { appsSharp, documentText, home, podium } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import BigUp from '../..';
import type { MenuItem } from './MenuTypings';
import styles from './SideMenuV2.module.scss';
import { useAppSelector } from '../../../../hooks';
import { setSelectedProject } from '../../../../reducers/project';
import store from '../../../../store';

interface SideMenuV2OptionsProps {
  activeTab: string | undefined;
  setActiveTab: (tab: string) => void;
}

const SideMenuV2Options: React.FC<SideMenuV2OptionsProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const location = useLocation();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const [nextPath, setNextPath] = useState<string | undefined>(undefined);

  const onNavigate = (url: string) => router.push(url);
  const [present] = useIonPopover(BigUp.Popovers.ProjectSelectionPopover, {
    nextPath,
    onNavigate
  });

  const handleOpenProjectSelection = (e: React.MouseEvent, route: string, tab: string) => {
    setActiveTab(tab);
    setNextPath(route);
    present({
      event: e as any,
      dismissOnSelect: true,
      onDidDismiss: () => setNextPath(undefined)
    });
  };

  const items: MenuItem[] = [{
    icon: home,
    activeIcon: BigUp.Icons.Svg.Home,
    title: t('Home'),
    route: '/tools',
    tab: 'home',
    project_optional: true,
    onClick: () => store.dispatch(setSelectedProject(undefined)),
    match_priority: 0
  }, {
    icon: podium,
    activeIcon: BigUp.Icons.Svg.Projects,
    title: t('Projects'),
    route: undefined,
    tab: 'projects',
    match_priority: 0
  }, {
    icon: appsSharp,
    activeIcon: BigUp.Icons.Svg.Tools,
    title: t('Tools'),
    match_route: '/tools*',
    route: undefined,
    tab: 'tools',
    match_priority: 0
  }, {
    icon: documentText,
    activeIcon: BigUp.Icons.Svg.DocumentPrimary,
    title: t('Documents'),
    route: '/tools/:uuid/documents',
    match_route: '/tools/:uuid/documents',
    tab: 'documents',
    match_priority: 1
  }/*, { // FIXME: Commented out to for future use
    icon: star,
    activeIcon: BigUp.Icons.Svg.Favourites,
    title: t('Favourites'),
    route: '/favorites'
    tab: 'favourites'
  } */];

  const itemsWithProps: MenuItem[] = useMemo(() => items.map(item => ({
    ...item,
    route: item.route?.replace('/:uuid', selectedProjectId ? `/${selectedProjectId}` : '/:uuid')
  })), [items, selectedProjectId, activeTab]);

  const handleClick = (e: React.MouseEvent, item: MenuItem) => {
    if (item.onClick) {
      item.onClick();
    }
    if (item.route) {
      if (!selectedProjectId && !item.project_optional) {
        handleOpenProjectSelection(e, item.route, item.tab);
        return;
      } else {
        router.push(item.route);
      }
    }
    setActiveTab(item.tab);
  };

  const checkActiveTab = () => {
    const activeItem = itemsWithProps
      .sort((a, b) => (b.match_priority ?? 0) - (a.match_priority ?? 0))
      .find(
        item => item.match_route && matchPath(location.pathname, {
          path: item.match_route,
          exact: true,
          strict: false
        })
      );
    if (activeItem) {
      setActiveTab(activeItem.tab);
    }
  };

  useEffect(() => {
    checkActiveTab();
  }, []);

  useEffect(() => {
    checkActiveTab();
  }, [router.routeInfo.pathname]);

  return (
    <IonMenu className={styles['side-menu--root']} contentId="main">
      <IonContent className={classNames(styles['side-menu--bg'], 'ion-no-padding')}>
        <IonGrid className={styles['side-menu']}>
          {itemsWithProps.map((item, i) =>
            <IonRow
              key={i}
              className={classNames('ion-no-margin', styles['side-menu--row'])}
              onClick={(e) => handleClick(e, item)}
            >
              <IonCol
                size={'auto'}
                className={
                  classNames(
                    'ion-activatable ripple-parent',
                    styles['side-menu--item'],
                    item.tab === activeTab
                      ? styles['side-menu--item--active']
                      : ''
                  )
                }
                role={'button'}
              >
                <div className={styles['side-menu--item--icon-holder']}>
                  {activeTab && (item.tab === activeTab
                    ? <IonIcon icon={item.activeIcon} />
                    : <IonIcon icon={item.icon} />
                  )}
                </div>
                <IonText className={styles['side-menu--item--title']}>
                  <p className={'ion-no-margin'}>{item.title}</p>
                </IonText>
                <IonRippleEffect />
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <IonLoading isOpen={!activeTab} />
      </IonContent>
    </IonMenu>
  );
};

export default SideMenuV2Options;
