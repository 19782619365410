import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import ActionColumn from '../../../pages/Financials/Subpanels/Columns/ActionColumn';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import { formatToNiceDate } from '../../../tools/formatDates';
import BigUp from '../../UI';
import toasters from '../../UI/Toasts';

interface RowsSettingPageProps {
  type: 'projects' | 'clients';
  rows: any[];
  onRowChange: () => void;
}

const RowsList: React.FC<RowsSettingPageProps> = ({ onRowChange, rows, type }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [rowId, setRowId] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const methods = useForm();
  const { category_id } = useParams<{ client_id: string, category_id: string }>();

  const doDeleteRow = (row: any) => {
    networking.delete(`/api/v1/work_preparations/rows/${row}`)
      .then(() => {
        onRowChange();
        toasters.createToast({
          message: t('Row successfully removed')
        }, 'success');
      })
      .catch(() => {
        toasters.createToast({
          message: t('Something went wrong when trying to remove row.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'deletingRow', value: false }));
      });
  };

  const doRenameRow = (data: any) => {
    store.dispatch(setIsLoading({ name: 'renamingRow', value: true }));
    networking.put(`/api/v1/work_preparations/rows/${rowId}`, {
      name: data.name
    })
      .then(() => {
        onRowChange();
        toasters.createToast({
          message: t('Row successfully renamed')
        }, 'error');
      })
      .catch(() => {
        toasters.createToast({
          message: t('Failed to rename row. Please try again later or contact our support.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'renamingRow', value: false }));
      });
  };

  return (
    <>
      <BigUp.Table
        sourceUrl={`/api/v1/work_preparations/categories/${category_id}/rows?limit=9999`}
        reducers={{
          created_at: (value: string) => formatToNiceDate(value)
        }}
        columns={[{
          key: 'name',
          label: t('Name'),
          alignment: 'left',
          sizes: {
            xs: '5',
            sm: '3',
            md: '3',
            lg: '3',
            xl: '3'
          },
        }, {
          key: 'created_at',
          label: t('Created at'),
          alignment: 'left',
          sizes: {
            xs: '5',
            sm: '3',
            md: '3',
            lg: '3',
            xl: '3'
          },
        },
        {
          key: 'is_active',
          label: t('Active'),
          alignment: 'center',
          sizes: {
            xs: '5',
            sm: '3',
            md: '3',
            lg: '3',
            xl: '3'
          },
          body: (row) => <>{row.is_active ? t('Yes') : t('No')}</>
        }, {
          key: 'actions',
          label: t('Actions'),
          sortable: false,
          body: <ActionColumn
            type="rows"
            attributes={{} as any}
            callbacks={{
              onDetails: () => { },
              onEdit: (row) => {
                setModalOpen(true);
                methods.setValue('name', row?.name ?? '');
                setRowId(row.id);
              },
              onDelete: (row) => doDeleteRow(row)
            }}

          />,
          sizes: {
            xs: '5',
            sm: '3',
            md: '3',
            lg: '3',
            xl: '3'
          },
          alignment: 'right'
        }]}
      />

      <FormProvider {...methods}>
        <BigUp.Modal.InputModal
          maxHeight='230px'
          modal={{
            isOpen: modalOpen,
            onDismiss: () => setModalOpen(false),
            title: t('Rename row'),
          }}
          button={{
            type: 'submit',
            title: t('Save'),
            disabled: methods.formState.isSubmitting || !methods.formState.isValid,
            loading: false
          }}
          textAlignment='center'
          input={{
            name: 'name',
            type: 'text',
            placeholder: t('Enter name'),
            register: 'name',
            validation: {
              required: true,
              minLength: 1
            }
          }}
          onSubmit={methods.handleSubmit(doRenameRow)}
        />
      </FormProvider >
    </>
  );
};

export default RowsList;
