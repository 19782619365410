import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';

import './selectOption.scss';
import type { SelectOptionProps } from './interface';
import { useAppSelector } from '../../../hooks';

const SelectOptions = <T, >(props: SelectOptionProps<T>) => {
  const { getId, getName, itemPadding, label, options, placeholder, rest, selectInterface, selectInterfaceDesktop, selectedColour, selectedOption, selectedTextSize, setSelectedOption } = props;

  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);

  const handleSelectChange = (e: CustomEvent) => {
    const selectedId = e.detail.value;
    const option = options.find((o) => getId(o) === selectedId) || null;
    setSelectedOption(option, e);
  };

  return (

    <IonItem style={{ borderBottom: `1px solid var(--ion-color-light-shade)`, '--inner-padding-end': 0 }} className={itemPadding && itemPadding} color={'none'} {...selectedColour && { lines: 'none' }} >
      <IonSelect
        label={label}
        labelPlacement='stacked'
        {...rest}
        interfaceOptions={{
          cssClass: 'select-option-interface',
        }}
        {...isDesktop
          ? { interface: selectInterfaceDesktop?.options || 'popover' }
          : { interface: selectInterface || 'action-sheet' }
        }
        style={{
          color: selectedColour || 'var(--ion-color-light)',
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
        value={selectedOption ? getId(selectedOption) : undefined}
        placeholder={placeholder || `Select ${label}`}
        onIonChange={handleSelectChange}
      >
        {options.map((option) => (
          <IonSelectOption key={getId(option)} value={getId(option)}>
            {getName(option)}
          </IonSelectOption>
        ))}
        {props.childrenPosition === 'bottom' && props.children}
      </IonSelect>
    </IonItem>

  );
};

export default SelectOptions;
