import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonRow,
  IonText
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { close } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../../api/networking';
import toasters from '../../../../../components/Toasts/Toasts';
import BigUp from '../../../../../components/UI';
import i18n from '../../../../../i18n';

const NotPostedRowsColumn: React.FC<{
  editable: boolean;
  attributes: E2U.V1.Models.NotPostedRow;
  callbacks: {
    onUpdate: () => void;
  }
}> = (props) => {
  const { attributes: row, callbacks, editable } = props;

  const { t } = useTranslation();
  const [addEconomyMonitoring, setAddEconomyMonitoring] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);

  const methods = useForm<Partial<E2U.V1.Models.NotPostedRow>>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      not_posted_cost_total: row?.not_posted_cost_total ?? 0,
      not_posted_cost_total_comment: row?.not_posted_cost_total_comment ?? '',
    }
  });

  const notPostedCostTotal = methods.getValues('not_posted_cost_total');
  const notPostedCostTotalComment = methods.getValues('not_posted_cost_total_comment');

  const toggleAddEconomyMonitoring = () => {
    if (editable) {
      setAddEconomyMonitoring(!addEconomyMonitoring);
      callbacks.onUpdate();
    }
  };

  const onSubmit = (data: Partial<E2U.V1.Models.NotPostedRow>) => {
    toasters.promise(
      networking.put(`/api/v1/not_posted_rows/${row?.id}`, data),
      {
        pending: i18n.t('Adding economy monitoring.'),
        success: i18n.t('Economy monitoring added.'),
        error: i18n.t('Error while adding economy monitoring.'),
      }
    )
      .then(() => {
        toggleAddEconomyMonitoring();
        callbacks.onUpdate();
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {

      });
  };

  useEffect(() => {
    setUpdate(!update);
  }, [notPostedCostTotal, notPostedCostTotalComment]);

  return (
    <React.Fragment>
      {row && (
        <span
          onClick={() => setAddEconomyMonitoring(true)}>
          {
            row.not_posted_cost_total === 0
              ? editable
                ? '+ ' + i18n.t('add')
                : row.not_posted_cost_total
              : row.not_posted_cost_total
          }
        </span>
      )}
      <BigUp.Modal.Wrapper
        isOpen={addEconomyMonitoring}
        dismiss={() => setAddEconomyMonitoring(false)}
        header={{
          title: t('Not posted'),
          end: {
            icon: close,
            onClick: () => setAddEconomyMonitoring(false)
          }
        }}
      >
        <IonContent className='ion-no-padding'>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <IonGrid>
                <IonRow className='ion-justify-items-start ion-align-items-start'>
                  <IonCol size='12' className='ion-margin-top'>
                    <IonItem className='ion-no-padding ion-margin-bottom'>
                      <IonText color='medium' className='ion-margin-bottom'>
                        <BigUp.Label.Thick label={t('Current Value')} />
                        <BigUp.Label.Regular className='ion-no-margin' label={row?.not_posted_cost_total} />
                      </IonText>
                      <IonText color='medium' className='ion-margin-bottom ion-margin-start'>
                        <BigUp.Label.Thick label={t('Previous Value')} />
                        <BigUp.Label.Regular className='ion-no-margin' label={row?.previous_not_posted_cost_total} />
                      </IonText>
                    </IonItem>
                    <BigUp.OutlinedInput
                      customLabel={t('Set quantity')}
                      isCurrency
                      register={'not_posted_cost_total'}
                      validation={{
                        required: t('This field is required')
                      }}
                      inputMode='numeric'
                      placeholder="Enter amount"
                    />
                  </IonCol>
                  <IonCol size='12' className='ion-margin-top'>
                    <BigUp.TextareaOutlined
                      itemProps={{
                        className: 'ion-no-padding'
                      }}
                      customLabel={t('Comment')}
                      register='not_posted_cost_total_comment'
                      autoGrow
                      placeholder={i18n.t('Enter a description')}
                      maxlength={140}
                      validation={{
                        required: t('This field is required')
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className='ion-margin-bottom'>
                <IonRow className='ion-justify-content-center ion-align-items-center ion-margin-top'>
                  <IonCol size='10' className='ion-text-center'>
                    <BigUp.Buttons.Primary
                      title={t('Submit')}
                      type='submit'
                      disabled={
                        typeof update !== 'undefined' && (
                          methods.formState.isDirty ||
                          !methods.formState.isValid
                        )
                      }
                      onClick={() => onSubmit(methods.getValues())}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </FormProvider>
        </IonContent>
      </BigUp.Modal.Wrapper>

    </React.Fragment>
  );
};
export default NotPostedRowsColumn;
