import type { IonIcon } from '@ionic/react';
import type { ComponentProps } from 'react';

import BigUp from '../../../components/UI';
import i18n from '../../../i18n';

export interface ComingSoonList {
  open?: boolean;
  title: string;
  message: string;
  icon: ComponentProps<typeof IonIcon>['icon']
}

export const comingSoonItems: ComingSoonList[] = [{
  icon: BigUp.Icons.Svg.TimeReport,
  title: i18n.t('Time reporting'),
  message: 'This feature is coming soon'
}, {
  icon: BigUp.Icons.Svg.Diary,
  title: i18n.t('Diary'),
  message: 'This feature is coming soon'
}, {
  icon: BigUp.Icons.Svg.Resources,
  title: i18n.t('Resources'),
  message: 'This feature is coming soon'
}, {
  icon: BigUp.Icons.Svg.Weather,
  title: i18n.t('Weather'),
  message: 'This feature is coming soon'
}];
