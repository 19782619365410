import { IonCol, IonRow, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector(state => state.authentication.user);
  return (
    <>
      <IonRow className='ion-justify-content-center'>
        <IonCol size='auto'>
          <h1>{t('Welcome {userFirstname}!', { userFirstname: user?.first_name })}</h1>
        </IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-center ion-margin-top ion-padding-horizontal ion-padding-bottom'>
        <IonCol size='12' className='ion-text-center'>
          <IonText>
            <span style={{ color: 'var(--ion-color-medium)' }}>
              {t('To begin using')} <i style={{ fontWeight: 700, fontSize: 18, fontStyle: 'normal' }}>{t('BigUp')}</i> {t('in your projects, you can choose between being called within 30 seconds and get help with the installation, or enter the necessary information yourself.')}
            </span>
          </IonText>
        </IonCol>
      </IonRow>
    </>
  );
};

export default Header;
