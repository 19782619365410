import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import styles from './LoginForm.module.scss';
import { useBankIdContext } from '../../bankid/bankid.context';
import useFocusNextInput from '../../hooks/useFocusNextInput';
import useLogin from '../../hooks/useLogin';
import { regex } from '../../regex/regex';
import { BigUp } from '../UI';
import BankID from '../UI/BankID/BankID';

interface LoginCredentials {
  username: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const [triggerBankId, setTriggerBankId] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const methods = useForm<LoginCredentials>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      username: undefined,
      password: undefined
    }
  });
  const { t } = useTranslation();
  const { focusNextInput, formRef } = useFocusNextInput();
  const login = useLogin();
  const bankid = useBankIdContext();
  const location = useLocation();
  const history = useHistory();
  const email = methods.getValues('username');
  const errors = methods.formState.errors;
  const username = methods.watch('username');
  const password = methods.watch('password');

  const handleNextInput = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key === 'Enter') {
      focusNextInput(e.target as HTMLInputElement);
    }
  };

  const handleRegistrationRedirected = () => {
    const locationState = (location.state as { bankid?: boolean })?.bankid;
    if (locationState && locationState === true) {
      setTriggerBankId(true);
    }
  };

  const cancelBankIdRequest = () => {
    history.push({
      pathname: '/login',
      state: { bankid: false }
    });
    const locationState = (location.state as { bankid?: boolean })?.bankid;
    if (locationState === false) {
      setTriggerBankId(false);
      bankid?.abortBankIdAuthentication();
    }
  };

  useEffect(() => {
    handleRegistrationRedirected();
  }, [location]);
  useEffect(() => {
    if (triggerBankId) {
      bankid?.authenticate.initiateBankIdAuthentication();
    } else {
      bankid?.abortBankIdAuthentication();
    }
  }, [triggerBankId]);

  useEffect(() => {
    if (username || password) {
      login.clearStorage();
    }
    methods.clearErrors('username');
  }, [username, password]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={styles['login-form-content']}
          onSubmit={methods.handleSubmit(login.handleLogin)}
          ref={formRef}
        >
          <IonGrid>
            {login.result && (
              <BigUp.Cards.Alert type='error'>
                {t(`It looks like you've entered an incorrect email address or password. Forgot your password? `)}
                <button type='button' className={styles['forgot-password']} onClick={() => setResetPasswordModalOpen(true)}>
                  {t('Click here')}</button> {t(`to reset it.`)} {t(`Want to create a new account?`)}
                <Link className={styles['forgot-password']} to={'/registration'}> {t(`Click here`)}</Link> {t(`to sign up.`)}
              </BigUp.Cards.Alert>
            )}
            <IonRow>
              <IonCol className='ion-margin-bottom'>
                <BigUp.OutlinedInput
                  disabled={login.loading}
                  id='email'
                  name='email'
                  inputMode='email'
                  customLabel={t('Email')}
                  register='username'
                  autocomplete='email'
                  enterkeyhint='next'
                  onKeyUp={handleNextInput}
                  autocorrect='on'
                  serverError={login.result}
                  forceError={errors.username?.message}
                  placeholder={t('Enter email')}
                  validation={{
                    required: {
                      value: true,
                      message: t('Email is required')
                    },
                    pattern: {
                      value: regex.regex_email,
                      message: t('Invalid email address')
                    }
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <BigUp.OutlinedInput
                  disabled={login.loading}
                  id='password'
                  name='password'
                  register='password'
                  type='password'
                  customLabel={t('Password')}
                  enterkeyhint='done'
                  serverError={login.result}
                  onKeyUp={handleNextInput}
                  forceError={errors.password?.message}
                  placeholder={t('Enter password')}
                  validation={{
                    required: {
                      value: true,
                      message: t('Password is required')
                    },
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow className='ion-align-items-center'>
              <IonCol className='ion-text-right'>
                <button onClick={() => setResetPasswordModalOpen(true)} type='button' className={styles['forgot-password']}>
                  {t('Forgot your password?')}
                </button>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid className={styles['button-wrapper']}>
            <IonRow className='ion-justify-content-space-between ion-align-items-center'>
              <IonCol size='12'>
                <BigUp.Buttons.Primary
                  loading={login.loading}
                  expand='full'
                  title={t('Sign in')}
                  type='submit'
                  disabled={!methods.formState.isValid || methods.formState.isSubmitting || login.loading}
                />
              </IonCol>
              <BigUp.Seperator.LabeledSeparator label={t('or')} />
              <IonCol size='12'>
                <BankID.BankIdButton
                  disabled={login.loading}
                  title=''
                  shape='round'
                  expand='full'
                  onClick={() => setTriggerBankId(true)}
                >
                  <BankID.BankIdIcon color='white' width={40} /> {t('Open BankID')}
                </BankID.BankIdButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </FormProvider>

      <BigUp.Modal.Wrapper
        scroll={false}
        height='800px'
        header={{
          title: t('Sign in with BankID'),
          end: {
            icon: close,
            onClick: cancelBankIdRequest

          }
        }}
        isOpen={triggerBankId}
        dismiss={cancelBankIdRequest}
      >
        <BankID.BankIdLogin />
      </BigUp.Modal.Wrapper>

      <BigUp.Modal.ResetPassword
        defaultValue={email}
        onSubmit={() => setResetPasswordModalOpen(false)}
        modal={{
          isOpen: resetPasswordModalOpen,
          onDismiss: () => setResetPasswordModalOpen(false),
          title: t('Reset password'),
          description: t('Enter your email address to reset your password.'),
          successMessage: t('If the email is registered, a reset link has been sent to your email.')
        }}
        button={{
          title: t('Send'),
          disabled: false,
          type: 'submit'
        }}
        input={{
          id: 'email',
          name: 'email',
          register: 'email',
          placeholder: t('Enter email'),
          validation: {
            required: {
              value: true,
              message: t('Email is required')
            },
            pattern: {
              value: regex.regex_email,
              message: t('Invalid email address')
            }
          },
        }}
      />
    </>
  );
};

export default LoginForm;
