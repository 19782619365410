import type { E2U } from '@techlove/easy2use-typings';
import { folderOpen, hammerOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MultiSelector from '../../components/UI/MultiSelector';
import type { MultiSelectorProps } from '../../components/UI/MultiSelector/interfaces';

interface DocumentFilterProps {
  sourceUrl: MultiSelectorProps['sourceUrl'];
  group?: MultiSelectorProps['group'];
}

const DocumentCategoryFilter: React.FC<DocumentFilterProps> = (props) => {
  const { group, sourceUrl } = props;
  const { t } = useTranslation();
  return (
    <MultiSelector
      title={t('Category')}
      identifier='categories'
      triggerProps={{
        icon: folderOpen,
        label: t('Category')
      }}
      sourceUrl={sourceUrl}
      callbacks={{
        parseResponse: (response: {
          id: string;
          name: string;
          slug: string;
          disabled: boolean;
        }[]) => {
          return response.map((item: any) => ({
            label: item.name,
            value: item.id,
            disabled: item.disabled,
            children: item.children?.map((child: any) => ({
              label: child.name,
              value: child.id,
              disabled: child.disabled,
            }))
          }));
        }
      }}
      group={group} />
  );
};

const DocumentActivityCodeFilter: React.FC<DocumentFilterProps> = (props) => {
  const { group, sourceUrl } = props;
  const { t } = useTranslation();

  return (
    <MultiSelector
      title={t('Activity Codes')}
      identifier='activity_codes'
      triggerProps={{
        icon: hammerOutline,
        label: t('Activity code'),
        chips: {
          triggerPlacement: 'inner-top',
          type: 'group'
        }
      }}
      sourceUrl={sourceUrl}
      callbacks={{
        parseResponse: (response: (E2U.V1.Models.ActivityCategory & {
          activity_codes: (E2U.V1.Models.ActivityCode & {
            documents_count: number;
          })[];
        })[]) => {
          return response.map(
            (item) => {
              const childCount = (item.activity_codes ?? []).reduce(
                (acc: number, child) => acc + child.documents_count, 0
              );
              return ({
                label: `${item.code_prefix} ${item.name}`,
                value: item.id,
                disabled: childCount === 0,
                children: (item.activity_codes ?? []).map((child) => ({
                  label: `${child.code} ${child.name}`,
                  value: child.id,
                  count: child.documents_count,
                  disabled: child.documents_count === 0,
                }))
              });
            });
        }
      }}
      group={group} />
  );
};

const DocumentExtensionCodeFilter: React.FC<DocumentFilterProps> = (props) => {
  const { group, sourceUrl } = props;
  const { t } = useTranslation();
  return (
    <MultiSelector
      title={t('Type')}
      identifier='extensions'
      triggerProps={{
        label: t('Type'),
      }}
      sourceUrl={sourceUrl}
      callbacks={{
        parseResponse: (response: {
          extension: string;
          documents_count: number;
        }[]) => {
          return response.map((item) => ({
            label: item.extension.toUpperCase(),
            value: item.extension,
            disabled: item.documents_count === 0,
            count: item.documents_count
          }));
        }
      }}
      group={group} />
  );
};

export {
  DocumentCategoryFilter,
  DocumentActivityCodeFilter,
  DocumentExtensionCodeFilter
};
