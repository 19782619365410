import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import equal from 'fast-deep-equal/react';
import { add, link } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import BigUp from '../../../../components/UI';
import MultiSelector from '../../../../components/UI/MultiSelector';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import { setSelectedCategory } from '../../../../reducers/document';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
import ActionColumn from '../../../Financials/Subpanels/Columns/ActionColumn';

const ShowDocumentCategory: React.FC = () => {
  const { t } = useTranslation();
  const { category_id } = useParams<{ uuid: string | undefined, category_id: string | undefined }>();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);
  const [lastTimestamp, setLastTimestamp] = useState<number>(Date.now());
  const isLoadingCategory = useAppSelector(state => state.loading.isLoading.documentCategory);
  const router = useIonRouter();
  const selectedDocumentType = useAppSelector(state => state.document.selectedCategory);
  const existingTags = useMemo(() => selectedDocumentType?.tags?.map((tag) => tag.id) ?? [], [selectedDocumentType]);

  const fetchCategory = () => {
    if (!category_id) {
      return;
    }
    store.dispatch(setIsLoading({ name: 'documentCategory', value: true }));
    networking.get(`/api/v1/document_types/${category_id}?with=tags.category`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Type>) => {
        store.dispatch(setSelectedCategory(response.data.data));
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'documentCategory', value: false }));
      });
  };

  const updateTags = (data: string[]) => {
    if (!selectedDocumentType) {
      return;
    }
    const tagsToAttach = data
      .filter(
        (tagId) => (selectedDocumentType?.tags ?? []).find((tag) => tag.id === tagId) === undefined
      )
      .map(tagId => networking.post(`/api/v1/document_types/${category_id}/tags/${tagId}`));
    const tagsToDetach = (selectedDocumentType?.tags ?? [])
      .filter(
        (tag: E2U.V1.Models.Tag) => data.find((newTagId) => newTagId === tag.id) === undefined && !tag.is_system_record && !tag.is_protected
      )
      .map(tag => networking.delete(`/api/v1/document_types/${category_id}/tags/${tag.id}`));
    toasters.promiseToast(
      Promise.all([...tagsToAttach, ...tagsToDetach]),
      {
        pending: {
          message: t('Updating tags')
        },
        success: {
          message: t('Tags updated successfully')
        },
        error: {
          message: t('Failed to update tags')
        }
      }
    )
      .catch((err) => Sentry.captureException(err))
      .finally(() => {
        setLastTimestamp(Date.now());
        fetchCategory();
      });
  };

  const handleDetachTag = (id: string, name: string) => {
    toasters.promiseToast(
      networking.delete(`/api/v1/document_types/${category_id}/tags/${id}`),
      {
        pending: {
          message: t('Removing tag {name}', {
            name
          })
        },
        success: {
          message: t('Tag removed successfully')
        },
        error: {
          message: t('Failed to remove tag')
        }
      }
    )
      .catch((err) => Sentry.captureException(err))
      .finally(() => {
        setLastTimestamp(Date.now());
        fetchCategory();
      });
  };

  useEffect(() => {
    fetchCategory();
  }, [category_id]);

  useEffect(() => {
    fetchCategory();
    return () => {
      store.dispatch(setSelectedCategory(undefined));
    };
  }, []);

  return (
    <DesktopWrapper>
      {(!selectedProjectId || !category_id || isLoadingCategory)
        ? <SkeletonTextThreeLines />
        : <>
          <IonGrid>
            <IonRow className={'ion-align-items-center'}>
              <IonCol>
                <BigUp.Label.V2Thick
                  label={t('Tags for {categoryName}', {
                    categoryName: selectedDocumentType?.name
                  })}
                />
              </IonCol>
              <IonCol size={'auto'}>
                <MultiSelector
                  title={t('Connect tags')}
                  identifier='tags'
                  triggerProps={{
                    icon: link,
                    label: t('Connect'),
                    hideChevron: true,
                    chips: {
                      hide: true
                    }
                  }}
                  sourceUrl={{
                    url: `/api/v1/projects/${selectedProjectId}/tag_categories`,
                    args: {
                      'with[]': 'tags',
                      filters: JSON.stringify([
                        {
                          field: 'null',
                          value: 'system_record_class'
                        }
                      ])
                    }
                  }}
                  callbacks={{
                    parseResponse: (response: {
                      code: string;
                      name: string
                    }[]) => {
                      return response.map((item: E2U.V1.Models.TagCategory) => ({
                        label: item.name,
                        value: item.id,
                        children: item.tags?.map((tag) => ({
                          label: tag.name,
                          value: tag.id
                        })) ?? []
                      }));
                    },
                    handleUserSelection: (items: string[]) => {
                      if (!equal(existingTags, items)) {
                        updateTags(items);
                      }
                    }
                  }}
                  defaultValue={selectedDocumentType?.tags?.map((tag) => tag.id) ?? []}
                  excludeSelectedParentFromCallback={true}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <BigUp.Table
            callbacks={{
              onRowClick: (model) => router.push(`/tools/${selectedProjectId}/settings/tags/${model.category_id}`)
            }}
            columns={[
              {
                key: 'name',
                label: t('Name'),
                alignment: 'left',
                sizes: {
                  xs: '5',
                  sm: '5',
                  md: '6',
                  lg: '6',
                  xl: '6'
                },
                body: <BigUp.LockableNameColumn />
              },
              {
                key: 'category.name',
                label: t('Group name'),
                alignment: 'left',
                sizes: {
                  xs: '4',
                  sm: '5',
                  md: '5',
                  lg: '5',
                  xl: '5'
                },
              },
              {
                key: 'actions',
                label: t('Actions'),
                sortable: false,
                body: (row: E2U.V1.Models.Tag) => (!row.is_system_record && !row.is_protected)
                  ? (
                    <ActionColumn
                      type={'tags'}
                      routePrefix={'/settings'}
                      typeLabel={t('Tags')}
                      attributes={{} as E2U.V1.Models.Tag}
                      callbacks={{
                        onDelete: (id: string, name?: string) => handleDetachTag(id, name ?? ''),
                      }}
                      customIdentifierColumn={'category_id'}
                    />
                  )
                  : <></>,
                sizes: {
                  xs: '3',
                  sm: '2',
                  md: '1',
                  lg: '1',
                  xl: '1'
                },
                alignment: 'right',

              }
            ]}
            reducers={{
            }}
            sourceUrl={{
              url: `/api/v1/document_types/${category_id}/tags`,
              args: {
                'sort_by[]': ['is_system_record', 'protected_at', 'name'],
                with: 'category',
                filters: JSON.stringify([
                  {
                    field: 'null',
                    value: 'system_record_class'
                  }
                ])
              }
            }}
            timestamp={lastTimestamp}
          />
        </>
      }
    </DesktopWrapper>
  );
};

export default ShowDocumentCategory;
