import { IonCol, IonGrid, IonLabel, IonRow, IonSelect, IonSelectOption, IonToggle, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add, trash } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { precalculationRowInputs } from './Form/formProps';
import styles from './Form/PrecalculationForm.module.scss';
import { networking } from '../../../../api/networking';
import RelatedMultiSelectEdit from '../../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import BigUp from '../../../../components/UI';
import EmptyList from '../../../../components/UI/EmptyList';
import toasters from '../../../../components/UI/Toasts';

const AddPrecalculationRows: React.FC = () => {
  const [selectedActivityCodes, setSelectedActivityCodes] = useState<E2U.V1.Models.ActivityCode[]>([]);
  const { t } = useTranslation();
  const { precalculationId } = useParams<{ precalculationId: string }>();

  interface DefaultValues {
    rows: {
      [activity_code_id: string]: {
        cost_per_unit: number;
        quantity: number;
        secured_cost: boolean;
        unit: string;
        name: string;
        description: string;
      }
    }
  }

  const methods = useForm<DefaultValues>({
    defaultValues: {
      rows: {}
    }
  });
  const rows = methods.watch('rows');

  const handleSecuredCostToggle = (id: string) => {
    const securedCost = rows[id].secured_cost;
    methods.setValue(`rows.${id}.secured_cost`, !securedCost);
  };
  const router = useIonRouter();

  const removeActivityCodeFromList = (id: E2U.V1.Models.ActivityCode['id']) => {
    const updatedSelectedActivityCodes = selectedActivityCodes.filter(ac => ac.id !== id);
    setSelectedActivityCodes(updatedSelectedActivityCodes);
    const updatedRows = { ...rows };
    delete updatedRows[id ?? ''];
    methods.reset({ rows: updatedRows });
  };

  const handleSave = () => {
    const promises = Object.keys(rows).map((id) => {
      return networking.post(`api/v1/precalculation_rows`, {
        ...rows[id],
        activity_code_id: id,
        precalculation_id: precalculationId
      });
    });

    Promise.all(promises)
      .then(() => {
        toasters.createToast({
          message: t('Precalculation rows saved'),
        }, 'success');

        setSelectedActivityCodes([]);
        methods.reset({ rows: {} });

        router.push(`/financials/precalculations/${precalculationId}`);
      })
      .catch((error) => {
        toasters.createToast({
          message: t('Something went wrong. Please try again.'),
        }, 'error');
        Sentry.captureException(error);
      });
  };

  const items = (id: E2U.V1.Models.ActivityCode['id']) => id
    ? precalculationRowInputs(id).map((input: any, i: number) => {
      let content;

      if (input.type === 'select') {
        content = (
          <IonSelect
            className={styles['quantity-select']}
            mode='md'
            justify='space-between'
            color={'medium'}
            label={input.label}
            labelPlacement='start'
            key={input.id}
            value={methods.getValues(`rows.${id}.${input.id}`)}
            placeholder={'Select Option'}
            interface="popover"
            onIonChange={(e) => {
              methods.setValue(`rows.${id}.${input.id}`, e.detail.value);
            }}
          >
            {input.options?.map((option: any) => (
              <IonSelectOption key={option.value} value={option.value}>
                {option.label}
              </IonSelectOption>
            ))}
          </IonSelect>
        );
      } else {
        content = (
          <BigUp.Input
            register={`rows.${id}.${input.id}`}
            className='ion-no-padding'
            itemProps={{ className: 'ion-no-padding' }}
            style={{
              textAlign: 'end',
              '--color': 'var(--ion-color-medium)',
              fontSize: '0.9rem'
            }}
            key={input.id}
            {...input}
            lines={false}
            label={methods.formState.errors?.rows?.[id]?.[input.id]?.message
              ? `${input.label} *`
              : input.label
            }
            labelPlacement='start'
            type={input.type as any}
          />
        );
      }
      return {
        id: i,
        content
      };
    })
    : [];

  useEffect(() => {
    if (selectedActivityCodes.length === 0) {
      methods.reset({ rows: {} });
    } else {
      let newRows = { ...rows };
      selectedActivityCodes.forEach((ac) => {
        if (!newRows[ac?.id ?? '']) {
          newRows[ac?.id ?? ''] = {
            cost_per_unit: 0,
            quantity: 0,
            secured_cost: false,
            unit: '',
            name: '',
            description: ''
          };
        }
      });
      newRows = Object.keys(newRows).reduce<{
        [activity_code_id: string]: {
          cost_per_unit: number;
          quantity: number;
          secured_cost: boolean;
          unit: string;
          name: string;
          description: string;
        }
      }>((acc, key) => {
        if (selectedActivityCodes.find(ac => ac.id === key)) {
          acc[key] = newRows[key];
        }
        return acc;
      }, {});
      methods.reset({ rows: newRows });
    }
  }, [selectedActivityCodes]);

  return (
    <>
      <div className={styles['precalculation-row-form']} style={{ height: '100%' }}>
        <IonGrid style={{ background: 'var(--ion-color-light)' }} className='ion-padding-horizontal'>
          <IonRow className='ion-margin-top'>
            <IonCol>
              <BigUp.Label.V2Thick label={t('Activity code')} />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-bottom'>
            <IonCol sizeXs='12'>
              <BigUp.Typography.Description>
                {t('Add one or more activity codes to be a part of your project.')}
              </BigUp.Typography.Description>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RelatedMultiSelectEdit
                pushToUrl={{
                  shouldPush: true,
                  url: `activitycodes`
                }}
                button={'v2'}
                icon={{ icon: add }}
                model={'activity_codes'}
                onChange={(results: E2U.V1.Models.ActivityCode[]) => setSelectedActivityCodes(results)}
                label={t('Select activity codes')}
                value={selectedActivityCodes}
                displayFields={['code', 'name']}
                modalTitle={t('Select activity codes')}
                hideSelected={true}
                ionButtonProps={{
                  color: 'primary',
                }}
                leftIcon={false}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSave)}
            style={{ height: '100%', background: 'var(--ion-color-light-shade)' }}>
            <IonGrid style={{ height: '100% !important' }} className={styles['precalculation-row-form-content']}>
              {Object.keys(rows).map((id, i) => {
                const row = rows[id];
                const activityCode = selectedActivityCodes.find(ac => ac.id === id);
                return (
                  <IonRow key={i}>
                    <IonCol>
                      <div className={styles['precalculation-row-form']}>
                        <div className={`ion-margin-top ${styles['precalculation-row-form-header']}`}>
                          <BigUp.Label.Regular className='ion-no-margin' color={'medium'}
                            label={t(`${activityCode?.code} - ${activityCode?.name}`)} />
                          <BigUp.Buttons.Icon
                            onClick={() => removeActivityCodeFromList(id)}
                            icon={{
                              icon: trash,
                              color: 'medium',
                              size: 'large'
                            }}
                            fill='clear'
                            padding={10}
                          />
                        </div>
                        <div className={styles['input-list-container']}>
                          <BigUp.ItemList
                            items={items(id)}
                            extraRow={{
                              hasExtraRow: true,
                              item: { detail: false, button: false },
                              content: (
                                <div className={styles['toggle-container']}>
                                  <IonToggle
                                    mode='ios'
                                    name={`rows.${id}.secured_cost`}
                                    labelPlacement='start'
                                    onClick={() => handleSecuredCostToggle(id)}
                                    checked={row.secured_cost}
                                  >
                                    <IonLabel className='ion-no-margin' color='medium'>
                                      {t('Secured cost')}
                                    </IonLabel>
                                  </IonToggle>
                                </div>
                              )
                            }}
                          />
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                );
              })}
            </IonGrid>
            {selectedActivityCodes.length === 0
              ? (
                <EmptyList
                  title={t('No activity codes selected')}
                  message={
                    <span>
                      {t('You have not selected any activity codes yet.')}
                    </span>
                  }
                />
              )
              : (
                <IonRow className='ion-justify-content-center ion-align-items-center ion-margin-top'>
                  <IonCol size='auto'>
                    <BigUp.Buttons.Primary type='submit' title={t('Save')} disabled={!methods.formState.isValid} expand='block' style={{ maxWidth: 220 }} />
                  </IonCol>
                </IonRow>
              )
            }
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddPrecalculationRows;
