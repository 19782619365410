import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';

import styles from './Wrapper.module.scss';
import type WrapperProps from './WrapperProps';

const Wrapper: React.FC<WrapperProps> = ({
  children,
  content = {
    hasPadding: true,
    isCustom: false
  },
  dismiss,
  header,
  height,
  isOpen,
  scroll = true,
  width
}) => {
  const { end, start, title } = header;

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={dismiss}
      className={styles['wrapper-container']}
      style={{
        '--height': height || '600px',
        '--min-height': height || '400px',
        '--min-width': width || '400px',
      }}
    >
      <IonHeader className={`ion-no-border  ${styles['modal-header']}`}>
        <IonToolbar mode='ios' className='ion-padding-top'>
          {start && (
            <IonButtons slot="start" className={styles['header-button-start']} >
              <IonButton
                {...start.button}
                onClick={start.onClick}
              >
                {start.button?.title
                  ? start.button.title
                  : <IonIcon slot="icon-only" icon={start.icon} />
                }
              </IonButton>
            </IonButtons>
          )}
          <IonTitle>{title}</IonTitle>
          {end && (
            <IonButtons slot="end" className={styles['header-button-start']}>
              <IonButton
                {...end.button}
                onClick={end.onClick}
              >
                {end.button?.title
                  ? end.button?.title
                  : <IonIcon slot="icon-only" icon={end.icon} />
                }
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      {content?.isCustom
        ? children
        : (
          <IonContent
            scrollY={scroll}
            className={
              `${content?.hasPadding && 'ion-padding'} 
          ${styles['modal-content']}`
            }>
            {children}
          </IonContent>
        )}
    </IonModal>
  );
};

export default Wrapper;
