import {
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../../api/networking';
import SkeletonTextThreeLines from '../../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../../../components/Toasts/Toasts';
import BigUp from '../../../../../components/UI';
import CreateButton from '../../../../../components/WorkPreparation/Categories/CreateButton';
import CategoriesList from '../../../../../components/WorkPreparation/Categories/List';
import { useAppSelector } from '../../../../../hooks';
import { setIsLoading } from '../../../../../reducers/loading';
import store from '../../../../../store';

interface CategoriesSettingPageProps {
  type: 'projects' | 'clients';
}

const CategoriesSettingPage: React.FC<CategoriesSettingPageProps> = ({ type }) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { client_id, section_id } = useParams<{ client_id: string, section_id: string }>();
  const id = useMemo(() => type === 'projects' ? selectedProjectId : client_id, [
    selectedProjectId,
    type,
    client_id
  ]);
  const [categories, setCategories] = useState<any[]>([]);
  const [section, setSection] = useState<any>();
  const loadingCategories = useAppSelector(state => state.loading.isLoading.fetchingCategories);
  const loadingSection = useAppSelector(state => state.loading.isLoading.fetchingSection);

  const fetchCategories = () => {
    if (!id) {
      return;
    }
    store.dispatch(setIsLoading({
      name: 'fetchingCategories',
      value: true
    }));
    networking.get(`/api/v1/work_preparations/sections/${section_id}/categories?limit=9999`)
      .then((res: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<any>>) => {
        setCategories(res.data.data.records);
      })
      .catch(() => {
        toasters.error(t('Failed to fetch categories. Please try again later or contact our support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'fetchingCategories',
          value: false
        }));
      });
  };

  const fetchSection = () => {
    if (!section_id) {
      return;
    }
    store.dispatch(setIsLoading({
      name: 'fetchingSection',
      value: true
    }));
    networking.get(`/api/v1/work_preparations/sections/${section_id}`)
      .then((res: E2U.V1.Response.Success<any>) => {
        setSection(res.data.data);
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to fetch category. Please try again later or contact our support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'fetchingSection',
          value: false
        }));
      });
  };

  useEffect(() => {
    fetchSection();
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [type, id]);

  useEffect(() => {
    fetchSection();
  }, [section_id]);

  return (
    <IonGrid className='ion-no-paddding'>
      <CreateButton
        onCategoryCreated={() => fetchCategories()}
        type={type}
        id={selectedProjectId ?? ''}
      />
      {(loadingCategories || loadingSection)
        ? (
          <IonRow className='ion-no-paddding'>
            <IonCol>
              <SkeletonTextThreeLines />
            </IonCol>
          </IonRow>
        )
        : (
          <IonRow className='ion-no-paddding'>
            <IonCol>
              <CategoriesList
                type={type}
                onCategoryChange={() => fetchCategories()}
                categories={categories}
              />
            </IonCol>
          </IonRow>
        )}
    </IonGrid>
  );
};

export default CategoriesSettingPage;
