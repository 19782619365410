import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonNav,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router';

import { AbilitiesWatchedRoute } from './AbilitiesWatchedRoute';
import Examples from './Examples';
import ProtectedRoute from './ProtectedRoute';
import { NetworkInterceptor } from '../components/NetworkInterceptors/NetworkInterceptor';
import SideMenuV2Layout from '../components/UI/SideMenu/V2/SideMenuV2Layout';
import { matchUuid } from '../constants/matchUuid';
import { onboardingPath, superOptionalOnboardingRoute } from '../constants/onboarding';
import AppLayout from '../layout/AppLayout';
import ClientAccountPage from '../pages/ClientAccount';
import DefaultRoute from '../pages/DefaultRoute';
import HomePage from '../pages/Home';
import IntroductionPage from '../pages/Introduction';
import AcceptInvitationPage from '../pages/Invitation/AcceptInvitationPage';
import Login from '../pages/Login';
import ConsumePasswordResetPage from '../pages/Login/ConsumePasswordResetPage';
import Onboarding from '../pages/Onboarding';
import SettingsPage from '../pages/ProfilePage/Settings';
import Registration from '../pages/Registration';
import ClickupForm from '../pages/Support/ClickupForm';
import Support from '../pages/Support/Support';
import UserPhoto from '../pages/UserPhoto/index';

const ProtectedRouteContent = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <NetworkInterceptor />
      <ProtectedRoute path={superOptionalOnboardingRoute} exact={true}>
        <ProtectedRoute path={onboardingPath}>
          <Onboarding path={onboardingPath} />
        </ProtectedRoute>
        <AppLayout>
          <HomePage />
        </AppLayout>
      </ProtectedRoute>
      <Route path="/registration" exact={true}>
        <Registration />
      </Route>
      <Route path={`/reset/:uuid(${matchUuid})`} exact={true}>
        <ConsumePasswordResetPage />
      </Route>
      <Route path={'/login'} exact={true}>
        <Login />
      </Route>
      <AbilitiesWatchedRoute path={'/introduction'} exact={true}>
        <IntroductionPage />
      </AbilitiesWatchedRoute>
      <AbilitiesWatchedRoute path={'/client-account'}>
        <AppLayout scroll={true}>
          <ClientAccountPage />
        </AppLayout>
      </AbilitiesWatchedRoute>
      <AbilitiesWatchedRoute path={'/user-photo'} exact={true}>
        <SideMenuV2Layout><UserPhoto /></SideMenuV2Layout>
      </AbilitiesWatchedRoute>
      <Route path={`/join/:uuid(${matchUuid})`} exact={true}>
        <AppLayout>
          <AcceptInvitationPage />
        </AppLayout>
      </Route>

      <AbilitiesWatchedRoute path={'/components'} exact={true}>
        <IonNav root={() => (
          <IonPage>
            <IonHeader>
              <IonToolbar style={{
                '--min-height': '80px',
                '--padding-top': '20px',
                '--padding-bottom': '20px',
                '--padding-start': '10px',
              }}>
                <IonButtons slot="start" onClick={() => history.goBack()}>
                  <IonBackButton defaultHref="#"></IonBackButton>
                </IonButtons>
                <IonTitle>{t('E2C Components')}</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <Examples />
            </IonContent>
          </IonPage>
        )}
        />
      </AbilitiesWatchedRoute>
      <Route path={`/support/report-error`} exact={true}>
        <ClickupForm />
      </Route>
      <Route path={`/support`} exact={true}>
        <Support />
      </Route>
      <AbilitiesWatchedRoute path={`/settings`} exact={true}>
        <SettingsPage />
      </AbilitiesWatchedRoute>
      <Route path={'/'} exact={true}>
        <DefaultRoute />
      </Route>
    </>
  );
};

export default ProtectedRouteContent;
