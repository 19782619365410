import { IonCol, IonContent, IonGrid, IonList, IonRow, IonSplitPane } from '@ionic/react';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';

import styles from './SideMenuV2.module.scss';
import SideMenuV2Options from './SideMenuV2Options';
import { SideMenuV2Submenus } from './Submenus';
import BigUp from '../..';
import { useAppSelector } from '../../../../hooks';
import AppLayout from '../../../../layout/AppLayout';
import storage from '../../../../storage';
import ReturnChevronText from '../../Return/ReturnChevronText';
import type { ColourVariables } from '../../variables';
import SubmenuRoot from './Submenus/SubmenuRoot';

export type BaseUrlOptions = 'financials' | 'tools' | 'notice-board';

interface DynamicContentProps {
  children: React.ReactNode;
  canReturn?: boolean;
  title?: string;
  contentColour?: ColourVariables;
  paddedPage?: boolean;
  showDocumentActionsButton?: boolean;
  limitHeight?: boolean;
}

const SideMenuV2Layout: React.FC<DynamicContentProps> = ({
  canReturn = false,
  children,
  contentColour = 'var(--ion-color-light)',
  limitHeight = false,
  paddedPage = true,
  showDocumentActionsButton = false,
  title,
}) => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const [activeTab, setActiveTab] = useState<string | undefined>('home');
  const [submenuVisible, setSubmenuVisible] = useState(false);

  const limitedHeightStyles = useMemo(() => {
    if (!limitHeight) return {};
    return {
      maxHeight: '100%',
      height: '100%'
    };
  }, [limitHeight]);

  const handleActiveTabChange = (tab: string) => {
    storage.set('activeTab', tab).then(() => {
      setActiveTab(tab);
    });
  };

  useEffect(() => {
    storage.get('activeTab').then((tab: string) => {
      if (tab) {
        setActiveTab(tab);
      }
    });
  }, []);

  if (!isDesktop) {
    return (
      <AppLayout
        showDocumentActionsButton={showDocumentActionsButton}
        paddedPage={paddedPage}
        headerTitle={title}
        contentColour={contentColour}
        limitHeight={limitHeight}
      >
        {children}
      </AppLayout>
    );
  }

  return (
    <AppLayout
      scroll
      showDocumentActionsButton={showDocumentActionsButton}
      limitHeight={limitHeight}
    >
      <div className={styles['side-menu--base--holder']}>
        <IonSplitPane contentId="main" className={styles['side-menu--base']}>
          <SideMenuV2Options
            activeTab={activeTab}
            setActiveTab={handleActiveTabChange}
          />
          <SubmenuRoot
            submenuVisible={submenuVisible}
            setSubmenuVisible={setSubmenuVisible}
            hidden={!activeTab || !['projects', 'tools'].includes(activeTab)}
          >

            {activeTab === 'tools' &&
              <SideMenuV2Submenus.Tools
                submenuVisible={submenuVisible}
              />
            }
            {
              activeTab === 'projects' &&
              <SideMenuV2Submenus.Projects
                submenuVisible={submenuVisible}
              />
            }
          </SubmenuRoot>
          <div id="main">
            <IonContent style={{
              '--background': contentColour || 'var(--ion-color-light)',
            }}>
              <div style={limitedHeightStyles}>

                {canReturn && (<IonList className={'ion-margin-bottom ion-padding-bottom'} style={{ height: '100%' }}>
                  <ReturnChevronText text={t('Back')} color={'none'} fill={'none'} />
                </IonList>)}

                {children}
              </div>
            </IonContent>
          </div >
        </IonSplitPane>
      </div>
    </AppLayout>
  );
};

export default SideMenuV2Layout;
