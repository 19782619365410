import React from 'react';

import styles from './styles.module.scss';

interface LabeledSeparatorProps {
  label: string
}

const LabeledSeparator: React.FC<LabeledSeparatorProps> = (props) => {
  return (
    <div className={styles['button-seperator']}>
      <div className={styles['seperator-line']} />
      <p className='ion-no-margin ion-margin-start ion-margin-end'>{props.label}</p>
      <div className={styles['seperator-line']} />
    </div>
  );
};

export default LabeledSeparator;
