import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { networking } from '../api/networking';

type File = E2U.V1.Models.File;

interface UseFilePreviewsResult {
  fileUrls: Record<string, string>;
  loadingPreview: boolean;
  fetchFile: (file: File) => void;
}

const useFilePreviews = (
  files?: File[],
  errorCallback?: (error: E2U.V1.Response.Error) => void
): UseFilePreviewsResult => {
  const [fileUrls, setFileUrls] = useState<Record<string, string>>({});
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);

  const handleError = (error: E2U.V1.Response.Error) => {
    if (errorCallback) {
      errorCallback(error);
    }
    Sentry.captureException(error);
  };

  const fetchFile = (file: File) => {
    if (!file.id) {
      return;
    }
    setLoadingPreview(true);
    networking
      .get(`/api/v1/files/${file.id}/export?base64=true`)
      .then((response: AxiosResponse<string>) =>
        setFileUrls((prev) => ({
          ...prev,
          [file.id as string]: `data:${response.headers['Content-Type']};base64,${response.data}`,
        })),
      )
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingPreview(false);
      });
  };

  useEffect(() => {
    if (files) {
      files.forEach((file) => fetchFile(file));
    }
  }, [files]);

  return { fileUrls, loadingPreview, fetchFile };
};

export default useFilePreviews;
