import * as Sentry from '@sentry/capacitor';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';

type CountTypes = {
  current: string | number | undefined,
  total: string | number | undefined
};

const countDefault: CountTypes = {
  current: undefined,
  total: undefined
};
interface SignedCountProps {
  rows: any[]
}
const SignedCount: React.FC<SignedCountProps> = (props) => {
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation();
  const project = useAppSelector(state => state.project.selectedProject);
  const user = useAppSelector(state => state.authentication.user);

  const currentFinishedCount = props.rows?.filter((row) => !!row.finished_at).length;

  const getCount = () => {
    if (!project?.id && !user?.id) return;
    const endpoint = project?.id
      ? `/api/v1/projects/${project?.id}/work_preparation_protocols/count`
      : `/api/v1/users/${user?.id}/work_preparation_protocols/count`;

    networking.get(endpoint)
      .then((response) => {
        setTotal(response.data.data ?? 0);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    getCount();
  }, [project?.id, user?.id]);

  return (
    <BigUp.Widget.Count
      avatar={project?.name.charAt(0).toUpperCase() ?? '-'}
      label={t('Signed')}
      count={{
        current: currentFinishedCount ?? '0',
        total: total ?? '0'
      }}
    />
  );
};

export default SignedCount;
