import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonBackdrop, IonButtons, IonContent, IonFooter, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { attachSharp, close } from 'ionicons/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import BigUp from '..';
import imagePlaceholder from '../../../assets/img-placeholder.webp';
import type { CameraUploadResult } from '../../../hooks/useCameraUpload';
import type { FileUploadResult } from '../../../hooks/useFileUpload';
import i18n from '../../../i18n';
import storage from '../../../storage';
import type { ActionSheetItem } from '../ActionSheets';
import styles from './MediaUploader.module.scss';
interface MediaUpload {
  id: string;
  dismiss: () => void;
  index?: number;
  isOpen: boolean;
  title: string;
  cameraProps: CameraUploadResult;
  fileProps: FileUploadResult;
}

const MediaUploader: React.FC<MediaUpload> = ({ cameraProps, dismiss, fileProps, id, isOpen, title }) => {
  const [photoContent, setPhotoContent] = useState<{ [fileHash: string]: string }>({});
  const methods = useFormContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const rows = methods.watch('rows');
  const index = useMemo(() => rows.findIndex((row: any) => row.id === id), [rows, id]);
  const files = useMemo(() => rows[index]?.files || [], [rows, index, id]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      fileProps.handleFileSelection(Array.from(files));
      const newRows = [...rows];
      if (index === -1) {
        rows.push({
          id,
          active: true,
          description: '',
          files: Array.from(files)
        });
      } else {
        newRows[index].files = [
          ...newRows[index].files,
          ...Array.from(files)
        ];
      }
      methods.setValue('rows', newRows);
    }
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const takePhoto = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        quality: 100,
      });
      cameraProps.handleTakenPhoto([photo]);
      const newRows = [...rows];
      if (index === -1) {
        rows.push({
          id,
          active: true,
          description: '',
          files: [photo]
        });
      } else {
        newRows[index].files = [
          ...newRows[index].files,
          photo
        ];
      }
      methods.setValue('rows', newRows);
    } catch (error) {
      return (
        console.log(error)
      );
    }
  };

  const actionButtonList: ActionSheetItem[] = [
    {
      value: 'photo',
      label: i18n.t('Take a photo'),
      onClick: takePhoto,
    },
    {
      value: 'file',
      label: i18n.t('Select a file'),
      onClick: openFileSelector,
    },
    // TODO: Implement logic for selecting existing documents
    /*    {
          value: 'document',
          label: i18n.t('Attach document'),
          onClick: () => { },
        }, */
    {
      value: 'cancel',
      label: i18n.t('Cancel'),
      onClick: () => undefined,
    },
  ];

  const updatePhotoPreviews = () => {
    files.forEach((photo: E2U.V1.Models.File) => {
      storage.get(`pending-${photo.file_hash}`)
        .then((data) => {
          if (data) {
            setPhotoContent((prev) => ({
              ...prev,
              [`pending-${photo.file_hash}`]: data
            }));
          }
        });
    });
  };

  useEffect(() => {
    updatePhotoPreviews();
  }, [files]);

  return (
    <>

      <IonModal
        className={styles['media-upload-modal']}
        style={{
          '--width': '400px',
          '--height': '250px',
          padding: '18px'
        }}
        isOpen={isOpen} onIonModalDidDismiss={dismiss}>
        <IonHeader>
          <IonToolbar>
            <IonTitle className="ion-text-center">{title}</IonTitle>
            <IonButtons slot="end">
              <BigUp.Buttons.Icon
                onClick={dismiss}
                fill='clear'
                className='ion-no-margin'
                padding={20}
                icon={{
                  icon: close,
                  color: 'primary',
                  size: 'large',
                }}
                color={'none'}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollY={false} className='ion-padding'>
          <BigUp.Textarea
            withValidation={false}
            itemProps={{
              lines: 'none',
              className: 'ion-no-padding'
            }}
            register={`rows.${index}.description`}
            placeholder={i18n.t('Description')}
            rows={6}
          />
        </IonContent>
        <IonFooter>
          <IonToolbar style={{ paddingLeft: 8, paddingBottom: 8 }}>
            <IonButtons slot='start'>
              <BigUp.Buttons.Icon
                fill='solid'
                id={'open-risk-actions'}
                className={`ion-no-margin ${styles['media-upload-actions']}`}
                padding={20}
                icon={{
                  icon: attachSharp,
                  color: 'primary',
                  size: 'large',
                }}
                color={'none'}
              />
            </IonButtons>
            <div className={styles['media-upload-preview']}>
              {files.map((file: any) => {
                return (
                  <img
                    className={styles['media-upload-preview-image']}
                    key={file.id}
                    src={
                      (file.type === 'image')
                        ? (
                          (file.export_url)
                            ? `${file.export_url}?base64=true`
                            : (file.file_hash && photoContent[`pending-${file.file_hash}`])
                              ? photoContent[`pending-${file.file_hash}`]
                              : imagePlaceholder
                        )
                        : imagePlaceholder
                    }
                  />
                );
              })}
            </div>
            <IonButtons slot="end" style={{ padding: '0 4px 0 18px' }}>
              <BigUp.Buttons.Regular
                size='small'
                onClick={() => {
                  methods.setValue('description', methods.getValues('description'));
                  dismiss();
                }}
                title={i18n.t('Save')}
              />
            </IonButtons>
          </IonToolbar>
        </IonFooter>
        <input
          ref={fileInputRef}
          type="file"
          hidden
          onChange={handleFileChange}
          multiple
        />
        <BigUp.ActionSheets.ActionSheet items={actionButtonList} actionId="open-risk-actions" />
      </IonModal>
    </>
  );
};

export default MediaUploader;
