import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useMemo } from 'react';

import Contact from '../../../../pages/EmergencyContacts/ContactTile';
import Avatar from '../../../Avatar';
import type { MultiSelectorDefaultTriggerProps, MultiSelectorItemProps } from '../interfaces';
import styles from '../MultiSelector.module.scss';
import MultiSelectorTrigger from '../MultiSelectorTrigger';

interface MultiSelectorChipsProps {
  selectedItems: any[];
  items?: MultiSelectorItemProps[];
  selectedGroups?: MultiSelectorItemProps[];
  triggerProps?: Partial<MultiSelectorDefaultTriggerProps>;
  toggleModal?: () => void;
  onDeselectItems?: (items: string[]) => void;
}

const MultiSelectorChips: React.FC<React.PropsWithChildren<MultiSelectorChipsProps>> = ({
  items,
  onDeselectItems,
  selectedGroups,
  selectedItems,
  toggleModal,
  triggerProps
}) => {
  const allSelectedItems = useMemo(() => {
    const selectedItemsInGroups = selectedGroups?.reduce((acc, group) => {
      return acc.concat(group.children ?? []);
    }, [] as MultiSelectorItemProps[]) ?? [];
    const itemsWithChildren = [
      ...(items ?? []).filter(i => !i.children || !i.children.length),
      ...(items ?? []).flatMap((i) => i.children ?? [])
    ];
    const matchedValues = selectedItemsInGroups.map((item) => item.value);
    return selectedItemsInGroups.concat(
      itemsWithChildren.filter((item) => !matchedValues.includes(item.value) && selectedItems.includes(item.value))
    )
      .sort((a, b) => (a.shortLabel ?? a.label).localeCompare(b.shortLabel ?? b.label));
  }, [selectedGroups, items, selectedItems]);

  const formattedGroupRange = useMemo(() => {
    if (!selectedGroups) {
      return [];
    }
    const labels = selectedGroups.map((group) => {
      const children = group.children?.sort(
        (a, b) => (a.shortLabel ?? a.label).localeCompare(b.shortLabel ?? b.label)
      ) ?? [];
      if (!children.length) {
        return {
          ids: [],
          label: ''
        };
      }
      if (children.length > 1) {
        return {
          ids: [group.value, ...children.map((child) => child.value)],
          label: `${children[0].shortLabel ?? children[0].label}-${children[children.length - 1].shortLabel ?? children[children.length - 1].label}`
        };
      }
      return {
        ids: [group.value, children[0].value],
        label: children[0].shortLabel ?? children[0].label
      };
    });
    const letAllChildrenIds = selectedGroups.reduce((acc, group) => {
      return acc
        .concat(
          group.children?.map(
            (child) => child.value
          ) ?? []
        )
        .concat([group.value]);
    }, [] as string[]);
    selectedItems.filter((i) => !letAllChildrenIds.includes(i)).forEach((i) => {
      const item = items?.flatMap((item) => item.children ?? [])
        .find((child) => child.value === i);
      if (item) {
        labels.push({
          ids: [item.value],
          label: item.shortLabel ?? item.label
        });
      }
    });
    return labels.sort((a, b) => a.label.localeCompare(b.label));
  }, [items, selectedGroups, selectedItems]);

  const handleDeselect = (items: string[]) => {
    if (onDeselectItems) {
      onDeselectItems(items);
    } else {
      toggleModal && toggleModal();
    }
  };

  return (
    <>
      {
        (
          triggerProps?.chips?.type === 'range' &&
          formattedGroupRange.length > 0
        )
          ? (
            <MultiSelectorTrigger
              {...triggerProps}
              onClick={toggleModal ?? (() => {
              })}
              selectedItems={selectedItems}
            >
              {formattedGroupRange.map((group) => group.label).join(', ')}
            </MultiSelectorTrigger>
          )
          : (
            (
              (triggerProps?.chips?.type === 'individual' || triggerProps?.chips?.type === 'avatars') &&
              allSelectedItems &&
              allSelectedItems.length
            )
              ? allSelectedItems.map((item, i) => (
                <MultiSelectorTrigger
                  {...triggerProps}
                  icon={undefined}
                  onClick={() => handleDeselect([item.value])}
                  selectedItems={selectedItems}
                  key={i}
                  labelClassName={'ion-align-items-center ion-justify-content-center'}
                >
                  {triggerProps?.chips?.type === 'avatars' && <Contact
                    attributes={{ user_id: item?.value ?? '' }}
                    hideName={true}
                    size={'small'}
                  />}
                  {item.shortLabel ?? item.label}
                  <IonIcon
                    icon={close}
                    className={styles.trigger__default_icon_toggle_close}
                    onClick={() => {
                      toggleModal && toggleModal();
                    }}
                  />
                </MultiSelectorTrigger>
              ))
              : (
                (
                  triggerProps?.chips?.type === 'group' &&
                  formattedGroupRange.length > 0
                ) && formattedGroupRange.map((group, i) => (
                  <MultiSelectorTrigger
                    {...triggerProps}
                    onClick={() => handleDeselect(group.ids)}
                    selectedItems={selectedItems}
                    key={i}
                    icon={undefined}
                    labelClassName={'ion-align-items-center'}
                  >
                    {group.label}
                    <IonIcon
                      icon={close}
                      className={styles.trigger__default_icon_toggle_close}
                    />
                  </MultiSelectorTrigger>
                ))
              )
          )
      }
    </>
  );
};

export default MultiSelectorChips;
