import { IonCol, IonContent, IonGrid, IonModal, IonRow } from '@ionic/react';
import { createSelector } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useState } from 'react';

import BigUp from '../../..';
import type { NavigationProps } from './BigUp/types';
import ContentWrapper from './ContentWrapper';
import { networking } from '../../../../../api/networking';
import { useAppSelector } from '../../../../../hooks';
import useFilePreviews from '../../../../../hooks/useFilePreview';
import useLongPress from '../../../../../hooks/useLongPress';
import type { FileSelectionReducer } from '../../../../../reducers/files';
import { setFiles, setSelectedFiles } from '../../../../../reducers/files';
import type { RootState } from '../../../../../store';
import store from '../../../../../store';
import { formatToNiceDate } from '../../../../../tools/formatDates';
import SkeletonItem from '../../../../SkeletonComponents/SkeletonItem';

const Latest: React.FC<NavigationProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [triggerPreview, setTriggerPreview] = useState<boolean>(false);

  const selectionHandler = createSelector(
    (state: RootState) => state.fileSelection.search,
    (state: RootState) => state.fileSelection.toggleListView,
    (state: RootState) => state.fileSelection.selectedFiles,
    (state: RootState) => state.fileSelection.files,
    (search, toggleListView, selectedFiles, files): FileSelectionReducer => ({
      search, toggleListView, selectedFiles, files
    })
  );
  const fileReducer = useAppSelector(selectionHandler);
  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id);
  const { fileUrls, loadingPreview } = useFilePreviews(fileReducer.files);

  const [onStartLongPress, onEndLongPress] = useLongPress(() => {
    setTriggerPreview(true);
  }, 600);

  const handleStartLongPress = () => {
    onStartLongPress();
  };

  const handleEndLongPress = () => {
    onEndLongPress();
    setTriggerPreview(false);
  };

  const searchParams = new URLSearchParams();
  searchParams.append('direction', 'asc');
  searchParams.append('search', fileReducer.search ?? '');

  const getFilesInProject = () => {
    if (!selectedProjectId) return;
    setIsLoading(true);
    networking.get(`/api/v1/projects/${selectedProjectId}/documents/files?${searchParams}`)
      .then((response) => {
        store.dispatch(setFiles(response.data.data.records));
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkIfSelected = (file: E2U.V1.Models.File) => {
    return fileReducer.selectedFiles?.some(selectedFile => selectedFile.id === file.id);
  };

  const handleItemPress = (file: E2U.V1.Models.File) => {
    const isSelected = fileReducer.selectedFiles?.some(selectedFile => selectedFile.id === file.id);
    const updatedFiles = isSelected
      ? fileReducer.selectedFiles?.filter(selectedFile => selectedFile.id !== file.id)
      : [...(fileReducer.selectedFiles || []), file];
    store.dispatch(setSelectedFiles(updatedFiles));
  };

  useEffect(() => {
    getFilesInProject();
  }, [selectedProjectId, fileReducer.search]);

  return (
    <>
      <ContentWrapper>
        {isLoading && (
          <SkeletonItem
            amount={7}
            sizes={{
              sizeLg: '12',
              sizeMd: '12',
              sizeSm: '12',
              sizeXs: '12'
            }}
          />
        )}
        <IonGrid className='ion-no-padding'>
          <IonRow>
            {fileReducer.files?.map((file) => {
              return (
                fileReducer.toggleListView
                  ? (
                    <IonCol key={file.id} size='4' sizeSm='3'>
                      <BigUp.Preview.CheckboxPreview
                        // touch={{
                        // touchEnd: handleEndLongPress,
                        // touchStart: handleStartLongPress
                        // }}
                        type={file.filetype}
                        fileType={file.type as any}
                        loading={loadingPreview}
                        onClick={() => handleItemPress(file)}
                        handleCheckbox={() => { }}
                        image={{
                          src: fileUrls[file.id ?? ''],
                          alt: file.name,
                        }}
                        title={file.name}
                        subtitle={formatToNiceDate(file.updated_at)}
                        checked={checkIfSelected(file)}
                      />
                    </IonCol>
                  )
                  : (
                    <IonCol key={file.id} size='12' className='ion-margin-bottom'>
                      <BigUp.Preview.PreviewItem
                        type={file.filetype}
                        detail
                        lines='full'
                        // onTouchStart={handleStartLongPress}
                        // onTouchEnd={handleEndLongPress}
                        onClick={() => {
                          handleItemPress(file);
                        }}
                        previewLoading={loadingPreview}
                        fileUrl={fileUrls[file.id ?? '']}
                        fileName={file.name}
                        fileType={file.type}
                        subtitle={formatToNiceDate(file.updated_at)}
                      >
                        <BigUp.Checkbox
                          handleCheckbox={() => { }}
                          slot='end'
                          checked={checkIfSelected(file)}
                        />
                      </BigUp.Preview.PreviewItem>
                    </IonCol>
                  )
              );
            })}
          </IonRow>
        </IonGrid>
      </ContentWrapper>
      {/*
      TODO: Add preview to modal
      */}
      {/* <IonModal
        isOpen={triggerPreview}
        onDidDismiss={() => setTriggerPreview(false)}
      >
        <IonContent>
          <h1>Preview</h1>
        </IonContent>
      </IonModal> */}
    </>
  );
};

export default Latest;
