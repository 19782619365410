
import { IonFooter, isPlatform } from '@ionic/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BigUpTab from './BigUp';
import DeviceFiles from './DeviceFiles';
import Latest from './Latest';
import type { ModalContentProps, TabValues } from './types';
import BigUp from '../../..';
import bigUpFolder from '../../../../../components/icons/bigUpFolder/bigupFolder.svg';
import bigUpFolderActive from '../../../../../components/icons/bigUpFolder/bigupFolderActive.svg';
import clock from '../../../../../components/icons/clock/clock.svg';
import clockActive from '../../../../../components/icons/clock/clockActive.svg';
import folder from '../../../../../components/icons/folder/folder.svg';
import folderActive from '../../../../../components/icons/folder/folderActive.svg';
import { setSelectedFile, setSelectedFiles } from '../../../../../reducers/files';
import store from '../../../../../store';
import type { CreateButtonType } from '../../../Tabs/TabToolbar/Tabs';

const ModalContent: React.FC<ModalContentProps> = (props) => {
  const { cameraProps, fileProps } = props;
  const { t } = useTranslation();
  const checkPlatform = !!isPlatform('ios');

  const handleClick = async (key: TabValues) => {
    props.setCurrentTab(key);
    fileProps?.setUploadedFiles([]);
    store.dispatch(setSelectedFiles([]));
    store.dispatch(setSelectedFile(undefined));
  };

  const createTabButton: CreateButtonType<TabValues> = useCallback(
    (key, label, icon) => ({
      label,
      icon: { icon },
      isActive: props.currentTab === key,
      activeColor: {
        background: 'transparent',
        textColour: 'var(--ion-color-secondary)'
      },
      onClick: () => handleClick(key),
    }),
    [props.currentTab, cameraProps, fileProps]
  );

  const tabButtons = useMemo(() => [
    createTabButton(
      'latest',
      t('Latest'),
      props.currentTab === 'latest' ? clockActive : clock,
    ),
    createTabButton(
      'bigup',
      t('BigUps unit'),
      props.currentTab === 'bigup' ? bigUpFolderActive : bigUpFolder,
    ),
    createTabButton(
      'my-device',
      t('My device'),
      props.currentTab === 'my-device' ? folderActive : folder,
    ),
  ], [props.currentTab, createTabButton]);

  const renderTabContent = (tabKey: TabValues) => {
    switch (tabKey) {
      case 'latest':
        return <Latest key="latest" {...props} />;
      case 'bigup':
        return <BigUpTab key="bigup" {...props} />;
      case 'my-device':
        return <DeviceFiles key="my-device" {...props} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    fileProps?.resetFilesToUpload();
    if (props.currentTab === 'my-device') {
      props.setShowFilters && props.setShowFilters(false);
    }
  }, [props.currentTab]);

  return (
    <>
      {renderTabContent(props.currentTab)}
      <IonFooter
        translucent
        style={{
          backgroundColor:
            checkPlatform
              ? 'rgba(255, 255, 255, 0.7)'
              : 'var(--color-white)',
          borderTop: '1px solid var(--ion-color-light)',
        }}
        className='ion-no-border'>
        <BigUp.Tabs.TabToolbar buttons={tabButtons} />
      </IonFooter>
    </>
  );
};

export default ModalContent;
