import Assignees from './Assignees';
import Description from './Description';
import Project from './Project';
import Sections from './Sections';

const Section = {
  Project,
  Description,
  Sections,
  Assignees,
};

export default Section;
