import type { AlertOptions } from '@ionic/react';
import { IonAlert, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import { projectInputs } from './inputs';
import { networking } from '../../../../api/networking';
import RelatedMultiSelectEdit from '../../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import BigUp from '../../../../components/UI';
import type { InputProps } from '../../../../components/UI/Inputs/Input.interface';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import useFetchProjects from '../../../../hooks/useFetchProjects';
import usePermissionHandler from '../../../../hooks/usePermissions';
import { setSelectedProject } from '../../../../reducers/project';
import store from '../../../../store';

const Project: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(undefined);
  const [availableUsers, setAvailableUsers] = useState<E2U.V1.Models.User[]>([]);
  const fetchProjects = useFetchProjects();
  const methods = useFormContext();
  const location = useLocation();
  const { uuid } = useParams<{ uuid: string }>();
  const project = useAppSelector((state) => state.project.selectedProject);
  const { checkPermissionsForProjectAndTools } = usePermissionHandler();
  const { t } = useTranslation();
  const activityCodes = methods.watch('activity_codes') as string[];
  const contactPerson = methods.watch('contact_person_id') as string;
  const [presentAlert] = useIonAlert();
  const contactPersonName = useMemo(
    () => availableUsers.find(user => user.id === contactPerson)?.name,
    [availableUsers, contactPerson]
  );

  const fetchAndSetProjectById = (projectId: string) => {
    networking.get(`/api/v1/projects/${projectId}`)
      .then((response) => {
        const projectData = response.data.data;
        store.dispatch(setSelectedProject(projectData));
        methods.setValue('project', projectData.name);
        setSelectedProjectId(projectData.id);
        if (location.pathname !== `/tools/work-preparations/${projectId}`) {
          history.pushState({}, '', `/tools/work-preparations/${projectId}`);
        }
      })
      .catch(() => {
        toasters.createToast({
          message: t('Error while looking for project'),
          background: 'var(--ion-color-light)',
        }, 'error');
      });
  };

  const handleSelectedProject = (projectId: string) => {
    fetchAndSetProjectById(projectId);
    setIsOpen(false);
  };

  const showErrorOutline = (item: InputProps<any>): boolean => {
    const isTouched = methods.formState.touchedFields[item.register] ?? false;
    const required = item.validation.required;
    const isSet = !methods.watch(item.register);

    return isTouched && required && isSet;
  };

  useEffect(() => {
    if (uuid && uuid !== project?.id) {
      fetchAndSetProjectById(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (project?.id) {
      methods.setValue('project', project?.name);
      methods.setValue('project_id', project?.id);
      setSelectedProjectId(project?.id);
    }
  }, [project]);

  useEffect(() => {
    fetchProjectUsers();
  }, [selectedProjectId]);

  const handleAlert = () => {
    fetchProjects(true);
    setIsOpen(true);
  };

  const fetchProjectUsers = () => {
    if (!selectedProjectId) return;
    networking.get(`/api/v1/projects/${selectedProjectId}/users?limit=9999`)
      .then((response) => {
        setAvailableUsers(response.data.data.records);
      });
  };

  const handleContactPersonAlert = () => {
    presentAlert({
      header: t('Select contact person'),
      message: t('Select the contact person for this work preparation'),
      inputs: [
        {
          type: 'radio',
          label: t('No contact person'),
          value: undefined,
          checked: !contactPerson,
        },
        ...(availableUsers ?? []).map(user => ({
          type: 'radio',
          label: user.name,
          value: user.id,
          checked: user.id === contactPerson,
        }))
      ] as AlertOptions[],
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
        },
        {
          text: t('Select'),
          handler: (data) => {
            methods.setValue('contact_person_id', data);
          },
        },
      ],
    });
  };

  const inputs = checkPermissionsForProjectAndTools('read', 'read')?.map(project => ({
    label: project.name || '',
    type: 'radio',
    value: project.id || '',
    checked: project.id === selectedProjectId,
  })) || [];

  const items = projectInputs.map((inputProp, index) => ({
    id: (index + 1).toString(),
    errorOutline: (showErrorOutline(inputProp) ?? false),
    content: (
      <>
        <BigUp.Input
          {...inputProp}
          lines={false}
          style={{
            textAlign: inputProp.alignment,
          }}
        />
        {inputProp.register === 'project' && (
          <BigUp.Buttons.Secondary
            size='small'
            style={{ minWidth: 'fit-content' }}
            title={t('Select project')}
            onClick={() => handleAlert()}
          />
        )}
      </>
    ),
  }));

  return (
    <FormProvider {...methods}>
      <BigUp.ItemList
        header={{ title: t('Project') }}
        items={items}
        extraRow={[
          {
            hasExtraRow: true,
            item: { detail: false, button: false },
            content:
              <>
                <BigUp.Label.Regular className='ion-no-margin' color={'dark'} label={t('Activity Codes')} />
                <RelatedMultiSelectEdit
                  button='v2'
                  buttonSize='small'
                  model='activity_codes'
                  label={(activityCodes.length > 1)
                    ? t('{amount} selected', { amount: activityCodes.length })
                    : (activityCodes.length === 1
                      ? `${activityCodes[0]?.code} ${activityCodes[0]?.name}`
                      : t('Add codes'))}
                  action='submit'
                  icon={{ icon: add }}
                  hideSelected
                  value={activityCodes}
                  modalTitle={t('Select activity codes')}
                  onChange={(data) => methods.setValue('activity_codes', data)}
                  displayFields={['code', 'name']}
                />
              </>
          },
          {
            hasExtraRow: true,
            item: { detail: false, button: false },
            content:
              <>
                <BigUp.Label.Regular
                  className='ion-no-margin'
                  color={'dark'}
                  label={contactPersonName
                    ? t('Contact person: {contactPersonName}', {
                      contactPersonName
                    })
                    : t('Contact person')}
                />
                <BigUp.Buttons.Secondary
                  size='small'
                  style={{ minWidth: 'fit-content' }}
                  title={contactPersonName ? t('Change contact') : t('Select contact')}
                  onClick={() => handleContactPersonAlert()}
                />
              </>
          }
        ]}
      />
      <IonAlert
        isOpen={isOpen}
        inputs={inputs as AlertOptions[]}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
          },
          {
            text: t('Select'),
            role: 'confirm',
            handler: (projectId) => handleSelectedProject(projectId || uuid),
          },
        ]}
        onDidDismiss={() => setIsOpen(false)}
      />
    </FormProvider>
  );
};

export default Project;
