import type { E2U } from '@techlove/easy2use-typings';
import type { SetStateAction } from 'react';
import React, { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styles from './Form.module.scss';
import { networking } from '../../../api/networking';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import BigUp from '../../UI';
import toasters from '../../UI/Toasts';

interface SectionFormProps {
  onSectionCreated: (section: any) => void;
  type: 'projects' | 'clients';
  id: string;
  section?: any;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

interface SectionFormData {
  id: string | null;
  name: string;
  required_note: boolean;
}

const SectionForm: React.FC<SectionFormProps> = ({ modalOpen, onSectionCreated, section, setModalOpen, type }) => {
  const { t } = useTranslation();
  const { node_id } = useParams<{ node_id: string }>();
  const methods = useForm<SectionFormData>({
    defaultValues: {
      name: '',
      required_note: false
    }
  });
  const requiredNote = methods.watch('required_note');

  const createSection: SubmitHandler<SectionFormData> = (data) => {
    store.dispatch(setIsLoading({ name: 'creatingSection', value: true }));

    const nodeType = type === 'projects' ? 'WorkPreparationProjectNode' : 'WorkPreparationClientNode';

    const networkType = section ? networking.put : networking.post;

    const idSuffix = data.id ? `/${data.id}` : '';

    networkType(`/api/v1/work_preparations/sections${idSuffix}`, {
      id: data.id,
      name: data.name,
      required_note: data.required_note,
      node_id,
      node_type: `App\\Models\\WorkPreparations\\${nodeType}`
    })
      .then((res: E2U.V1.Response.Success<any>) => {
        onSectionCreated(res.data.data);
        setModalOpen(false);
      })
      .catch(() => {
        toasters.createToast({
          message: t('Something went wrong when trying to create section. Please try again later or contact support.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'creatingSection', value: false }));
      });
  };

  const handleExistingSection = () => {
    if (!section) {
      return;
    }
    methods.setValue('id', section.id);
    methods.setValue('name', section.name);
    methods.setValue('required_note', section.required_note);
  };

  useEffect(() => {
    handleExistingSection();
  }, [section]);

  useEffect(() => {
    handleExistingSection();
  }, []);

  return (
    <FormProvider {...methods}>
      <BigUp.Modal.InputModal
        maxHeight='280px'
        modal={{
          isOpen: modalOpen,
          onDismiss: () => setModalOpen(false),
          title: section ? t('Rename section') : t('Create new section'),
        }}
        button={{
          type: 'submit',
          title: t('Save'),
          disabled: methods.formState.isSubmitting || !methods.formState.isValid,
          loading: false
        }}
        textAlignment='center'
        input={{
          name: 'name',
          type: 'text',
          placeholder: t('Enter name'),
          register: 'name',
          validation: {
            required: true,
            minLength: 1
          }
        }}
        onSubmit={methods.handleSubmit(createSection)}
      >
        <div className={styles.sectionForm}>
          <BigUp.Checkbox
            labelPlacement='start'
            checked={requiredNote}
            style={{
              '--checkbox-background-checked': 'var(--bigup-gradient-secondary)',
              '--border-color-checked': 'var(--color-white)'
            }}
            labelProps={{
              label: t('Required note'),

            }}
            handleCheckbox={() => methods.setValue('required_note', !requiredNote)}
          />
        </div>
      </BigUp.Modal.InputModal>
    </FormProvider >
  );
};

export default SectionForm;
