import CallMeUp from './CallMeUp';
import Header from './Header';
import WorksiteCodeAccess from './WorksiteCodeAccess';

const Sections = {
  CallMeUp,
  Header,
  WorksiteCodeAccess,
};

export default Sections;
