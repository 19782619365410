
import { IonButton, IonCol, IonGrid, IonIcon, IonModal, IonRow, IonSearchbar, IonToolbar, isPlatform } from '@ionic/react';
import { createSelector } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { chevronDown, filterOutline, funnel, funnelOutline } from 'ionicons/icons';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import BigUp from '../..';
import { networking } from '../../../../api/networking';
import { useAppSelector } from '../../../../hooks';
import useCameraUpload from '../../../../hooks/useCameraUpload';
import { useClearFileUploadReducers, useCreateAndAttachFilesOnCategory } from '../../../../hooks/useCreateAndAttachFilesOnCategory';
import useFileUpload from '../../../../hooks/useFileUpload';
import i18n from '../../../../i18n';
import type { FileSelectionReducer } from '../../../../reducers/files';
import { setForceReloadDocument, setSearch, setToggleListView } from '../../../../reducers/files';
import type { RootState } from '../../../../store';
import store from '../../../../store';
import { importData } from '../../../../tools/importData';
import toasters from '../../Toasts';
import ModalContent from './Contents/ModalContent';
import type { TabValues } from './Contents/types';
import styles from './index.module.scss';
import { DocumentActivityCodeFilter, DocumentCategoryFilter, DocumentExtensionCodeFilter } from '../../../../pages/Document/Filters';
import { MultiSelectorGroup } from '../../MultiSelector';
import type { MultiSelectorGroupValuesProps } from '../../MultiSelector/interfaces';

interface FileModal {
  endpoint?: string;
  onUpload?: () => void;
  modal: {
    dismiss: () => void;
    isOpen: boolean;
  }
}

const generateHeaderTitle = new Map<TabValues, string>([
  ['my-device', i18n.t('My device')],
  ['bigup', i18n.t('BigUp')],
  ['latest', i18n.t('Latest')],
]);

const FileUpload: React.FC<FileModal> = (props) => {
  const { t } = useTranslation();
  const { documentUuid } = useParams<{ documentUuid: string | undefined }>();
  const isDesktop = isPlatform('desktop') ? 'desktop' : 'mobile';
  const [activeFilters, setActiveFilters] = useState<MultiSelectorGroupValuesProps>({});
  const [showFilters, setShowFilters] = useState(false);
  const [focused, setFocused] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabValues>('my-device');

  const selectFileReducers = createSelector(
    (state: RootState) => state.fileSelection.files,
    (state: RootState) => state.fileSelection.selectedFiles,
    (state: RootState) => state.fileSelection.toggleListView,
    (state: RootState) => state.fileSelection.search,
    (files, selectedFiles, toggleListView, search): FileSelectionReducer => ({
      files,
      selectedFiles,
      toggleListView,
      search,
    })
  );

  const fileReducers = useAppSelector(selectFileReducers);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedDocumentCategory = useAppSelector((state) => state.document.selectedCategory);
  const categoryTags = useMemo(() => selectedDocumentCategory?.category_tags ?? [], [selectedDocumentCategory]);

  const cameraProps = useCameraUpload();
  const fileProps = useFileUpload();
  const clearReducers = useClearFileUploadReducers();

  const { createDocumentAndAttachAllFilesSelected, } = useCreateAndAttachFilesOnCategory({
    callbackOnSuccess: () => dismiss(),
  });

  const didPresent = () => {
    if (
      fileProps.filesToUpload.length === 0 &&
      isPlatform('mobile') &&
      currentTab === 'my-device'
    ) {
      importData(fileProps.handleFileSelection);
    }
  };

  const dismiss = () => {
    props.modal.dismiss();
    fileProps.resetFilesToUpload();

    setCurrentTab('my-device');
    clearReducers();
  };

  const handleToasters = (successful: boolean) => {
    if (successful) {
      fileProps.setUploadedFiles([]);
    }
  };

  const handleFileUpload = async () => {
    if (!documentUuid) {
      return uploadNewDocument();
    }
    if (documentUuid) return attachFilesToExistingDocument();
    if (categoryTags && categoryTags.length) return attachCategoryTagsToDocument(documentUuid);
  };

  const attachFilesToExistingDocument = () => {
    const uploadPromises = fileProps.onlyUploadFiles().map((filePromise) =>
      filePromise
        .then((fileResponse) => {
          const fileId = fileResponse.data.data.id;
          return networking.put(`/api/v1/documents/${documentUuid}`)
            .then(() => networking.post(`/api/v1/documents/${documentUuid}/files/${fileId}`)
              .then(() => {
                store.dispatch(setForceReloadDocument(true));
              })
            );
        })
    );
    return executeFileUploads(uploadPromises);
  };

  const uploadNewDocument = () => {
    const uploadPromises = fileProps.onlyUploadFiles().map((filePromise) =>
      filePromise
        .then((fileResponse) => {
          const fileId = fileResponse.data.data.id;
          return networking
            .post(`/api/v1/projects/${selectedProject?.id}/documents`, {
              name: fileResponse.data.data.name,
              description: '',
            })
            .then((response) => {
              const documentId = response.data.data.id;
              return networking
                .post(`/api/v1/documents/${documentId}/files/${fileId}`)
                .then(() => attachCategoryTagsToDocument(documentId)).then(() => { store.dispatch(setForceReloadDocument(true)); });
            });
        })
    );

    return executeFileUploads(uploadPromises);
  };

  const attachCategoryTagsToDocument = (documentId: string) => {
    if (categoryTags && categoryTags.length) {
      categoryTags.forEach((tag: E2U.V1.Models.Tag) => {
        return (
          networking.post(`/api/v1/documents/${documentId}/related_tags/${tag.id}`).then(() => {
            store.dispatch(setForceReloadDocument(true));
          })
        );
      });
    }
  };

  const executeFileUploads = (uploadPromises: Promise<any>[]) => {
    const allUploads = Promise.allSettled(uploadPromises);

    toasters
      .promiseToast(allUploads, {
        pending: { message: t('Uploading files') },
        success: { message: t('All files uploaded successfully') },
        error: { message: t('Some files could not be uploaded') },
      })
      .then((results) => {
        const successful = results.every((result: any) => result.status === 'fulfilled');
        handleToasters(successful);
      })
      .catch((error) => {
        handleToasters(false);
        Sentry.captureException(error);
      });
  };

  const handleSubmit = () => {
    // TODO Add ability to just store the files and not upload them to attach to a new document
    if (currentTab === 'my-device') {
      handleFileUpload();
    } else {
      createDocumentAndAttachAllFilesSelected();
    }
    dismiss();
  };

  return (
    <IonModal
      isOpen={props.modal.isOpen}
      onDidDismiss={() => dismiss()}
      onDidPresent={didPresent}
      backdropDismiss={true}
      className={styles[`file-upload-modal--${isDesktop}`]}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      mode='md'
    >
      <div>
        <BigUp.Modal.ModalHeader
          title={generateHeaderTitle.get(currentTab) || '' as any}
          end={{
            button: {
              title: t('Save'), color: 'secondary',
              // TODO Handle disabled state
              // disabled: !fileProps.filesToUpload.length || !fileProps.filesToUpload.length || !selectedFiles?.length
            },
            onClick: handleSubmit,

          }}
          start={{
            button: { title: t('Cancel') },
            onClick: dismiss
          }}
        />

        <IonToolbar
          className='ion-no-padding'
          style={{
            boxShadow: '0px 32.814px 28.814px -22px #F2F2F2',
            '--background': 'white',
            '--padding-start': '0px',
          }}>
          {(currentTab === 'bigup' || currentTab === 'latest')
            ? <>
              <IonGrid className='ion-no-padding'>
                <IonRow className='ion-justify-content-between ion-align-items-center ion-padding-end'>
                  <IonCol size={focused ? '12' : '11'}>
                    <IonSearchbar
                      onFocus={() => setFocused(true)}
                      onBlur={() => setTimeout(() => setFocused(false), 200)}
                      showClearButton='focus'
                      style={{
                        '--box-shadow': '0px 0px 0px 1px #E0E0E0',
                        '--background': 'var(--color-white)',
                        '--placeholder-color': '#A3A3A3',
                        '--icon-color': '#A3A3A3',
                        borderRadius: '10px',
                        paddingBottom: 0
                      }}
                      mode={'ios'}
                      onIonInput={(e) => store.dispatch(setSearch(e.detail.value || ''))}
                      value={fileReducers.search}
                    />
                  </IonCol>
                  {!focused && (
                    <IonCol size='1' className='ion-text-right'>
                      <IonButton
                        style={{ borderRadius: '10px', border: ' 1px solid #E2E2EA' }}
                        fill='clear'
                        onClick={() => setShowFilters(!showFilters)}
                      >
                        <IonIcon slot='icon-only'
                          color={
                            showFilters
                              ? 'secondary'
                              : 'medium'
                          }
                          icon={filterOutline}
                        />
                      </IonButton>
                    </IonCol>
                  )}

                </IonRow>
              </IonGrid>

            </>
            : null
          }
          {showFilters && (
            <IonGrid className='ion-no-padding ion-padding-top'>
              <IonRow className='ion-margin-start'>
                <IonCol size='12'
                  className='ion-no-padding'
                  style={{
                    overflow: 'scroll',
                    whiteSpace: 'nowrap',
                  }}>
                  <MultiSelectorGroup callbacks={{
                    onSelectionChange: (selection: MultiSelectorGroupValuesProps) => {
                      if (!Object.keys(selection).length && !activeFilters.length) {
                        return;
                      }
                      const filters = Object.entries(selection).reduce((acc, [key, value]) => {
                        return {
                          ...acc,
                          [`filters[${key}]`]: value.join(','),
                        };
                      }, {});

                      setActiveFilters(filters);
                    }
                  }}>
                    <DocumentCategoryFilter sourceUrl={{
                      url: `/api/v1/projects/${selectedProject?.id}/documents/filters`,
                      args: {
                        'only[]': 'categories',
                      },
                    }} />
                    <DocumentActivityCodeFilter sourceUrl={{
                      url: `/api/v1/projects/${selectedProject?.id}/documents/filters`,
                      args: {
                        'only[]': 'activity_codes'
                      },
                    }} />
                    <DocumentExtensionCodeFilter sourceUrl={{
                      url: `/api/v1/projects/${selectedProject?.id}/documents/filters`,
                      args: {
                        'only[]': 'extensions'
                      },
                    }} />
                  </MultiSelectorGroup>
                </IonCol>
              </IonRow>
            </IonGrid>

          )}
          <IonGrid className='ion-no-padding'>
            <IonRow className={'ion-justify-content-between ion-align-items-center ion-padding-end'}>
              <IonCol size='11'>
                <IonButton
                  className='ion-no-margin ion-no-padding'
                  color='medium'
                  fill='clear'
                  mode='ios'
                  size='default'
                  style={{
                    paddingLeft: 16,
                  }}
                >
                  {currentTab === 'latest' && (
                    <>
                      {t('Date')}
                      <IonIcon color='medium' size='small' slot='end' icon={chevronDown} />
                    </>
                  )}
                  {currentTab === 'bigup' && (
                    <>
                      {t('Name')}
                      <IonIcon color='medium' size='small' slot='end' icon={chevronDown} />
                    </>
                  )}
                </IonButton>
              </IonCol>
              <IonCol size='1' className='ion-text-right'>
                {(fileProps.filesToUpload && fileProps.filesToUpload.length === 0) && currentTab === 'my-device'
                  ? <></>
                  : (
                    <BigUp.Buttons.ListToggle
                      handleClick={() => {
                        store.dispatch(setToggleListView(!fileReducers.toggleListView));
                      }}
                    />
                  )
                }
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </div>
      <ModalContent
        setShowFilters={setShowFilters}
        submitHandler={{
          createDocumentAndAttachAllFilesSelected,
          clearReducers
        }}
        setSearch={setSearch}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        cameraProps={cameraProps}
        fileProps={fileProps}
      />
    </IonModal>
  );
};

export default FileUpload;
