import { IonContent, IonPage } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';

import appStyles from '../App.module.scss';
import DesktopWrapper from '../components/DesktopWrapper';
import DocumentActionsButton from '../components/DocumentActionsButton';
import PageMenuHeader from '../components/PageMenuHeader/PageMenuHeader';
import BigUp from '../components/UI';
import type { ColourVariables } from '../components/UI/variables';
import { useAppSelector } from '../hooks';
import TabBar from './TabBar';
import Backdrop from '../components/UI/Backdrop';

type Header = {
  hasHeader: boolean;
  component?: React.FC;
};

interface AppLayoutProps {
  scroll?: boolean;
  headerTitle?: string;
  children: React.ReactNode;
  header?: Header;
  tabs?: boolean;
  contentColour?: ColourVariables;
  canGoBack?: boolean;
  goBackTo?: string;
  content_id?: string;
  menu_id?: string;
  paddedPage?: boolean;
  showDocumentActionsButton?: boolean;
  limitHeight?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  canGoBack = false,
  children,
  content_id,
  contentColour = 'var(--ion-color-light)',
  goBackTo = '/tools',
  header = { hasHeader: true },
  headerTitle,
  limitHeight = false,
  menu_id,
  paddedPage,
  scroll = false,
  showDocumentActionsButton = false,
  tabs,
}) => {
  const history = useHistory();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  return (
    <React.Fragment>
      <IonPage id={'main-content'}>
        {header.hasHeader
          ? (
            <PageMenuHeader headerTitle={!isDesktop ? headerTitle : ''} />
          )
          : (
            <BigUp.Header
              ionTitle={{
                label: headerTitle || '',
              }}
              buttons={{
                start: {
                  show: true,
                  icon: {
                    color: 'primary',
                    icon: chevronBack,
                  },
                  onClick: () => history.go(-1),
                },
                end: {
                  show: false,
                }
              }}
            />
          )
        }
        <IonContent
          style={{ '--background': contentColour, hyphens: 'var(--ion-hyphen)' }}
          id={appStyles['fade-in-page']}
          className={appStyles[scroll ? 'has-scroll' : 'remove-scroll']}
          fullscreen
        >
          <DesktopWrapper
            paddedPage={paddedPage}
            alignment={'center'}
            width={'700px !important'}
            limitHeight={limitHeight}
          >
            {children}
          </DesktopWrapper>
        </IonContent>
        {!isDesktop && <TabBar />}
        {showDocumentActionsButton && <DocumentActionsButton />}
        <Backdrop />
      </IonPage>
    </React.Fragment>
  );
};

export default AppLayout;
