import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  isPlatform,
  useIonActionSheet,
  useIonAlert,
  useIonPopover,
  useIonRouter
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add, checkmark, ellipsisHorizontal } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import ActivityCodeColumn from './Columns/ActivityCodeColumn';
import PrecalculationForm from './Form/PrecalculationForm';
import PrecalculationsRadialChart from './Graphs/PrecalculationRadialChart';
import { useChartData } from './Graphs/useChartData';
import styles from './Precalculations.module.scss';
import PrecalculationTitle from './PrecalculationTitle';
import { columns } from './utils/tableColumns';
import { searchParams } from './utils/urlSearchParams';
import { networking } from '../../../../api/networking';
import history from '../../../../assets/svg/history.svg';
import BigUp from '../../../../components/UI';
import type { ActionSheetItem } from '../../../../components/UI/ActionSheets';
import CalculationLegend from '../../../../components/UI/CalculationLegend';
import type { PopoverItem } from '../../../../components/UI/Popovers';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import useModal from '../../../../hooks/useModal';
import type { SourceUrlProps } from '../../../../interfaces/SourceUrlProps';
import { setIsLoading } from '../../../../reducers/loading';
import { setSelectedPrecalculation, setSelectedVersion } from '../../../../reducers/precalculations';
import store from '../../../../store';
import { formatToNiceDate, formatToYYYYMMDD } from '../../../../tools/formatDates';
import formatNumber from '../../../../tools/formatNumber';
import getUnitLabel from '../../../../tools/getUnitLabel';
import shareUrl from '../../../../tools/shareUrl';

const PrecalculationDetail: React.FC = () => {
  const {
    historicalPrecalculationId,
    precalculationId,
    uuid
  } = useParams<{
    precalculationId: string | undefined,
    uuid: string | undefined,
    historicalPrecalculationId: string | undefined
  }>();
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(new Date());
  const [versionModal, setVersionModal] = useState(false);
  const [selectedPrecalculationRow, setSelectedPrecalculationRow] = useState<Partial<E2U.V1.Models.PrecalculationRow>>({});
  const [precalculationVersions, setPrecalculationVersions] = useState<any>([]);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const selectedPrecalculation = useAppSelector((state) => state.precalculations.selectedPrecalculation);
  const selectedVersion = useAppSelector(state => state.precalculations.selectedVersion);
  const { t } = useTranslation();
  const location = useLocation();
  const router = useIonRouter();
  const { pathname } = router.routeInfo;
  const { closeModal, isModalOpen, openModal } = useModal();
  const onModalDismiss = () => closeModal();
  const graphData = useChartData();
  const methods = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      id: '',
    }
  });
  const name = methods.watch('name');
  const id = methods.watch('id');
  const shareURL = process.env.REACT_APP_SHARE_BASE_URL + location.pathname;
  const [securedCostFilter, setSecuredCostFilter] = useState<'none' | boolean>('none');

  const selectedPrecalculationId = useMemo(() => {
    if (selectedVersion?.precalculation_id) {
      return selectedVersion.precalculation_id;
    } else {
      return selectedPrecalculation?.id;
    }
  }, [selectedVersion, selectedPrecalculation]);

  const sourceUrlParams: SourceUrlProps['args'] = useMemo(() => {
    return {
      filters: JSON.stringify([{
        field: 'secured_cost',
        value: securedCostFilter === 'none'
          ? [0, 1]
          : (
            securedCostFilter ? 1 : 0
          )
      }])
    };
  }, [securedCostFilter]);

  const getPrecalculation = async () => {
    return new Promise<void>((resolve) => {
      if (precalculationId) {
        store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
        networking.get(`api/v1/precalculations/${precalculationId}`)
          .then((response) => {
            store.dispatch(setSelectedPrecalculation(response.data.data));
            store.dispatch(setSelectedVersion(undefined));
            resolve();
          })
          .catch((error) => {
            Sentry.captureException(error);
          })
          .finally(() => {
            store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
          });
      } else if (historicalPrecalculationId) {
        networking.get(`api/v1/precalculation_histories/${historicalPrecalculationId}`)
          .then((response) => {
            store.dispatch(setSelectedVersion(response.data.data));
            networking.get(`api/v1/precalculations/${response.data.data.precalculation_id}`)
              .then((res) => {
                store.dispatch(setSelectedPrecalculation(res.data.data));
                resolve();
              });
          })
          .finally(() => {
            store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
          });
      }
    });
  };

  const getPrecalculationVersions = () => {
    store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
    networking.get(`/api/v1/precalculations/${selectedPrecalculationId}/versions?${searchParams}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Precalculation[]>>) => {
        setPrecalculationVersions(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
      });
  };

  const handleSelectedVersion = (id?: string) => {
    const selectedVersion = precalculationVersions.find((version: E2U.V1.Models.Precalculation) => version.id === id);
    const isVersion = Boolean(selectedVersion);

    const projectPrecalculationPaths = isVersion
      ? `/financials/${uuid}/precalculation_history/${id}`
      : `/financials/${uuid}/precalculations/${id}`;

    const userPrecalculationPaths = isVersion
      ? `/financials/precalculation_history/${id}`
      : `/financials/precalculations/${id}`;
    const checkPath = uuid ? projectPrecalculationPaths : userPrecalculationPaths;
    router.push(checkPath, 'none', 'replace');
  };

  const handleExport = () => {
    networking.get(
      `/api/v1/precalculations/${selectedPrecalculation?.id}/share`
    )
      .then((response) => {
        shareUrl(
          `${t('Precalculation for {projectName}.', 'Precalculation for {projectName}.', { projectName: selectedProject?.name })}`,
          `${t('Here is the link to the precalculation for {projectName}: {url}.', 'Here is the link to the precalculation for {projectName}: {url}.', {
            projectName: selectedProject?.name, url: isPlatform('ios') ? response.data.data.url : ''
          })}`,
          `${response.data.data.url}`,
          t(`Let me know if there is something you are wondering about.`),
          'export'
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleShareRevision = () => {
    shareUrl(
      `${t('Share revision for {projectName}.', 'Share revision for {projectName}.', { projectName: selectedProject?.name })}`,
      `${t('Here is the link for revision')}: ${isPlatform('ios') ? shareURL : ''}`,
      `${process.env.REACT_APP_SHARE_BASE_URL + location.pathname}`,
      t(`Let me know if there is something you are wondering about.`),
      'share'
    );
  };

  const handleShareAndExportOfPrecalculation = (handler: 'export' | 'share') => {
    if (handler === 'export') return handleExport();
    if (handler === 'share') return handleShareRevision();
  };

  const deletePrecalculationRow = () => {
    store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
    toasters
      .promiseToast(networking.delete(`/api/v1/precalculation_rows/${selectedPrecalculationRow.id}`), {
        pending: {
          message: t('Deleting precalculation row')
        },
        success: {
          message: t('Successfully deleted precalculation row')
        },
        error: {
          message: t("Couldn't delete precalculation row")
        }
      })
      .then(() => {
        getPrecalculation()
          .then(() => {
            setLastUpdateTimestamp(new Date());
          });
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
        getPrecalculation();
        onModalDismiss();
      });
  };

  const updatePrecalculatioName = (data: FieldValues) => {
    networking.put(`/api/v1/precalculations/${selectedPrecalculation?.id}`, {
      name: data.name,
      project_id: selectedProject?.id,
    })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Precalculation>) => {
        store.dispatch(setSelectedPrecalculation(response.data.data));
        toasters.createToast({
          message: t('Name updated to {name}', 'Name updated to {name}', { name: response.data.data.name }),
          background: 'var(--ion-color-light)',
        }, 'success');
        setVersionModal(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const submitPrecalculation: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const pushNameDataToReducer = {
      ...selectedPrecalculation,
      name: data.name,
      project_id: selectedProject?.id,
    };
    const endpoint = historicalPrecalculationId
      ? `api/v1/precalculations/${historicalPrecalculationId}/restore`
      : `/api/v1/precalculations/${precalculationId}/submit`;
    const networkRequest = networking.post(`${endpoint}`, pushNameDataToReducer);

    toasters.promiseToast(networkRequest, {
      pending: {
        message: t('Submitting precalculation'),
      },
      success: {
        message: t('Successfully submitted precalculation'),
      },
      error: {
        message: t("Couldn't submit precalculation"),
      }
    })
      .then((response: E2U.V1.Response.Success<{
        precalculation_id: string;
        precalculation_iteration: number;
        precalculation_history_id: string;
        precalculation_history_iteration: number;
      }>) => {
        router.push(
          uuid
            ? `/financials/${uuid}/precalculations/${response.data.data.precalculation_id}`
            : `/financials/precalculations/${response.data.data.precalculation_id}`
        );
        if (!historicalPrecalculationId) {
          getPrecalculationVersions();
        }
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      });
  };

  const onRowSave = () => {
    getPrecalculation()
      .then(() => {
        closeModal();
        setLastUpdateTimestamp(new Date());
      });
  };

  const sendDeleteRequest = (id: string | undefined) => {
    networking.delete(`/api/v1/precalculations/${id}`)
      .then(() => {
        setLastUpdateTimestamp(new Date());
        toasters.createToast({
          message: t('Precalculation deleted successfully'),
          background: 'var(--ion-color-light)'
        }, 'success');
        router.push(`/financials/${selectedProjectId}/precalculations`);
      }
      ).catch((error) => {
        toasters.createToast({
          message: t('Error deleting precalculation'),
          background: 'var(--ion-color-light)'
        }, 'error');
        Sentry.captureException(error);
      });
  };
  const [presentAlert] = useIonAlert();

  const handleDelete = (id: string | undefined) => {
    presentAlert({
      header: t('Are you sure you want to delete this precalculation?'),
      message: t('This will permanently delete the precalculation and all its versions.'),
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('OK'),
          role: 'confirm',
          handler: () => {
            sendDeleteRequest(id);
          }
        }
      ]
    });
  };
  const actionButtonList: ActionSheetItem[] | PopoverItem[] = [
    {
      value: 'share',
      label: t('Share'),
      onClick: () => {
        handleShareAndExportOfPrecalculation('share');
      }
    },
    {
      value: 'export',
      label: t('Share file'),
      onClick: () => {
        handleShareAndExportOfPrecalculation('export');
      }
    },
    /*
         // TODO: Implement these features (backend and frontend)
        {
           value: 'favorite',
           label: t('Mark as favorite'),
           icon: {
             icon: starOutline
           },
           onClick: () => {
             console.log('Mark / Unmark as favorite');
           }
         }, */
    {
      value: 'duplicate',
      label: t('Duplicate'),
      onClick: () => {
        router.push(`/financials/precalculations/${selectedPrecalculation?.id}/duplicate`);
      }

    },
    {
      value: 'create-version',
      label: t('Create new version'),
      onClick: () => {
        setVersionModal(true);
      }
    },
    ...(historicalPrecalculationId
      ? []
      : [{
        value: 'create-project',
        label: t('Create project'),
        onClick: () => {
          router.push(`/financials/precalculations/${selectedPrecalculation?.id}/create-project`);
        }
      }]),
    ...(!historicalPrecalculationId
      ? [{
        value: 'delete',
        label: t('Delete'),
        onClick: () => {
          handleDelete(selectedPrecalculation?.id);
        }
      },]
      : []),
    ...(!isDesktop
      ? [{
        role: 'cancel',
        value: 'cancel',
        label: t('Close'),
        onClick: () => {
        },
      }]
      : [])
  ];

  const historicalPrecalculationList: PopoverItem[] = [
    {
      value: 'current',
      label: t('Current: {name}', 'Current: {name}', { name: selectedPrecalculation?.name }),
      onClick: () => {
        handleSelectedVersion(selectedPrecalculationId);
      },
      icon: {
        icon: selectedPrecalculationId === precalculationId ? checkmark : undefined,
        size: 'large'
      }
    },
    ...precalculationVersions.map((version: E2U.V1.Models.Precalculation) => ({
      value: version.id,
      label: `${formatToYYYYMMDD(version.created_at)} ${version.name} `,
      onClick: () => handleSelectedVersion(version.id),
      icon: {
        icon: version.id === selectedVersion?.id ? checkmark : history
      }
    })),
    ...(!isDesktop
      ? [{
        value: 'cancel',
        label: t('Close'),
        onClick: () => {
        },
      }]
      : [])
  ];

  const [presentHistorical] = useIonPopover(BigUp.Popovers.Default, {
    items: historicalPrecalculationList,
    mode: 'md',
  });
  const [presentOptions] = useIonPopover(BigUp.Popovers.Default, {
    items: actionButtonList,
    mode: 'md',
  });

  const [presentHistoricalActionSheet] = useIonActionSheet();
  const [presentOptionsActionSheet] = useIonActionSheet();

  const showPopover = (
    present: (options: any) => void,
    e: React.MouseEvent
  ) => {
    present({
      event: e as any,
      dismissOnSelect: true,
    });
  };

  const showActionSheet = (
    present: (options: any) => void,
    items: ActionSheetItem[] | PopoverItem[],
    cssClass?: string
  ) => {
    present({
      cssClass,
      buttons: items.map((item) => ({
        text: item.label,
        handler: item.onClick,
        icon: item.icon?.icon,
        role: item.role
      })),
    });
  };

  useEffect(() => {
    if (precalculationId) {
      setLastUpdateTimestamp(new Date());
    }
  }, [pathname]);

  useEffect(() => {
    getPrecalculation()
      .then(() => {
        setLastUpdateTimestamp(new Date());
      });
  }, [precalculationId, historicalPrecalculationId, pathname]);

  useEffect(() => {
    if (selectedPrecalculation?.id !== id) {
      methods.setValue('id', selectedPrecalculation?.id);
      methods.setValue('name', selectedPrecalculation?.name);
    }
  }, [selectedPrecalculation]);

  useEffect(() => {
    if (selectedPrecalculationId) {
      getPrecalculationVersions();
    } else {
      setPrecalculationVersions([]);
    }
  }, [selectedPrecalculationId]);

  useEffect(() => {
    if (selectedProjectId &&
      selectedPrecalculation &&
      selectedPrecalculation.project_id &&
      selectedPrecalculation.project_id !== selectedProjectId
    ) {
      router.push(`/financials/${selectedProjectId}/precalculations`);
    }
  }, [selectedProjectId]);

  return (
    <div {...isDesktop && { className: 'ion-padding' }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitPrecalculation)}>
          <IonGrid className='ion-padding-top'>
            <IonRow className='ion-justify-content-end ion-align-items-center '>
              <IonCol>
                <section className={styles['precalculations-legend-wrapper']}>
                  <CalculationLegend
                    color='var(--ion-color-light-shade)'
                    label={{
                      start: {
                        label: precalculationId
                          ? t('Current: ')
                          : t('Version: ')
                      },
                      end: {
                        label: <PrecalculationTitle />
                      }
                    }}
                  />
                </section>
              </IonCol>
              <IonCol size='auto' className='ion-no-padding'>
                <IonRow>
                  <IonCol size='auto'>
                    <IonButton
                      onClick={(e) => {
                        if (isDesktop) {
                          showPopover(presentHistorical, e);
                        } else {
                          showActionSheet(
                            presentHistoricalActionSheet,
                            historicalPrecalculationList,
                            styles['precalculation-action-sheet']
                          );
                        }
                      }}
                      className={`icon-only ${styles['precalculations-history-button']}`}
                      fill='clear'
                    >
                      <IonIcon icon={history} size='large' />
                    </IonButton>
                  </IonCol>
                  <IonCol size='auto'>
                    <IonButton
                      onClick={(e) => {
                        if (isDesktop) {
                          showPopover(presentOptions, e);
                        } else {
                          showActionSheet(presentOptionsActionSheet, actionButtonList, 'precalculation-options-sheet');
                        }
                      }}
                      className={`icon-only ${styles['precalculations-options-button']}`}
                      fill='clear'
                    >
                      <IonIcon icon={ellipsisHorizontal} size='large' color={'medium'} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>

          {((selectedPrecalculation && precalculationId) || (selectedVersion && historicalPrecalculationId)) && (
            <PrecalculationsRadialChart
              pieChartData={graphData.pieChartData as any}
              remainingTotal={graphData.remainingTotal}
              selectedPrecalculation={selectedPrecalculation}
            />
          )}

          <IonGrid className='ion-padding-top ion-no-padding ion-margin-top'>
            <IonRow className='ion-justify-content-between ion-align-items-center' style={{ margin: 16 }}>
              <IonCol size='auto'>
                <BigUp.Label.V2Thick label={t('Costs')} color='medium' />
              </IonCol>

              <IonCol size='auto'>
                {historicalPrecalculationId
                  ? (
                    <BigUp.Buttons.Secondary
                      size='small'
                      disabled={!name || methods.formState.isSubmitting}
                      type="submit"
                      title={historicalPrecalculationId ? t('Restore') : t('Save')}
                    />
                  )
                  : <BigUp.Buttons.Secondary
                    size='small'
                    title={t('Add')}
                    icon={{ icon: add }}
                    onClick={() => router.push(`/financials/precalculations/${precalculationId}/add-row`)}
                  />
                }

              </IonCol>
            </IonRow>
          </IonGrid>

          <div
            {...isDesktop && { className: styles['precalculations-desktop-wrapper'] }} >
            <div style={{ width: '100%' }}>
              {(precalculationId || historicalPrecalculationId) && (
                <BigUp.Table
                  sourceUrl={
                    {
                      url: precalculationId
                        ? `/api/v1/precalculations/${precalculationId}/rows`
                        : `/api/v1/precalculation_histories/${historicalPrecalculationId}/rows`,
                      args: sourceUrlParams
                    }
                  }
                  columns={
                    [{
                      key: 'stamped_activity_code',
                      label: t('Titel'),
                      alignment: 'left',
                      sizes: {
                        xl: '2',
                        xs: '6'
                      },
                      body: <ActivityCodeColumn
                        value=''
                        attributes={{} as E2U.V1.Models.PrecalculationRow} />,
                      sortable: true,
                      default_sort: true,
                      default_sort_direction: 'asc'
                    },
                    ...columns as any
                    ]
                  }
                  filters={[{
                    label: t('All costs'),
                    callback: () => {
                      setSecuredCostFilter('none');
                    }
                  }, {
                    label: t('Only secured costs'),
                    callback: () => {
                      setSecuredCostFilter(true);
                    }
                  }, {
                    label: t('Only unsecured costs'),
                    callback: () => {
                      setSecuredCostFilter(false);
                    }
                  }]}
                  reducers={{
                    quantity: (value: number) => formatNumber(value, true),
                    cost_per_unit: (value: number) => formatNumber(value, true),
                    updated_at: (value: string) => formatToNiceDate(value),
                    total: (value: number) => formatNumber(value, true),
                    unit: (value: any) => getUnitLabel(value),
                  }}
                  callbacks={{
                    ...precalculationId && {
                      onRowClick: (row: E2U.V1.Models.PrecalculationRow) => {
                        setSelectedPrecalculationRow(row);
                        openModal();
                      }
                    }
                  }}
                  timestamp={lastUpdateTimestamp}
                />
              )}
            </div>
          </div>

        </form>
      </FormProvider>
      <FormProvider {...methods}>
        <BigUp.Modal.InputModal
          modal={{
            title: t('Create version'),
            description: t('Create a new version of the precalculation'),
            onDismiss: () => setVersionModal(false),
            isOpen: versionModal,
          }}
          button={{
            title: t('Create version'),
            disabled: !name || methods.formState.isSubmitting,
            type: 'submit',
            onClick: () => {
              updatePrecalculatioName(methods.getValues());
            },
          }}
          onSubmit={() => {
          }}
          input={{
            validation: {
              required: 'Required',
            },
            disabled: !!historicalPrecalculationId,
            label: precalculationId ? t('Name') : t('Current name'),
            labelPlacement: 'stacked',
            className: 'ion-no-padding ion-no-margin',
            register: 'name',
            placeholder: t('Name'),
            type: 'text',
            inputMode: 'text',
          }}
        />
      </FormProvider>
      <IonModal
        className={styles['precalculation-row-form-modal']}
        isOpen={isModalOpen}
        onIonModalDidDismiss={onModalDismiss}
      >
        <PrecalculationForm
          deletePrecalculationRow={deletePrecalculationRow}
          onDismiss={onModalDismiss}
          selectedRow={selectedPrecalculationRow}
          onRowSave={onRowSave}
        />
      </IonModal>
    </div>
  );
};

export default PrecalculationDetail;
