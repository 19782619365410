
import { IonCol, IonGrid, IonInputPasswordToggle, IonNote, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './Registration.module.scss';
import type { PasswordInputsProps } from './types';
import { BigUp } from '../../components/UI';
import type { InputProps } from '../../components/UI/Inputs/Input.interface';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';
import { regex } from '../../regex/regex';
const password_pattern = {
  value: regex.regex_password,
  message: i18n.t('Must contain at least one number, one symbol, one uppercase and lowercase letter')
};

const password_length = {
  value: 8,
  message: i18n.t('Password must be at least 8 characters')
};

const PasswordInputs: React.FC<PasswordInputsProps> = ({ onKeyUp, required }) => {
  const methods = useFormContext();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const validatePassword = (value: string) => {
    const watchPassword = methods.getValues('password');
    return value === watchPassword || i18n.t('The entered passwords do not match');
  };

  const password_input: InputProps<E2U.V1.Models.User> = {
    customLabel: i18n.t('Password'),
    type: 'password',
    inputMode: 'text',
    enterkeyhint: 'next',
    placeholder: i18n.t('Password'),
    autocomplete: 'new-password',
    onKeyUp,
    showErrorBadge: !isDesktop,
    register: required ? 'password' : undefined,
    validation: {
      required: required ? i18n.t('Please enter a password') as keyof E2U.V1.Models.User : false,
      minLength: { value: password_length.value, message: password_length.message },
      pattern: { value: password_pattern.value, message: password_pattern.message },
    },
  };

  const confirm_password_input: InputProps<E2U.V1.Models.User> = {
    customLabel: i18n.t('Confirm password'),
    type: 'password',
    inputMode: 'text',
    autocomplete: 'new-password',
    enterkeyhint: 'done',
    placeholder: i18n.t('Confirm password'),
    onKeyUp,
    showErrorBadge: !isDesktop,
    register: required ? 'password_confirmation' : undefined,
    validation: {
      validate: (value) => validatePassword(value) as boolean,
      ...!isDesktop && {
        required: required ? i18n.t('Please enter a password') as keyof E2U.V1.Models.User : false,
        minLength: { value: password_length.value, message: password_length.message },
        pattern: { value: password_pattern.value, message: password_pattern.message },
      }
    },
  };

  const columnStyles = {
    paddingRight: isDesktop ? 4 : 0,
    marginBottom: !isDesktop ? 16 : 0
  };

  const error = (input: InputProps<E2U.V1.Models.User>) => {
    return (
      (isDesktop && methods.formState.errors && methods.formState.errors[input.register]) && (
        <BigUp.Label.Regular
          color='danger'
          className='ion-no-margin'
          label={methods.formState.errors[input.register]?.message as string}
        />
      )
    );
  };

  return (
    <IonRow className='ion-align-items-center'>
      <IonCol
        size='12'
        sizeSm='6'
        className='ion-no-padding'
        style={columnStyles}
      >
        <BigUp.OutlinedInput {...password_input}>
          <IonInputPasswordToggle className={styles.passwordToggle} color='medium' slot="end" />
        </BigUp.OutlinedInput>
      </IonCol>

      <IonCol
        size='12'
        sizeSm='6'
        sizeMd='6'
        className='ion-no-padding'
        style={columnStyles}
      >
        <BigUp.OutlinedInput {...confirm_password_input} >
          <IonInputPasswordToggle className={styles.passwordToggle} color='medium' slot="end" />
        </BigUp.OutlinedInput>
      </IonCol>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {error(password_input)}
        {error(confirm_password_input)}
      </div>
    </IonRow>
  );
};

export default PasswordInputs;
