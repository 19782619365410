import * as Sentry from '@sentry/capacitor';
import { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import { regex } from '../../../regex/regex';
import store from '../../../store';
import toasters from '../Toasts';
import type { InputModalProps } from './interface';

const ResetPassword: React.FC<InputModalProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const isResettingPassword = useAppSelector((state) => state.loading.isLoading.resettingPassword);
  const [sentToEmail, setSentToEmail] = useState<string>();
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });

  const handleOnDismiss = () => {
    props.modal.onDismiss();
    setSuccessMessage(undefined);
    methods.reset({ email: '' });
  };

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({ name: 'resettingPassword', value: true }));
    setSentToEmail(data.email);
    networking.post('/api/v1/users/reset_password/request', data)
      .then(() => {
        setSuccessMessage(t('If the email is registered, a reset link has been sent to your email.'));
      })
      .catch((error) => {
        Sentry.captureMessage(error);
        toasters.createToast({
          message: i18n.t('An error occurred, try again later.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'resettingPassword', value: false }));
      });
  };

  const handleSetValue = () => {
    if (props.defaultValue !== undefined && props.defaultValue !== '') {
      methods.setValue('email', props.defaultValue);
    }
  };

  useEffect(() => {
    handleSetValue();
  }, [props.defaultValue]);

  return (
    <FormProvider {...methods}>
      <BigUp.Modal.InputModal
        input={{
          showErrorBadge: true,
          defaultValue: props.defaultValue,
          id: 'email',
          name: 'email',
          register: 'email',
          placeholder: t('Enter email'),
          validation: {
            required: {
              value: true,
              message: t('Email is required')
            },
            pattern: {
              value: regex.regex_email,
              message: t('Invalid email address')
            }
          },
        }}
        textAlignment='center'
        modal={{
          ...successMessage && { successMessage },
          isOpen: props.modal.isOpen,
          onDismiss: handleOnDismiss,
          title: t('Reset Password'),
          description: successMessage
            ? t(`If there is a registered account for {email} an email will be sent there.`, `If there is a registered account for {email} an email will be sent there.`, { email: sentToEmail })
            : t('Enter your registered email address to reset your password.')
        }}
        button={{
          type: 'submit',
          title: t('Send reset link'),
          disabled: isResettingPassword || methods.formState.isSubmitting || !methods.formState.isValid,
          loading: isResettingPassword
        }}
        onSubmit={methods.handleSubmit(handleFormSubmit)}
      />
    </FormProvider>
  );
};

export default ResetPassword;
