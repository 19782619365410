import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useModal from '../../hooks/useModal';
import i18n from '../../i18n';
import BigUp from '../UI';
import styles from './TermsAndConditions.module.scss';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const TermsAndConditionCheckbox: React.FC = () => {
  const [agreed, setAgreed] = useState(false);
  const methods = useFormContext();
  const termsAndConditionsModal = useModal();
  const { t } = useTranslation();

  const openTermsAndConditions = () => {
    termsAndConditionsModal.toggleModal();
  };

  const handleModal = (handling: 'accept-terms' | 'decline-terms') => {
    if (handling === 'accept-terms') {
      setAgreed(true);
      methods.setValue('terms_and_conditions', true);
    }
    if (handling === 'decline-terms') {
      setAgreed(false);
      methods.setValue('terms_and_conditions', false);
    }
    termsAndConditionsModal.closeModal();
  };

  return (
    <>
      <IonGrid className='ion-no-padding'>
        <IonRow className='ion-align-items-center ion-justify-content-between'>
          <IonCol size='11'>
            <IonItem lines='none' detail={false} className='ion-no-padding'>
              <IonLabel className='ion-no-margin' >
                <BigUp.Label.Thick color={'medium'} className='ion-no-margin' label={i18n.t('Terms and conditions')} />
                <section className={styles['terms-and-conditions__section']}>
                  <p>{t('I have read the terms and ')}</p>
                  <a
                    role="button"
                    className={styles['terms-and-conditions__button']}
                    onClick={openTermsAndConditions}>
                    {t('conditions')}
                  </a>
                </section>
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size='1'>
            <BigUp.Checkbox
              checked={agreed}
              labelPlacement='start'
              handleCheckbox={(checked) => {
                setAgreed(checked);
                methods.setValue('terms_and_conditions', checked);
              }}
              validation={{
                required: { value: true, message: t('You must agree to the terms and conditions') }
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      <TermsAndConditionsModal
        handleModal={handleModal}
        modalStateHandlers={{
          open: termsAndConditionsModal.openModal,
          close: termsAndConditionsModal.closeModal,
          isOpen: termsAndConditionsModal.isModalOpen
        }}
      />
    </>
  );
};

export default TermsAndConditionCheckbox;
