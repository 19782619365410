import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonPopover, IonRow, IonTitle } from '@ionic/react';
import { close, help, information } from 'ionicons/icons';
import type { ComponentProps } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { HTMLGenericProps } from '../generic.interface';

type Popover = ComponentProps<typeof IonPopover> & HTMLGenericProps<HTMLIonPopoverElement>;
interface HelpPopoverProps extends Popover {
  children: string | React.ReactNode;
  type?: 'info' | 'help';
}

const HelpPopover: React.FC<HelpPopoverProps> = (props) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { t } = useTranslation();
  const openPopover = (e: Event) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const closePopover = () => setPopoverOpen(false);

  return (
    <>
      <IonButton
        onClick={(e) => openPopover(e.nativeEvent)}
        size={'small'}
        shape='round'
        style={{ '--background': 'var(--bigup-gradient-primary)' }}
      >
        <IonIcon
          slot="icon-only"
          icon={props.type === 'info' ? information : help}
        />
      </IonButton>

      <IonPopover
        ref={popover}
        isOpen={popoverOpen}
        onIonPopoverDidDismiss={closePopover}
        alignment={props.alignment || 'center'}
        animated={true}
        side={props.side || 'top'}
        mode={props.mode || 'md'}
        {...props}
      >
        <IonContent class="ion-padding">
          <IonGrid >
            <IonRow className='ion-align-items-center ion-justify-content-between ion-no-padding'>
              <IonCol size='auto' className='ion-no-padding'>
                <IonTitle className='ion-no-margin ion-no-padding'>{t('Need help?')}</IonTitle>
              </IonCol>
              <IonCol size='auto' className='ion-no-padding '>
                <IonButton size='small' fill='clear' onClick={closePopover}>
                  <IonIcon slot="icon-only" icon={close} />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='12' className='ion-no-padding'>
                {props.children}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default HelpPopover;
