import { IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonPage, IonRow, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../components/UI';
import { useAppSelector } from '../../hooks';
import { UnauthorizedHeader } from '../Registration';
import styles from './Introduction.module.scss';
import Sections from './Sections';

const IntroductionPage: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const device = isDesktop ? 'desktop' : 'mobile';
  const router = useIonRouter();

  return (
    <IonPage>
      <UnauthorizedHeader />
      <IonContent scrollY={false} className={styles.introductionContent}>
        <article className={`${styles['introduction-page-container']} ${styles[`introduction-page-container--${device}`]}`}>
          <div className={`${styles['introduction-page']} ${styles[`introduction-page--${device}`]}`}>
            <IonToolbar mode='ios' className={`ion-margin-bottom ion-margin-top ${styles['introduction-toolbar']}`}>
              <IonButtons>
                <IonBackButton defaultHref='/' text={''} />
              </IonButtons>
              <IonTitle>{t('Get started with your organization')}</IonTitle>
            </IonToolbar>
            <IonGrid>
              <Sections.Header />
              <Sections.CallMeUp />
              <BigUp.Seperator.LabeledSeparator label={t('Or')} />
              <IonRow className='ion-justify-content-center ion-padding ion-margin-bottom'>
                <IonCol size='10'>
                  <BigUp.Buttons.Primary
                    title={t('Fill in our form')}
                    expand='block'
                    onClick={() => router.push('/client-account/create')}
                  />
                </IonCol>
              </IonRow>
              <BigUp.Seperator.SeperatorLine />
              <Sections.WorksiteCodeAccess />
            </IonGrid>
          </div>
        </article>
      </IonContent>
    </IonPage>
  );
};

export default IntroductionPage;
