import { IonCol, IonItem, IonLabel, IonList, IonLoading, IonNote, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';
import EmptyList from '../../UI/EmptyList';
import type { AclPolicy } from '../AccessList';

interface AccessModuleListProps {
  rows: E2U.V1.Objects.AclSearchResult | null;
  entity: 'projects' | 'clients';
  id: string;
  handleClose?: () => void;
  onSuccessfulGrant: (result: E2U.V1.Objects.AclGrantAccessResult) => void;
}

interface AccessModuleFormData {
  selected_modules: string[];
  rows: E2U.V1.Objects.AclSearchResult | null;
}

const AccessModuleList: React.FC<AccessModuleListProps> = (props) => {
  const { t } = useTranslation();
  const isLoadingAccessModules = useAppSelector((state) => state.loading.isLoading.isLoadingAccessModules);
  const [availableModules, setAvailableModules] = React.useState<E2U.V1.Objects.AclQueryResultRow[]>([]); // FIXME: Add typings
  const methods = useForm<AccessModuleFormData>({
    defaultValues: {
      selected_modules: [],
      rows: null
    },
  });
  const selected_modules = methods.watch('selected_modules');

  const fetchAvailableModules = () => {
    if (!props.entity || !props.rows || !props.id || (
      (!props.rows.found || !props.rows.found.length) &&
      (!props.rows.missing || !props.rows.missing.length)
    )) return;
    store.dispatch(setIsLoading({
      name: 'isLoadingAccessModules',
      value: true
    }));
    const entityColumnName = props.entity === 'projects' ? 'project_id' : 'client_id';
    networking.post(`/api/v1/acl/query`, {
      targets: [...props.rows.found, ...props.rows.missing],
      [entityColumnName]: props.id
    })
      .then((res: E2U.V1.Response.Success<E2U.V1.Objects.AclQueryResult>) => { // FIXME: Add typings
        if (!res.data.data || res.data.data.length === 0) {
          toasters.error(t('No available modules found'));
          props.handleClose && props.handleClose();
        }
        setAvailableModules(res.data.data);
      })
      .catch((err) => {
        Sentry.captureException(err);
        toasters.error(t('Failed to fetch available modules. Please try again later, or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'isLoadingAccessModules',
          value: false
        }));
      });
  };

  const giveAccess: SubmitHandler<AccessModuleFormData> = (data) => {
    store.dispatch(setIsLoading({
      name: 'isGivingAccess',
      value: true
    }));
    const entityColumnName = props.entity === 'projects' ? 'project_id' : 'client_id';
    const combinedRows = [
      ...props.rows?.found ?? [],
      ...props.rows?.missing ?? []
    ];
    networking.post(`/api/v1/acl/grant`, {
      [entityColumnName]: props.id,
      items: combinedRows.map((row) => ({
        id: row.id,
        type: row.type,
        policies: data.selected_modules
      }))
    })
      .then((res: E2U.V1.Response.Success<E2U.V1.Objects.AclGrantAccessResult>) => { // FIXME: Add typings
        toasters.success(t('Access granted successfully'));
        props.onSuccessfulGrant(res.data.data);
      })
      .catch((err) => {
        Sentry.captureException(err);
        toasters.error(t('Failed to give access to modules. Please try again later, or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'isGivingAccess',
          value: false
        }));
      });
  };

  const fetchExisitingModulesForRow = (row: E2U.V1.Objects.AclFoundEntity) => {
    const urlSearchParams = new URLSearchParams();
    const idColumn = row.type === 'user' ? 'user_id' : 'team_id';
    urlSearchParams.append(idColumn, row.id);
    networking.get(`/api/v1/${props.entity}/${props.id}/acl/list?${urlSearchParams.toString()}`)
      .then((res: E2U.V1.Response.Success<{
        policies: AclPolicy[] // TODO: Add to typing spacking
      }>) => {
        methods.setValue('selected_modules', res.data.data.policies.map((policy) => policy.id));
      });
  };

  const handleModuleSelection = (module_id: string, checked: boolean) => {
    if (selected_modules.includes(module_id) && !checked) {
      methods.setValue('selected_modules', selected_modules.filter((id) => id !== module_id));
    } else {
      methods.setValue('selected_modules', [...selected_modules, module_id]);
    }
    methods.trigger('selected_modules');
  };

  const syncRowsFormValue = () => {
    if (props.rows) {
      methods.setValue('rows', props.rows);
      if (props.rows.found.length === 1) {
        fetchExisitingModulesForRow(props.rows.found[0]);
      }
    } else {
      methods.setValue('rows', null);
    }
  };

  useEffect(() => {
    fetchAvailableModules();
    syncRowsFormValue();
  }, []);

  useEffect(() => {
    syncRowsFormValue();
  }, [props.rows]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(giveAccess)}>
        {isLoadingAccessModules
          ? <IonLoading isOpen={true} />
          : (availableModules.length > 0
            ? <>
              <IonList>
                {availableModules.map((module, index) => (
                  <IonItem
                    className='ion-no-padding'
                    style={{ borderRadius: 8 }}
                    key={index}
                    onClick={() => module.is_available && handleModuleSelection(module.id, !selected_modules.includes(module.id))}
                    button={module.is_available}
                  >
                    <BigUp.Checkbox
                      slot="end"
                      aria-label={t('Toggle module assignment')}
                      disabled={!module.is_available}
                      value={selected_modules.includes(module.id)}
                      handleCheckbox={(checked) => handleModuleSelection(module.id, checked)}
                    />
                    <IonLabel>
                      <BigUp.Label.Thick label={module.name} />
                      <p>
                        {t('{existing} used + {toAdd} new of {total} seats', {
                          existing: module.occupied,
                          toAdd: module.to_add,
                          total: module.total
                        })}
                        {!module.is_available && `. ${module.message}`}
                      </p>
                    </IonLabel>
                    {!module.is_available && (
                      <IonNote color='danger' style={{ marginTop: '8px' }}>
                        {t('Not available')}
                      </IonNote>
                    )}
                  </IonItem>
                ))}
              </IonList>
              <IonRow className={'ion-justify-content-center ion-margin'}>
                <IonCol size={'10'}>
                  <BigUp.Buttons.Primary
                    expand='block'
                    title={t('Save')}
                    className={'ion-margin-top ion-text-end'}
                    disabled={!selected_modules.length}
                    type={'submit'}
                  />
                </IonCol>
              </IonRow>
            </>
            : <EmptyList
              title={t('No modules available')}
              message={t('This project doesn\'t have any modules available. Contact support for more information.')}
            />
          )}
      </form>
    </FormProvider>
  );
};

export default AccessModuleList;
