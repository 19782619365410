import { useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import BigUp from '../../UI';
import toasters from '../../UI/Toasts';

interface CreateButtonProps {
  onNodeCreated: (node: any) => void;
  type: 'projects' | 'clients';
  id: string;
}
const CreateButton: React.FC<CreateButtonProps> = ({ id, onNodeCreated, type }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const methods = useForm({});
  const { t } = useTranslation();

  const idColumn = type === 'projects' ? 'project_id' : 'client_id';
  const endpointType = type === 'projects' ? 'project_nodes' : 'client_nodes';

  const createNode = (data: any) => {
    store.dispatch(setIsLoading({ name: 'creatingNode', value: true }));

    networking.post(`/api/v1/work_preparations/${endpointType}`, {
      name: data.name,
      [idColumn]: id
    })
      .then((res: E2U.V1.Response.Success<any>) => {
        onNodeCreated(res.data.data);
        setModalOpen(false);
      })
      .catch(() => {
        toasters.createToast({
          message: t('Something went wrong when trying to create template. Please try again later or contact support.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'creatingNode', value: false }));
      });
  };

  return (
    <>
      <BigUp.TableHeader
        title={t('Templates')}
        button={{
          size: 'small',
          title: t('Create new template'),
          onClick: () => setModalOpen(true)
        }}
      />
      <FormProvider {...methods}>
        <BigUp.Modal.InputModal
          maxHeight='230px'
          modal={{
            isOpen: modalOpen,
            onDismiss: () => setModalOpen(false),
            title: t('Create template'),
          }}
          button={{
            type: 'submit',
            title: t('Save'),
            disabled: methods.formState.isSubmitting || !methods.formState.isValid,
            loading: false
          }}
          textAlignment='center'
          input={{
            name: 'name',
            type: 'text',
            placeholder: t('Enter name'),
            register: 'name',
            validation: {
              required: true,
              minLength: 1
            }
          }}
          onSubmit={methods.handleSubmit(createNode)}
        />
      </FormProvider >
    </>
  );
};

export default CreateButton;
