import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonTitle, IonToolbar, isPlatform, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { chevronBack, ribbon, warning } from 'ionicons/icons';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import PasswordHandler from './PasswordHandler';
import styles from './Registration.module.scss';
import RegistrationInputs from './RegistrationInputs';
import { networking, setAxiosAccessToken } from '../../api/networking';
import bigUpLogo from '../../assets/svg/bigup-white.svg';
import { useBankIdContext } from '../../bankid/bankid.context';
import AppVersion from '../../components/AppVersion';
import TermsAndConditionCheckbox from '../../components/TermsAndConditions/TermsAndConditionCheckbox';
import BigUp from '../../components/UI';
import BankID from '../../components/UI/BankID/BankID';
import toasters from '../../components/UI/Toasts';
import { useAppSelector } from '../../hooks';
import useFocusNextInput from '../../hooks/useFocusNextInput';
import useLogin from '../../hooks/useLogin';
import storage from '../../storage';
import { capitalize } from '../../tools/capitalizeString';

const Registration: React.FC = () => {
  const login = useLogin();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const router = useIonRouter();
  const bankid = useBankIdContext();
  const { focusNextInput, formRef } = useFocusNextInput();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const device = isDesktop ? 'desktop' : 'mobile';
  const userBankIdData = (location.state as { user?: E2U.V1.Models.User })?.user;

  const methods = useForm({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: {
      ...userBankIdData,
      terms_and_conditions: false,
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    networking.post('/api/v1/users/registration', data)
      .then((result: E2U.V1.Response.Success<{
        token: E2U.V1.Objects.Token,
        user: E2U.V1.Models.User
      }>) => {
        const promises: Promise<any>[] = [];
        promises.push(storage.set('access_token', result.data.data.token.access_token));
        promises.push(storage.set('refresh_token', result.data.data.token.refresh_token));
        promises.push(storage.set('access_token_expires_at', moment().add(result.data.data.token.expires_in, 'seconds').toISOString()));

        Promise.all(promises)
          .then(() => {
            setAxiosAccessToken(result.data.data.token.access_token);
            networking.post(`/api/v1/user`, {})
              .then((result: E2U.V1.Response.Success<E2U.V1.Models.User>) => {
                if (result.data.data.projects.length) {
                  router.push('/');
                } else {
                  router.push('/introduction');
                }
                toasters.createToast({
                  message: t('Welcome to BigUp {name}!', 'Welcome to BigUp {name}!', {
                    name: capitalize(result.data.data.first_name)
                  }),
                  icon: ribbon,
                  background: 'var(--ion-color-light)',
                }, 'success');
              })
              .catch((error) => {
                Sentry.captureException(error);
              });
          });
      })
      .catch((error: E2U.V1.Response.Error<E2U.V1.Models.User>) => {
        if (error.response?.data.message === 'SSN already exists') {
          toasters.createToast({
            message: t('This SSN is already registered. Please login using Bank-ID'),
            icon: warning,
            background: 'var(--ion-color-warning)',
          }, 'info');
        } else {
          const errorData = error.response?.data.data;
          if (errorData) {
            Object.entries(errorData).forEach(([fieldName, fieldErrors]) => {
              if (fieldName === 'backtrace') return;
              const errorMessage = (fieldErrors as string[])[0];

              toasters.createToast({
                message: errorMessage,
                icon: warning,
              }, 'error');

              methods.setError(fieldName as any, {
                type: 'server',
                message: errorMessage
              });
            });
          }
        }
        Sentry.captureException(error);
      });
  };

  const handleRegisterWithBankId = () => {
    history.push({
      pathname: '/login',
      state: { bankid: true }
    });
  };

  const handleNextInput = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key === 'Enter') {
      focusNextInput(e.target as HTMLInputElement);
    }
  };

  useEffect(() => {
    login.clearStorage();
    bankid?.abortBankIdAuthentication();
  }, []);

  return (
    <IonPage>
      <UnauthorizedHeader />
      <IonContent
        scrollY={false}
        fullscreen
        className={`ion-no-padding ion-margin-top ${styles['register-content-background']}`}
      >
        <div className={`${styles['register-form-container']} ${styles[`register-form-container--${device}`]}`}>
          <div className={`${styles['register-form']} ${styles[`register-form--${device}`]}`}>
            <IonToolbar mode="ios" className={`ion-margin-bottom ion-margin-top ${styles['register-toolbar']}`}>
              <IonButtons>
                <IonButton
                  fill={'clear'}
                  className={'ion-no-padding'}
                  onClick={() => {
                    router.push('/login');
                    bankid?.abortBankIdAuthentication();
                  }}
                >
                  <IonIcon slot={'icon-only'} icon={chevronBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>{t('Sign up')}</IonTitle>
            </IonToolbar>

            <IonGrid className='ion-no-padding'>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" sizeSm="9">
                  <IonButton
                    className={styles['bankid-registration-button']}
                    expand="block"
                    shape="round"
                    onClick={handleRegisterWithBankId}
                  >
                    <BankID.BankIdIcon slot="start" color="white" width={40} />
                    {t('Begin BankID registration')}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid className='ion-no-padding'>
              <IonRow>
                <IonCol className='ion-no-padding ion-margin-top'>
                  <BigUp.Seperator.LabeledSeparator label={t('or')} />
                </IonCol>
              </IonRow>
            </IonGrid>

            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className={styles['fade-in']}
                ref={formRef}
              >
                <RegistrationInputs isBankId={userBankIdData} onKeyUp={handleNextInput} />
                <IonGrid >
                  <PasswordHandler isBankId={userBankIdData} onKeyUp={handleNextInput} />
                  <IonRow>
                    <IonCol className='ion-margin-top'>
                      <TermsAndConditionCheckbox />
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow className="ion-justify-content-center ion-margin-bottom">
                    <IonCol sizeXs="10" sizeSm="6">
                      <BigUp.Buttons.Primary
                        expand="block"
                        title={t('Register')}
                        type="submit"
                        disabled={
                          !methods.formState.isValid ||
                          methods.formState.isSubmitting ||
                          !methods.watch('terms_and_conditions')
                        }
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </FormProvider>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export const UnauthorizedHeader: React.FC = () => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  return (
    <>
      {isPlatform('capacitor') && (
        <div className={styles['app-version-container']}>
          <AppVersion />
        </div>
      )}
      <div className={styles['register-page-logo']} style={{ top: isDesktop ? '8px' : '44px', }}>
        <img src={bigUpLogo} alt='BigUp logo' style={{ width: '10vh', height: 'auto' }} />
      </div>
    </>
  );
};
export default Registration;
