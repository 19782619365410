import {
  IonCol,
  IonGrid,
  IonRow,
  useIonRouter
} from '@ionic/react';
import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../api/networking';
import BigUp from '../../components/UI';
import toasters from '../../components/UI/Toasts';
import useAcl from '../../hooks/useAcl';

const ConstructionSiteForm: React.FC = () => {
  const { t } = useTranslation();
  const { project } = useAcl();
  const router = useIonRouter();
  const methods = useForm();

  const handleSubmit = (data: FieldValues) => {
    networking.post(`api/v1/projects/${project?.id}/construction_sites`, data)
      .then(() => {
        toasters.createToast({
          message: t(`Construction site successfully created`),
          background: 'var(--ion-color-light)'
        }, 'success');

        router.push(`/tools/${project?.id}/construction-sites`);
      }).catch(() => {
        toasters.createToast({
          message: t('Something went wrong. Please try again later.'),
          background: 'var(--ion-color-light)'
        }, 'error');
      });
  };

  return (
    <FormProvider {...methods}>
      <form method='post' onSubmit={methods.handleSubmit(handleSubmit)}>

        <IonGrid className='ion-padding ion-margin-top'>
          <IonRow>
            <IonCol className='ion-margin-bottom'>
              <BigUp.Title label={t('Create a new construciton site')} />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-bottom ion-align-items-center'>
            <IonCol size='12' className='ion-padding-start'>
              <BigUp.Input
                label={t('Name')}
                placeholder={t('Name')}
                labelPlacement='stacked'
                register='name'
                type='text'
                inputMode='text'
                validation={{
                  required: {
                    value: true,
                    message: t('Name is required')
                  },
                  maxLength: {
                    value: 255,
                    message: t('Name is too long'),
                  },
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-bottom ion-align-items-center'>
            <IonCol size='12' className='ion-padding-start'>
              <BigUp.Input
                label={t('Identification number')}
                placeholder={t('Identification number')}
                labelPlacement='stacked'
                register='identifier'
                type='text'
                inputMode='text'
                validation={{
                  required: {
                    value: true,
                    message: t('Identification number is required')
                  },
                  maxLength: {
                    value: 13,
                    message: t('Invalid identification number'),
                  },
                  minLength: {
                    value: 13,
                    message: t('Invalid identification number'),
                  },
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className='ion-margin-bottom ion-align-items-center'>
            <IonCol size='12' className='ion-padding-start'>
              <BigUp.Input
                label={t('Description')}
                placeholder={t('Description')}
                labelPlacement='stacked'
                register='description'
                type='text'
                inputMode='text'
                validation={{}}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-start md">
            <IonCol size="auto" className="md">
              <BigUp.Buttons.Primary
                size='default'
                title={t('Save')}
                type='submit' />
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>

    </FormProvider>
  );
};

export default ConstructionSiteForm;
