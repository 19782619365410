import { IonCol, IonGrid, IonRow, useIonRouter, useIonViewDidEnter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add, key } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import PrecalculationListTitle from './PrecalculationListTitle';
import PrecalculationTile from './PrecalculationTile';
import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import BigUp from '../../../../components/UI';
import type { TableProps } from '../../../../components/UI/Table/interfaces';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import useAcl from '../../../../hooks/useAcl';
import i18n from '../../../../i18n';
import { setSelectedPrecalculation } from '../../../../reducers/precalculations';
import store from '../../../../store';
import { formatToNiceDate } from '../../../../tools/formatDates';
import formatNumber from '../../../../tools/formatNumber';

const PrecalculationsList: React.FC = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [lastReload, setLastReload] = useState(new Date());
  const user = useAppSelector((state) => state.authentication.user);

  const { uuid } = useParams<{ uuid: string | undefined }>();
  const methods = useForm();
  const router = useIonRouter();
  const { t } = useTranslation();
  const { project } = useAcl();

  const isLoaded = useMemo(
    () => (project && project?.id) || (user && user?.id),
    [project, user]
  );

  const sourceUrl = useMemo(
    () => project?.id
      ? `/api/v1/projects/${project?.id}/precalculations`
      : `/api/v1/users/${user?.id}/precalculations`,
    [project?.id, user?.id]
  );

  const defaultColumnSizes = {
    xl: '2',
    lg: '4',
    md: '4',
    sm: '4',
    xs: '4',
  };

  const tableProps: TableProps = {
    columns: [
      {
        key: 'name',
        label: t('Name'),
        alignment: 'left',
        body: (<PrecalculationTile attributes={{} as any} />),
        sortable: true,
        sizes: {
          xl: '2',
          lg: '4',
          md: '3',
          sm: '4',
          xs: '5',
        },
      },
      {
        key: 'created_at',
        label: t('Created at'),
        alignment: 'left',
        sortable: true,
        default_sort: true,
        default_sort_direction: 'desc',
        sizes: defaultColumnSizes,
      },
      {
        key: 'secured_costs',
        label: t('Secured costs'),
        sortable: false,
        sizes: defaultColumnSizes,
      },
      {
        key: 'unsecured_costs',
        label: t('Remaining'),
        sortable: false,
        sizes: defaultColumnSizes,
        body: (row: any) => row.secured_costs ? <>{formatNumber(row.total - row.secured_costs)}</> : <>-</>
      },
      {
        key: 'total',
        label: t('Total'),
        sortable: true,
        sizes: defaultColumnSizes,
      },
      {
        key: 'iteration',
        label: t('Versions'),
        sortable: false,
        sizes: defaultColumnSizes,
      },
      {
        key: 'updated_at',
        label: t('Updated at'),
        alignment: 'right',
        sortable: true,
        sizes: defaultColumnSizes,
      },
    ],
    reducers: {
      created_at: (value: string) => formatToNiceDate(value),
      updated_at: (value: string) => formatToNiceDate(value),
      secured_costs: (value: number) => formatNumber(value),
      total: (value: number) => formatNumber(value),
    },
  };

  const handleCreateCalculationModal = () => {
    methods.reset();
    setOpenCreateModal(false);
  };

  const handleCreatePrecalculation = (data: E2U.V1.Models.Precalculation | any) => {
    networking.post('/api/v1/precalculations', {
      ...data,
      owner_id: user?.id,
      project_id: uuid ?? null,
    })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Precalculation>) => {
        store.dispatch(setSelectedPrecalculation(response.data));
        if (uuid) {
          router.push(`/financials/${uuid}/precalculations/${response.data.data.id}`);
        } else {
          router.push(`/financials/precalculations/${response.data.data.id}`);
        }
        setOpenCreateModal(false);
        methods.resetField('name');
      }
      )
      .catch((error) => {
        toasters.createToast({
          message: i18n.t('Error creating precalculation'),
          background: 'var(--ion-color-light)'
        }, 'error');
        Sentry.captureException(error);
      });
  };

  const handleRowClick = (precalculation: E2U.V1.Models.Precalculation) => {
    if (uuid) {
      router.push(`/financials/${uuid}/precalculations/${precalculation.id}`);
      store.dispatch(setSelectedPrecalculation(precalculation));
    } else {
      router.push(`/financials/precalculations/${precalculation.id}`);
      store.dispatch(setSelectedPrecalculation(precalculation));
    }
  };

  return (
    <DesktopWrapper>
      <IonGrid className='ion-margin-bottom'>
        <IonRow>
          <IonCol>
            <BigUp.Title
              label={<PrecalculationListTitle />}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow className={'ion-align-items-center'}>
          <IonCol>
            <BigUp.Label.V2Thick label={t('Precalculations')} />
          </IonCol>
          <IonCol size={'auto'}>
            <BigUp.Buttons.Secondary
              size='small'
              title={t('Create new')}
              icon={{ icon: add }}
              onClick={() => setOpenCreateModal(true)}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      {isLoaded
        ? <BigUp.Table
          timestamp={lastReload}
          sourceUrl={sourceUrl}
          columns={tableProps.columns}
          reducers={tableProps.reducers}
          callbacks={{
            onRowClick: (row: any) => {
              handleRowClick(row);
            }
          }}
        />
        : <SkeletonTextThreeLines />
      }
      <FormProvider {...methods}>
        <BigUp.Modal.InputModal
          textAlignment='center'
          modal={{
            isOpen: openCreateModal,
            onDismiss: handleCreateCalculationModal,
            title: t('Name your calculation'),
            description: t('Give your calculation a clear and descriptive name that reflects its content or purpose.')
          }}
          button={{
            title: t('Create'),
            disabled: methods.formState.isSubmitting || !methods.formState.isValid,
            type: 'submit'
          }}
          input={{
            placeholder: t('Enter a name'),
            register: 'name',
            validation: {
              required: true,
              minLength: 3,
              maxLength: 50
            }
          }}
          onSubmit={methods.handleSubmit(handleCreatePrecalculation)}
        />
      </FormProvider>
    </DesktopWrapper>
  );
};
export default PrecalculationsList;
