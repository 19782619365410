import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  useIonRouter, useIonViewWillLeave
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AclCurtain from '../../../components/AclCurtain';
import DocumentTypeFormModal from '../../../components/DocumentTypeFormModal';
import BigUp from '../../../components/UI';
import { MultiSelectorGroup } from '../../../components/UI/MultiSelector';
import type { MultiSelectorGroupValuesProps, MultiSelectorItemProps } from '../../../components/UI/MultiSelector/interfaces';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../../../hooks';
import type { SourceUrlProps } from '../../../interfaces/SourceUrlProps';
import { formatToNiceDate } from '../../../tools/formatDates';
import CategoryTitleColumn from '../Category/CategoryTitleColumn';
import FilePreviewColumn from '../Columns/FilePreviewColumn';
import { DocumentActivityCodeFilter, DocumentCategoryFilter, DocumentExtensionCodeFilter } from '../Filters';
import { getDocumentRoute } from './documentSwitch';

export const FILTER_TYPES: {
  [key: string]: string
} = {
  categories: 'documents',
  extensions: 'files',
  activity_codes: 'documents'
};

const DocumentsListTable: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const projectId = useMemo(() => selectedProject && selectedProject.id, [selectedProject]);
  const router = useIonRouter();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<MultiSelectorGroupValuesProps>({});
  const [lastTypesFetch, setLastTypesFetch] = useState<number | undefined>(undefined);
  const [createTypeModalOpen, setCreateTypeModalOpen] = useState<boolean>(false);
  const [previousDataSourceUrl, setPreviousDataSourceUrl] = useState<SourceUrlProps | undefined>(undefined);

  const defaultUrl = useMemo(() => {
    if (!projectId) {
      return undefined;
    }
    return {
      url: `/api/v1/projects/${projectId}/document_types`,
      args: {
        per_page: 9999,
        fields: 'id,name,last_document_change_ts,color,system_record_class',
      },
    };
  }, [projectId]);

  const parseBaseFilterChange = (
    type: 'documents_filters' | 'file_filters',
    existingFilters: { field: string, value: any }[],
    filterKey: string,
    filter: MultiSelectorItemProps[]
  ) => {
    const existingFilterParentIndex = existingFilters.findIndex(
      parent => parent.field === type
    );
    if (existingFilterParentIndex === -1) {
      existingFilters.push({
        field: type,
        value: {
          [filterKey]: filter.join(',')
        }
      });
      return existingFilters;
    }
    existingFilters[existingFilterParentIndex].value = {
      ...(existingFilters[existingFilterParentIndex]?.value ?? {}),
      [filterKey]: filter.join(',')
    };
    return existingFilters;
  };

  const parseDocumentFilter = (
    existingFilters: { field: string, value: any }[],
    filterKey: string,
    filter: any|any[]
  ) => {
    return parseBaseFilterChange(
      'documents_filters',
      existingFilters,
      filterKey,
      Array.isArray(filter) ? filter : [filter]
    );
  };

  const parseFileFilter = (
    existingFilters: { field: string, value: any }[],
    filterKey: string,
    filter: any|any[]
  ) => {
    return parseBaseFilterChange(
      'file_filters',
      existingFilters,
      filterKey,
      Array.isArray(filter) ? filter : [filter]
    );
  };

  const resetSearchFilters = (
    existingFilters: { field: string, value: any }[]
  ) => {
    return existingFilters.map(
      filter => {
        if (filter.field === 'documents_filters' || filter.field === 'file_filters') {
          return {
            ...filter,
            value: {}
          };
        }
        return filter;
      }
    );
  };

  const parseFilterArgs = (args: any, filterKeys: string[], filters: MultiSelectorGroupValuesProps) => {
    if (typeof args.filters === 'string') {
      args.filters = JSON.parse(args.filters) ?? [];
    }
    args.filters = resetSearchFilters(args?.filters ?? []);
    filterKeys.forEach(
      filterKey => {
        args.filters = FILTER_TYPES[filterKey] === 'documents'
          ? parseDocumentFilter(args.filters, filterKey, filters[filterKey])
          : parseFileFilter(args.filters, filterKey, filters[filterKey]);
      }
    );
    if (!args.filters) {
      args.filters = [];
    }
    return args;
  };

  const dataSourceUrl = useMemo(() => {
    if (!projectId) {
      return undefined;
    }

    let args: {[argKey: string]: any} = { search: searchQuery };
    if (
      previousDataSourceUrl !== undefined &&
      typeof previousDataSourceUrl === 'object' &&
      typeof previousDataSourceUrl.args !== 'undefined'
    ) {
      args = { ...previousDataSourceUrl.args, ...args };
    }

    const activeFilterKeys = Object.keys(activeFilters);
    if (activeFilterKeys.length) {
      args = parseFilterArgs(args, activeFilterKeys, activeFilters);
    }

    if (args.filters) {
      args.filters = JSON.stringify(args.filters);
    }

    return (searchQuery.length || Object.keys(activeFilters).length)
      ? {
        args: {
          ...args,
          fields: 'id,name,type,filetype,export_url',
        },
        url: `/api/v1/projects/${projectId}/documents/files`,
      }
      : defaultUrl;
  }, [searchQuery, previousDataSourceUrl, activeFilters, projectId, defaultUrl]);

  const handleSearch = (query: string, currentUrl: SourceUrlProps | string) => {
    if (!query.length) {
      if (!searchQuery.length) {
        return;
      }

      setSearchQuery(query);
    } else {
      setSearchQuery(query);
    }

    if (typeof currentUrl === 'string') {
      setPreviousDataSourceUrl(undefined);
      return;
    }
    setPreviousDataSourceUrl(currentUrl);
  };

  const handleTypeSaved = () => {
    setLastTypesFetch(Date.now());
    setCreateTypeModalOpen(false);
  };

  useIonViewWillLeave(() => {
    setSearchQuery('');
    setActiveFilters({});
  });

  return (
    <SideMenuV2Layout title={t('Documents')} showDocumentActionsButton={true}>
      <IonGrid>
        <IonRow className={'ion-wrap-reverse'}>
          <IonCol>
            <IonGrid>
              {isDesktop && (
                <IonRow>
                  <IonCol>
                    <BigUp.Title label={t('Documents')} />
                  </IonCol>
                </IonRow>
              )}
              <IonRow className={'ion-align-items-center ion-justify-content-between'}>
                <IonCol>
                  <BigUp.Label.V2Thick label={t('Categories')} />
                </IonCol>
                <AclCurtain requiredAbilities={['can_view_document_types_in_projects', 'can_update_document_types_in_projects']}>
                  <IonCol size='auto'>
                    <IonButton onClick={() => router.push(`/tools/${selectedProject?.id}/settings/document-types`)} fill='clear'>
                      <IonIcon slot='icon-only' icon={BigUp.Icons.Svg.Settings} />
                    </IonButton>
                  </IonCol>
                </AclCurtain>
              </IonRow>
            </IonGrid>
            {(dataSourceUrl) && <BigUp.Table
              sourceUrl={dataSourceUrl}
              columns={
                [{
                  key: 'name',
                  label: t('Name'),
                  alignment: 'left',
                  sizes: {
                    xs: '7',
                    sm: '7',
                    md: '8',
                    lg: '9',
                    xl: '9'
                  },
                  sortable: true,
                  body: (row: E2U.V1.Models.Type | E2U.V1.Models.File) => {
                    return 'filetype' in row
                      ? <FilePreviewColumn attributes={row} />
                      : <CategoryTitleColumn />;
                  }
                }, {
                  key: 'last_document_change_ts',
                  label: t('Last updated'),
                  alignment: 'right',
                  sizes: {
                    xs: '5',
                    sm: '5',
                    md: '4',
                    lg: '3',
                    xl: '3'
                  }
                }]
              }
              filtersCustom={
                <MultiSelectorGroup callbacks={{
                  onSelectionChange: (selection: MultiSelectorGroupValuesProps) => {
                    setPreviousDataSourceUrl(defaultUrl);

                    const filters = Object.entries(selection).reduce((acc, [key, value]) => {
                      return {
                        ...acc,
                        [key]: value.join(','),
                      };
                    }, {});

                    setActiveFilters(filters);
                  }
                }}>
                  <DocumentCategoryFilter sourceUrl={{
                    url: `/api/v1/projects/${projectId}/documents/filters/categories`,
                    args: {
                      'others[search]': searchQuery
                    },
                  }} />
                  <DocumentActivityCodeFilter sourceUrl={{
                    url: `/api/v1/projects/${projectId}/documents/filters/activity_codes`,
                    args: {
                      'others[search]': searchQuery
                    },
                  }} />
                  <DocumentExtensionCodeFilter sourceUrl={{
                    url: `/api/v1/projects/${projectId}/documents/filters/extensions`,
                    args: {
                      'others[search]': searchQuery
                    },
                  }} />
                </MultiSelectorGroup>
              }
              reducers={{
                last_document_change_ts: (value: string) => formatToNiceDate(value),
              }}
              callbacks={{
                onSearch: handleSearch,
                onRenderComplete: (rows: E2U.V1.Models.Type[] | E2U.V1.Models.File[], setRows) => {
                  if (rows && rows.length && 'filename' in rows[0]) {
                    setRows(rows);
                  } else {
                    if (searchQuery.length || Object.keys(activeFilters).length) {
                      setRows(rows);
                    } else {
                      setRows([{
                        id: 'uncategorized',
                        name: t('Uncategorized'),
                      },
                      ...rows]);
                    }
                  }
                },
                onRowClick: (row: E2U.V1.Models.Type | E2U.V1.Models.File) => {
                  if ('filetype' in row) {
                    router.push(`/tools/${projectId}/file/${row.id}`);
                  } else {
                    router.push(`/tools/${projectId}/documents/category/${row.id}`);
                  }
                },
              }}
              timestamp={lastTypesFetch}
            />}

            <DocumentTypeFormModal
              open={createTypeModalOpen}
              onClose={() => setCreateTypeModalOpen(false)}
              onSaved={() => handleTypeSaved()}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </SideMenuV2Layout>
  );
};

export default DocumentsListTable;
