import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { generateIcon } from './helpers';
import type { MultiSelectorItemProps } from './interfaces';
import styles from './MultiSelector.module.scss';
import Contact from '../../../pages/EmergencyContacts/ContactTile';

const MultiSelectorItem: React.FC<React.PropsWithChildren<any> & {
  showAvatar?: boolean;
  item: MultiSelectorItemProps;
  selectedItems: MultiSelectorItemProps[] | [];
  toggleValue: (index: number) => void;
}> = (props) => {
  const { item, showAvatar } = props;
  const isChecked = useMemo(() => props.selectedItems.includes(item.value), [props.selectedItems, item.value]);
  const { t } = useTranslation();

  const isDisabled = useMemo(() => {
    return item.children?.length
      ? item.children.every((child: MultiSelectorItemProps) => {
        return child?.disabled || false;
      })
      : item?.disabled;
  }, [item, isChecked]);

  const icon = useMemo(() => generateIcon({
    icon: item.icon,
    className: styles.icon
  }), [item.icon]);

  return (
    <IonItem>
      {showAvatar && (
        <Contact
          attributes={{ user_id: item?.value ?? '' }}
          hideName={true}
          className={'ion-margin-start'}
        />
      )}
      <IonCheckbox
        value={item.value}
        labelPlacement="start"
        disabled={isDisabled}
        checked={isChecked && !isDisabled}
        onIonChange={() => props.toggleValue(item.value)}>
        {props.children
          ? props.children
          : (
            <>
              {icon && icon}
              <IonLabel>
                {item.label}
                {(typeof item.count !== 'undefined') && (
                  <small className={'ion-margin-start'}>
                    {t('{count} matching', {
                      count: item.count
                    })}
                  </small>
                )}
              </IonLabel>
            </>
          )}
      </IonCheckbox>
    </IonItem>
  );
};

export default MultiSelectorItem;
