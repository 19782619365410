import { Browser } from '@capacitor/browser';
import {
  IonAccordion,
  IonAccordionGroup,
  IonBackButton,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSegmentContent,
  IonSegmentView,
  IonText,
  useIonAlert,
  useIonPopover,
  useIonRouter,
  useIonViewWillLeave
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { chatbox, ellipsisHorizontal } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { getDocumentTypeColor } from './Category/CategoryTitleColumn';
import styles from './Document.module.scss';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../components/SkeletonComponents/SkeletonTextThreeLines';
import Toast from '../../components/Toasts/Toast';
import BigUp from '../../components/UI';
import FilterChips from '../../components/UI/Chips/FilterChips';
import EmptyList from '../../components/UI/EmptyList';
import type { PopoverItem } from '../../components/UI/Popovers';
import toasters from '../../components/UI/Toasts';
import { useAppSelector } from '../../hooks';
import useFilePreviews from '../../hooks/useFilePreview';
import i18n from '../../i18n';
import { setSelectedFile } from '../../reducers/file';
import { setForceReloadDocument } from '../../reducers/files';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';
import { formatToNiceDate } from '../../tools/formatDates';
import ActionColumn from '../Financials/Subpanels/Columns/ActionColumn';

const DocumentPage: React.FC = () => {
  const { t } = useTranslation();
  const document: (E2U.V1.Models.Document & {
    activity_codes: E2U.V1.Models.ActivityCode[]
  }) | undefined = useAppSelector((state) => state.file.selectedFile);

  const defaultAccordionValues = useMemo(() => [
    `document-${document?.id}-details`,
    `document-${document?.id}-activity-codes-and-tag`,
    `document-${document?.id}-files-and-activity`
  ], [document?.id]);

  const [lastTimestamp, setLastTimestamp] = useState<number>(Date.now());
  const [currentlyOpenedAccordions, setCurrentlyOpenedAccordions] = useState<string[]>([]);
  const { documentUuid } = useParams<{ documentUuid: string | undefined }>();
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { fileUrls, loadingPreview } = useFilePreviews(document?.files);
  const isLoadingDocument: boolean = useAppSelector((state) => state.loading.isLoading.document);
  const forceReloadDocument = useAppSelector(state => state.fileSelection.forceReloadDocument);
  const selectedProject = useAppSelector(state => state.project.selectedProject);

  const documentId = document?.id;

  const filteredRelatedTags = useMemo(
    () => document?.related_tags?.filter(
      (tag) => !document.categories?.find(
        (category) => category.id === tag.id)
    ) ?? [],
    [document]
  );

  const fetchSelectedDocument = () => {
    if (typeof documentUuid === 'undefined' || documentUuid === '') {
      if (router.canGoBack()) {
        router.goBack();
      } else {
        router.push('/tools');
      }
      return;
    }
    store.dispatch(setIsLoading({ name: 'document', value: true }));
    const queryParams = new URLSearchParams();
    queryParams.append('direction', 'desc');
    queryParams.append('with[]', 'files');
    queryParams.append('with[]', 'activityCodes');
    queryParams.append('with[]', 'relatedTags');
    networking.get(`/api/v1/documents/${documentUuid}?${queryParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
        store.dispatch(setSelectedFile(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
        Toast(t('Document not found'), 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'document', value: false }));
      });
  };

  const handleDeleteButtonClicked = () => {
    presentAlert({
      header: i18n.t('Are you sure you want to delete this document?'),
      message: document?.name,
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: i18n.t('Delete'),
          role: 'confirm',
          handler: () => {
            deleteSelectedFile();
          }
        }
      ]
    });
  };

  const deleteSelectedFile = () => {
    store.dispatch(setIsLoading({ name: 'deletingFile', value: true }));
    networking.delete(`/api/v1/documents/${documentUuid}`)
      .then(() => {
        store.dispatch(setSelectedFile(undefined));
        Toast(t('Document deleted!'), 'success');
        router.canGoBack() ? router.goBack() : router.push('/tools');
      })
      .catch(() => {
        Toast(t('Could not delete document {name}', 'Could not delete document {name}', { name: document?.name }), 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'deletingFile', value: false }));
      });
  };

  const documentOptions: PopoverItem[] = [
    {
      value: 'share',
      label: t('Share'),
      onClick: () => {
        alert('share');
      }
    },
    {
      value: 'export',
      label: t('Share file'),
      onClick: () => {
        alert('export');
      }
    },
    {
      value: 'edit',
      label: t('Edit document'),
      onClick: () => {
        router.push(`/tools/${selectedProject?.id}/documents/${documentId}/edit`);
      }
    },
    {
      value: 'delete',
      label: t('Delete document'),
      onClick: () => {
        handleDeleteButtonClicked();
      }
    }
  ];

  const [presentOptions] = useIonPopover(BigUp.Popovers.Default, {
    items: documentOptions,
    mode: 'md',
  });

  const showPopover = (present: (options: any) => void, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    present({
      event: e as any,
      mode: 'md',
      dismissOnSelect: true,
    });
  };

  const handleDetachFile = (id: string, name: string) => {
    toasters.promiseToast(
      networking.delete(`/api/v1/files/${id}`),
      {
        pending: {
          message: t('Detaching file {name}', { name })
        },
        success: {
          message: t('File detached successfully')
        },
        error: {
          message: t('Failed to detach file {name}', { name })
        }
      }
    )
      .then(() => {
        setLastTimestamp(Date.now());
      })
      .catch((err) => {
        Sentry.captureException(err);
        Toast(t('Failed to detach file {name}', { name }), 'error');
      });
  };

  const handleOpenFile = (file: E2U.V1.Models.File) => {
    router.push(`/tools/${selectedProject?.id}/file/${file.id}`);
  };

  useEffect(() => {
    if (forceReloadDocument) {
      fetchSelectedDocument();
      store.dispatch(setForceReloadDocument(false));
    }
  }, [forceReloadDocument]);

  useEffect(() => {
    fetchSelectedDocument();

    const savedAccordionStates = localStorage.getItem('documentAccordionStates');
    if (savedAccordionStates) {
      setCurrentlyOpenedAccordions(JSON.parse(savedAccordionStates));
    }
  }, []);

  useEffect(() => {
    if (documentId && !currentlyOpenedAccordions.length) {
      setCurrentlyOpenedAccordions(defaultAccordionValues);
    }
  }, [documentId, currentlyOpenedAccordions]);

  useEffect(() => {
    localStorage.setItem('documentAccordionStates', JSON.stringify(currentlyOpenedAccordions));
  }, [currentlyOpenedAccordions]);

  return (
    <DesktopWrapper>
      {isLoadingDocument
        ? <SkeletonTextThreeLines />
        : <>
          <IonGrid className={'ion-no-padding ion-margin-vertical'}>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <IonCol size='auto'>
                <IonBackButton text={''} defaultHref='/tools' />
              </IonCol>
              <IonCol size='auto'>
                <IonButton fill='clear' onClick={(e) => showPopover(presentOptions, e)}>
                  <IonIcon color='medium' slot='icon-only' icon={ellipsisHorizontal} />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonAccordionGroup multiple={true} value={currentlyOpenedAccordions}
            onIonChange={(e: CustomEvent) => {
              const values = e.detail.value;

              if (values.length) {
                if (typeof values === 'string') {
                  // save tab values also?
                } else if (values.every((value: string) => value.indexOf('tab-') === -1)) {
                  setCurrentlyOpenedAccordions(values);
                }
              }
            }}>
            <IonAccordion value={`document-${document?.id}-details`}>
              <DocumentHeader title={document?.name ?? ''} size='large' />
              <IonGrid slot='content' className={`${styles['document-details']}`}>
                <IonRow className={`ion-justify-content-start ion-align-items-center ${styles['document-category']}`}>
                  <IonCol size={'auto'}>
                    <BigUp.Label.Regular label={t('Categories: ')} className='ion-no-margin' />
                  </IonCol>
                  {
                    (document?.categories && document?.categories?.length)
                      ? document.categories.map((category, i) => (
                        <IonCol size={'auto'} key={i}>
                          <div className={styles['document-category--widget']}>
                            <IonIcon icon={getDocumentTypeColor(category.color ?? 'grey')} size='small' />
                            <p className='ion-no-margin'>{category.name}</p>
                          </div>
                        </IonCol>
                      ))
                      : (
                        <IonCol size={'auto'}>
                          <i><BigUp.Label.Regular label={t('none selected')} className='ion-no-margin' /></i>
                        </IonCol>
                      )
                  }
                </IonRow>
                <IonRow className='ion-margin-vertical'>
                  <IonCol>
                    <span className={styles['document-category--description']}>
                      {document?.description}
                    </span>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonAccordion>

            <IonAccordion value={`document-${document?.id}-activity-codes-and-tag`}>
              <DocumentHeader title={t('Activity codes & tags')} size='large' />
              <IonGrid slot='content' className={`${styles['document-tag-and-activity']}`}>
                <IonRow className={'ion-margin-bottom'}>
                  {(document && document.activity_codes && document.activity_codes.length > 0) && (
                    <FilterChips
                      data={document.activity_codes}
                      renderChipLabel={(code: E2U.V1.Models.ActivityCode) => `${code.code} ${code.name}`}
                    />
                  )}
                  {(filteredRelatedTags.length > 0) && (
                    <FilterChips data={filteredRelatedTags} renderChipLabel={(tag: E2U.V1.Models.Tag) => tag.name} />
                  )}
                  {(
                    !document ||
                    ((!document.activity_codes || document.activity_codes.length === 0) &&
                      (filteredRelatedTags.length === 0))
                  ) &&
                    (
                      <IonLabel className={`${styles['empty-section--label']}`}>
                        {t('No activity codes or tags added to {document}', { document: document?.name })}
                      </IonLabel>
                    )}
                </IonRow>

              </IonGrid>
            </IonAccordion>
            <IonAccordion value={`document-${document?.id}-files-and-activity`}>
              <DocumentHeader title={t('Files & activity')} size='large' />
              <IonGrid slot={'content'} className={`${styles['document-detail-grids']}`}>
                <IonRow className='ion-margin-vertical ion-justify-content-start'>
                  <IonCol size='auto'>
                    <IonSegment
                      mode='md'
                      className={styles['segment-wrapper']}
                      color={'dark'}

                    >
                      <IonSegmentButton value={'tab-files'} contentId='tab-files' className={styles['segment-button']}>
                        <IonLabel className='ion-no-margin'>{t('Files')}</IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value={'tab-document-activity-section'}
                        contentId='tab-document-activity-section'
                        className={styles['segment-button']}>
                        <IonLabel className='ion-no-margin'>{t('Activity')}</IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                    <IonSegmentView color='none' style={{ background: 'var(--color-white)', borderRadius: '20px' }}>
                      <IonSegmentContent id='tab-files'>
                        <BigUp.Table
                          timestamp={lastTimestamp}
                          rows={document?.files}
                          callbacks={{
                            onRowClick: (row: E2U.V1.Models.File) => handleOpenFile(row)
                          }}
                          columns={
                            [{
                              key: 'type',
                              label: t('Name'),
                              alignment: 'left',
                              sortable: true,
                              sizes: {
                                xs: '7',
                                sm: '5',
                                md: '6',
                                lg: '6',
                                xl: '6'
                              },
                              body: (rowData: E2U.V1.Models.File) => (
                                <BigUp.Preview.PreviewItem
                                  lines='none'
                                  fileName={rowData.name}
                                  fileType={rowData.type}
                                  previewLoading={loadingPreview}
                                  fileUrl={fileUrls[rowData.id as string]}
                                />
                              ),
                            }, {

                              key: 'date',
                              label: t('Date'),
                              alignment: 'left',
                              body: (rowData) => (
                                <div className={styles['column-container--date']}>
                                  {formatToNiceDate(rowData.created_at)}
                                </div>
                              )
                            }, {
                              key: 'actions',
                              label: t('Actions'),
                              sortable: false,
                              body: () => (
                                <div className={styles['column-container--actions']}>
                                  <ActionColumn
                                    type={'files'}
                                    routePrefix={'/settings'}
                                    typeLabel={t('Files')}
                                    attributes={{} as E2U.V1.Models.File}
                                    callbacks={{
                                      onDetails: (row: E2U.V1.Models.File) => handleOpenFile(row),
                                      onDelete: (id: string, name?: string) => handleDetachFile(id, name ?? ''),
                                    }}
                                    hideShare={true}
                                  />
                                </div>
                              ),
                              sizes: {
                                xs: '3',
                                sm: '2',
                                md: '2',
                                lg: '2',
                                xl: '2'
                              },
                              alignment: 'right',
                            }]
                          }
                        />
                      </IonSegmentContent>
                      <IonSegmentContent color='none' id={'tab-document-activity-section'}>
                        <EmptyList
                          icon={{
                            icon: chatbox,
                            size: 100,
                          }}
                          title={t('Coming soon!')}
                          message={t('This feature is not yet available. Check back soon for updates.')}
                        />
                      </IonSegmentContent>
                    </IonSegmentView>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonAccordion>

          </IonAccordionGroup>
        </>
      }
    </DesktopWrapper>
  );
};

interface DocumentHeaderProps {
  title: string;
  size: 'small' | 'large' | undefined;
}

const DocumentHeader: React.FC<DocumentHeaderProps> = (props) => {
  return (
    <IonItem slot={'header'} className={`ion-no-padding ${styles['document-accordion-header']}`}>
      <IonText >
        <BigUp.Title size={props.size} label={props.title} />
      </IonText>
    </IonItem>
  );
};

export default DocumentPage;
