import { IonAccordion, IonAccordionGroup, IonChip, IonCol, IonGrid, IonIcon, IonItem, IonList, IonRow, IonText, useIonViewWillEnter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { ActivityCode } from '@techlove/easy2use-typings/src/V1/Models/ActivityCode';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styles from './PreparationDetails.module.scss';
import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import { formatToNiceDate } from '../../../tools/formatDates';
import Contact from '../../EmergencyContacts/ContactTile';

const accordionItemStyles = {
  '--background': 'var(--color-white)',
};

const PreparationDetails: React.FC = () => {
  const [protocol, setProtocol] = useState<any>();
  const [createdBy, setCreatedBy] = useState<E2U.V1.Models.User>();
  const [project, setProject] = useState<E2U.V1.Models.Project>();
  const [assigneeIds, setAssigneeIds] = useState<string[]>([]);

  const { preparationId } = useParams<{ preparationId: string }>();
  const { t } = useTranslation();

  const getProtocolWithSections = () => {
    networking.get(`api/v1/work_preparations/protocols/${preparationId}/sorted_entries`)
      .then((protocolResponse) => {
        setProtocol(protocolResponse.data.data);
        return networking.get(`api/v1/users/${protocolResponse.data.data.created_by_id}`);
      })
      .then((userResponse) => {
        setCreatedBy(userResponse.data.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const getProject = () => {
    networking.get(`api/v1/projects/${protocol.project_id}`)
      .then((projectResponse) => {
        setProject(projectResponse.data.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const getProtocolAssignees = () => {
    networking.get(`api/v1/work_preparations/protocols/${preparationId}/assignees?fields=id&limit=9999`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<{ id: string }>>) => {
        setAssigneeIds(response.data.data.records.map((assignee) => assignee.id));
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  useIonViewWillEnter(() => {
    getProtocolWithSections();
    getProtocolAssignees();
  }, [preparationId]);

  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  useEffect(() => {
    if (protocol) {
      getProject();
    }
  }, [protocol]);

  useEffect(() => {
    if (!accordionGroup.current) {
      return;
    }
    accordionGroup.current.value = ['information-accordion'];
  }, []);

  return (
    <>
      <div className={styles['protocol-details-container']}>
        <IonGrid className='ion-padding'>
          <IonRow>
            <IonCol>
              <BigUp.Title label={t('Work preparation details')} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonAccordionGroup expand='inset' ref={accordionGroup} mode='md'>
          <IonAccordion value={'information-accordion'}>
            <IonItem slot={'header'} style={accordionItemStyles} lines='full'>
              <BigUp.Label.Thick className='ion-no-margin' color={'medium'} label={t('Information')}></BigUp.Label.Thick>
            </IonItem>
            <div style={{ background: 'var(--color-white)' }} slot={'content'} className='ion-padding-bottom'>
              <IonList>
                <IonItem lines='none'>
                  <IonText className='ion-margin-bottom'>
                    <BigUp.Label.Thick color={'medium'} label={t('Created by:')}></BigUp.Label.Thick>
                    <BigUp.Label.Regular className='ion-no-margin' label={`${createdBy?.first_name} ${createdBy?.last_name}`}></BigUp.Label.Regular>
                  </IonText>
                </IonItem>

                <IonItem lines='none'>
                  <IonText className='ion-margin-bottom'>
                    <BigUp.Label.Thick color={'medium'} label={t('Created at:')}></BigUp.Label.Thick>
                    <BigUp.Label.Regular className='ion-no-margin' label={formatToNiceDate(protocol?.created_at)} />
                  </IonText>
                </IonItem>

                <IonItem lines='none'>
                  <IonText className='ion-margin-bottom'>
                    <BigUp.Label.Thick color={'medium'} label={t('Work operation')}></BigUp.Label.Thick>
                    <BigUp.Label.Regular className='ion-no-margin' label={protocol?.work_operation ?? t('No work operation added')}></BigUp.Label.Regular>
                  </IonText>
                </IonItem>

                <IonItem lines='none'>
                  <IonText className='ion-margin-bottom'>
                    <BigUp.Label.Thick color={'medium'} label={t('Project')}></BigUp.Label.Thick>
                    <BigUp.Label.Regular className='ion-no-margin' label={project?.name}></BigUp.Label.Regular>
                  </IonText>
                </IonItem>
              </IonList>

              <IonItem lines='none'>
                <IonText>
                  <BigUp.Label.Thick color={'medium'} label={t('Contact person')}></BigUp.Label.Thick>
                  {(protocol && protocol.contact_person_id)
                    ? (
                      <Contact
                        attributes={{ user_id: protocol.contact_person_id }}
                      />
                    )
                    : (
                      <BigUp.Label.Regular className='ion-no-margin' label={t('No contact person added')} />
                    )}
                </IonText>
              </IonItem>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
        <IonAccordionGroup expand='inset' mode='md'>
          <IonAccordion value={'activity-code-accordion'}>
            <IonItem slot={'header'} style={accordionItemStyles} lines='full'>
              <BigUp.Label.Thick className='ion-no-margin' color={'medium'} label={t('Activity codes')}></BigUp.Label.Thick>
            </IonItem>
            <div style={{ background: 'var(--color-white)' }} slot={'content'} className='ion-padding-bottom'>
              <IonItem style={accordionItemStyles} lines='none'>
                {protocol?.activity_codes?.length === 0 && t('No activity codes added')}
                <div
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  {protocol?.activity_codes?.map((code: ActivityCode) => (
                    <IonChip key={code.id} outline={true}>
                      {code.code} {code.name}
                    </IonChip>
                  ))}
                </div>
              </IonItem>
            </div>
          </IonAccordion>
        </IonAccordionGroup>

        {(assigneeIds && assigneeIds.length > 0) && (
          <IonAccordionGroup expand='inset' mode='md'>
            <IonAccordion value={'assignee-accordion'}>
              <IonItem slot={'header'} style={accordionItemStyles} lines='full'>
                <BigUp.Label.Thick className='ion-no-margin' color={'medium'} label={t('Participants')}></BigUp.Label.Thick>
              </IonItem>
              <div style={{ background: 'var(--color-white)' }} slot={'content'} className='ion-padding-bottom'>
                {assigneeIds.map((assigneeId) => (
                  <IonItem style={accordionItemStyles} key={assigneeId} lines={'none'}>
                    <IonText>
                      <Contact attributes={{ user_id: assigneeId }} key={assigneeId} />
                    </IonText>
                  </IonItem>
                ))}
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        )}

        {(protocol && protocol.description) && (
          <IonAccordionGroup expand='inset' mode='md'>
            <IonAccordion value='description-accordion' >
              <IonItem slot={'header'} style={accordionItemStyles} lines='full'>
                <BigUp.Label.Thick
                  className='ion-no-margin'
                  color={'medium'}
                  label={t('Description')}
                />
              </IonItem>
              <div style={{ background: 'var(--color-white)' }} slot={'content'} className='ion-padding-bottom'>
                <IonItem style={accordionItemStyles} lines='none'>
                  <IonText>
                    <span className='ion-no-margin' >
                      {protocol.description}
                    </span>
                  </IonText>
                </IonItem>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        )}
        {protocol && protocol.sections.map((section: any, i: number) => (
          <>
            <IonAccordionGroup expand='inset' mode='md'>
              <IonAccordion value={section.name}>
                <IonItem slot={'header'} style={accordionItemStyles} lines='full' >
                  <BigUp.Label.Thick
                    label={section.name}
                    className='ion-no-margin'
                    color={'medium'}
                  />
                </IonItem>
                <div
                  slot='content'
                  style={{ background: 'var(--color-white)' }}
                >
                  <IonList className='ion-no-padding' >
                    {(section.categories && section.categories.length > 0) &&
                      section.categories.map((category: any, index: number) => {
                        return (
                          <>
                            {(!category.entries || category.entries.length === 0) && (
                              <IonItem key={index} style={accordionItemStyles} className=' ion-no-padding' lines='none'>
                                <IonText>
                                  <BigUp.Label.Regular
                                    className='ion-no-margin'
                                    label={t('No entries for this category')}
                                  />
                                </IonText>
                              </IonItem>
                            )}
                            {category.entries.map((row: any, index: number) => {
                              return (<>
                                <IonItem key={index} className='ion-no-padding' lines='none'>
                                  <IonIcon slot='start'
                                    icon={row.active ? checkmarkCircleOutline : closeCircleOutline}
                                    color={row.active ? 'success' : 'danger'}
                                    size="large"
                                    className='ion-margin-start'
                                  />
                                  <IonText>
                                    <BigUp.Label.Regular className='ion-no-margin' label={row.row_name} />
                                  </IonText>
                                </IonItem>
                                {row.reports.map((report: any, index: number) => {
                                  return (
                                    <>
                                      {report.description && (
                                        <IonItem key={index} className=' ion-margin-start' lines='none'>
                                          <IonText>
                                            <BigUp.Label.Thick className='ion-no-margin' color={'medium'} label={t('Description')} />
                                            <BigUp.Label.Regular className='ion-no-margin' label={report.description} />
                                          </IonText>
                                        </IonItem>
                                      )}

                                      {(report.files.length !== 0) &&
                                        (report.files.map((file: any, index: number) => {
                                          return (
                                            <IonItem key={index} className=' ion-no-padding' lines='none'>
                                              <IonText>
                                                <BigUp.Label.Thick className='ion-no-margin' color={'medium'} label={t('Files')} />
                                                <div key={index} className=' ion-no-padding'>
                                                  <BigUp.Label.Regular className='ion-no-margin' label={file.name} />
                                                </div>
                                              </IonText>
                                            </IonItem>
                                          );
                                        })
                                        )}
                                    </>
                                  );
                                })}
                              </>
                              );
                            })}
                          </>
                        );
                      })}
                  </IonList>
                </div>
              </IonAccordion>
            </IonAccordionGroup>

            {(!section.categories || section.categories.length === 0) && (
              <div className={`${styles['protocol-list-section']}`} key={i} >
                <IonList className='ion-no-padding ion-padding-horizontal'>
                  <IonItem className='ion-no-padding' lines='none'>
                    <IonText>
                      <BigUp.Label.Regular className='ion-no-margin' label={t('No categories for this section')}></BigUp.Label.Regular>
                    </IonText>
                  </IonItem>
                </IonList>
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default PreparationDetails;
