import { useIonRouter } from '@ionic/react';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import toasters from '../../../components/Toasts/Toasts';
import { useAppSelector } from '../../../hooks';
import ActionColumn from '../../../pages/Financials/Subpanels/Columns/ActionColumn';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import { formatToNiceDate } from '../../../tools/formatDates';
import BigUp from '../../UI';

interface CategoriesSettingPageProps {
  type: 'projects' | 'clients';
  categories: any[];
  onCategoryChange: () => void;
}

const CategoriesList: React.FC<CategoriesSettingPageProps> = ({ categories, onCategoryChange, type }) => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: ''
    }
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { client_id, section_id } = useParams<{ client_id: string, section_id: string }>();
  const id = useMemo(() => type === 'projects' ? selectedProjectId : client_id, [
    selectedProjectId,
    type,
    client_id
  ]);
  const router = useIonRouter();

  const doDeleteCategory = (category: any) => {
    networking.delete(`/api/v1/work_preparations/categories/${category}`)
      .then(() => {
        onCategoryChange();
        toasters.success(t('Category successfully removed'));
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to remove category.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'deletingCategory',
          value: false
        }));
      });
  };

  const doRenameCategory = (data: any) => {
    store.dispatch(setIsLoading({
      name: 'renamingCategory',
      value: true
    }));
    networking.put(`/api/v1/work_preparations/categories/${categoryId}`, {
      name: data.name
    })
      .then(() => {
        onCategoryChange();
        toasters.success(t('Category successfully renamed'));
      })
      .catch(() => {
        toasters.error(t('Failed to rename category. Please try again later or contact our support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'renamingCategory',
          value: false
        }));
      });
  };

  const onEdit = (category: any) => {
    setModalOpen(true);
    methods.setValue('name', category?.name ?? '');
    setCategoryId(category.id);
  };

  const navigateCategory = (category: any) => {
    if (type === 'projects') {
      router.push(`/tools/${id}/settings/work-preparation/category/${category.id}`);
    } else {
      router.push(`/clients/${id}/settings/work-preparation/category/${category.id}`);
    }
  };

  return (
    <>
      <BigUp.Table
        sourceUrl={`/api/v1/work_preparations/sections/${section_id}/categories?limit=9999`}
        reducers={{
          created_at: (value: string) => formatToNiceDate(value)
        }}
        callbacks={{
          onRowClick: (row: any) => navigateCategory(row)
        }}
        columns={[
          {
            key: 'name',
            label: t('Name'),
            alignment: 'left',
            sortable: true,
            sizes: {
              xs: '6',
              sm: '6',
              md: '6',
              lg: '6',
              xl: '6'
            },
          }, {
            key: 'created_at',
            label: t('Created at'),
            alignment: 'left',
            sortable: true,
            sizes: {
              xs: '4',
              sm: '4',
              md: '4',
              lg: '4',
              xl: '4'
            },

          }, {
            key: 'actions',
            label: t('Actions'),
            sortable: false,
            body: <ActionColumn
              type="template"
              attributes={{} as any}
              callbacks={{
                onDetails: (row: any) => navigateCategory(row),
                onEdit: (row: any) => onEdit(row),
                onDelete: (row: any) => doDeleteCategory(row)
              }}
            />,
            sizes: {
              xs: '3',
              sm: '2',
              md: '2',
              lg: '2',
              xl: '2'
            },
            alignment: 'right'
          }
        ]}
      />
      <FormProvider {...methods}>
        <BigUp.Modal.InputModal
          maxHeight='230px'
          modal={{
            isOpen: modalOpen,
            onDismiss: () => setModalOpen(false),
            title: t('Rename category'),
          }}
          button={{
            type: 'submit',
            title: t('Save'),
            disabled: methods.formState.isSubmitting || !methods.formState.isValid,
            loading: false
          }}
          textAlignment='center'
          input={{
            name: 'name',
            type: 'text',
            placeholder: t('Enter name'),
            register: 'name',
            validation: {
              required: true,
              minLength: 1
            }
          }}
          onSubmit={methods.handleSubmit(doRenameCategory)}
        />
      </FormProvider >
    </>
  );
};

export default CategoriesList;
