import { units } from '../pages/Financials/Subpanels/Precalculations/utils/units';

export default function getUnitLabel(value: any) {
  if (!value || typeof value !== 'string') return value || '';

  if (value === 'sqm') {
    value = 'square-meters';
  }

  const unit = units.find(unit => unit.value === value);

  return unit?.label || value || '';
}
