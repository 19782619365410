import i18n from '../../../../../i18n';

export const units = [
  { value: 'square-meters', label: i18n.t('Square metres (m²)') },
  { value: 'st', label: i18n.t('Pieces (pcs)') },
  { value: 'cubic-metres', label: i18n.t('Cubic metres (m³)') },
  { value: 'kilos', label: i18n.t('Kilograms (kg)') },
  { value: 'rounded-sum', label: i18n.t('Rounded sum (rs)') },
  { value: 'liters', label: i18n.t('Litres (l)') },
];
