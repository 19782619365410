import { IonCol, IonSelect, IonSelectOption } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../../components/UI';
import { useAppSelector } from '../../../../hooks';
import { setSelectedPrecalculation } from '../../../../reducers/precalculations';
import store from '../../../../store';

interface RegenerationFormProps {
  userPrecalculations: E2U.V1.Models.Precalculation[];
  projectPrecalculations: E2U.V1.Models.Precalculation[];
  generateBudget: () => void;
  isLocked: boolean;
}

const RegenerationForm: React.FC<RegenerationFormProps> = ({
  generateBudget,
  projectPrecalculations,
  userPrecalculations,
}) => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const isGeneratingBudget = useAppSelector((state) => state.loading.isLoading.generatingProductionBudget);
  const selectedBudget = useAppSelector((state) => state.productionBudget.productionBudget);

  const combinedPrecalculations = useMemo(() => {
    const items = {} as E2U.V1.Models.Precalculation[];

    [...userPrecalculations, ...projectPrecalculations].map((item) => {
      items[item.id] = item;

      return items;
    });

    return Array.from(Object.values(items)).sort((a, b) => a.name.localeCompare(b.name));
  }, [userPrecalculations, projectPrecalculations]);

  return (
    <>
      <IonCol size='auto'>
        <IonSelect
          onIonChange={(e) => {
            methods.setValue('precalculation_id', e.detail.value);
            methods.trigger('precalculation_id');
            store.dispatch(setSelectedPrecalculation(e.detail.value));
          }}
          label={t('Precalculation to generate from')}
          labelPlacement='stacked'
          placeholder={t('Select precalculation')}
          {...methods.register('precalculation_id', { required: true })}
        >
          {combinedPrecalculations.map((precalculation, index) => {
            return (
              <React.Fragment key={index}>
                {precalculation && precalculation.rows?.length === 0
                  ? null
                  : <IonSelectOption
                    key={index}
                    value={precalculation.id}>
                    {precalculation.name}
                  </IonSelectOption>
                }
              </React.Fragment>
            );
          })}
        </IonSelect>
      </IonCol>
      <IonCol size='auto' className='ion-text-right'>
        <BigUp.Buttons.Secondary
          color='default'
          size='small'
          title={t('Generate')}
          disabled={isGeneratingBudget || !methods.formState.isValid}
          {...selectedBudget?.rows?.length !== 0 && { type: 'submit' }}
          onClick={generateBudget}
        />
      </IonCol>
    </>

  );
};

export default RegenerationForm;
