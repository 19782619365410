import {
  IonCol,
  IonGrid, IonLoading,
  IonRow,
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../../api/networking';
import SkeletonTextThreeLines from '../../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../../../components/Toasts/Toasts';
import BigUp from '../../../../../components/UI';
import CreateButton from '../../../../../components/WorkPreparation/Rows/CreateButton';
import RowsList from '../../../../../components/WorkPreparation/Rows/List';
import { useAppSelector } from '../../../../../hooks';
import { setIsLoading } from '../../../../../reducers/loading';
import store from '../../../../../store';

interface RowsSettingPageProps {
  type: 'projects' | 'clients';
}

const RowsSettingPage: React.FC<RowsSettingPageProps> = ({ type }) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { category_id, client_id } = useParams<{ client_id: string, category_id: string }>();
  const id = useMemo(() => type === 'projects' ? selectedProjectId : client_id, [
    selectedProjectId,
    type,
    client_id
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const [category, setCategory] = useState<any>();
  const loadingRows = useAppSelector(state => state.loading.isLoading.fetchingRows);
  const loadingCategory = useAppSelector(state => state.loading.isLoading.fetchingCategory);

  const fetchRows = () => {
    if (!id) {
      return;
    }
    store.dispatch(setIsLoading({
      name: 'fetchingRows',
      value: true
    }));
    networking.get(`/api/v1/work_preparations/categories/${category_id}/rows?limit=9999`)
      .then((res: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<any>>) => {
        setRows(res.data.data.records);
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'fetchingRows',
          value: false
        }));
      });
  };

  const fetchCategory = () => {
    if (!category_id) {
      return;
    }
    store.dispatch(setIsLoading({
      name: 'fetchingCategory',
      value: true
    }));
    networking.get(`/api/v1/work_preparations/categories/${category_id}`)
      .then((res: E2U.V1.Response.Success<any>) => {
        setCategory(res.data.data);
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to fetch category. Please try again later or contact our support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'fetchingCategory',
          value: false
        }));
      });
  };

  useEffect(() => {
    fetchCategory();
    fetchRows();
  }, []);

  useEffect(() => {
    fetchRows();
  }, [type, id]);

  useEffect(() => {
    fetchCategory();
  }, [category_id]);

  return (
    <IonGrid>
      <CreateButton
        category={category}
        onRowCreated={() => fetchRows()}
        type={type}
        id={selectedProjectId ?? ''}
      />
      {(loadingRows || loadingCategory)
        ? (
          <IonRow>
            <IonCol>
              <SkeletonTextThreeLines />
            </IonCol>
          </IonRow>
        )
        : (
          <IonRow>
            <IonCol>
              <RowsList
                type={type}
                onRowChange={() => fetchRows()}
                rows={rows}
              />
            </IonCol>
          </IonRow>
        )}
    </IonGrid>
  );
};

export default RowsSettingPage;
