import { IonBackdrop, IonCol, IonContent, IonGrid, IonLabel, IonListHeader, IonModal, IonRow, isPlatform, useIonViewWillEnter } from '@ionic/react';
import { addSharp, chatboxOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { WorkPreparation } from '..';
import styles from './Sections.module.scss';
import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import EmptyList from '../../../../components/UI/EmptyList';
import { useAppSelector } from '../../../../hooks';
import type { CameraUploadResult } from '../../../../hooks/useCameraUpload';
import type { FileUploadResult } from '../../../../hooks/useFileUpload';
import AssessmentList from '../../AssessmentList';

interface SectionProps {
  selectedRows?: any[];
  cameraProps: CameraUploadResult;
  fileProps: FileUploadResult;
  sections: any[];
}

const Sections: React.FC<SectionProps> = (props) => {
  const [sections, setSections] = useState<any[]>([]);
  const [rowState, setRowState] = useState<{
    [key: string]: boolean
  }>({});
  const [openAssessmentModal, setOpenAssessmentModal] = useState<boolean>(false);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const [selectedSectionId, setSelectedSectionId] = useState<string | undefined>();
  const { t } = useTranslation();
  const methods = useFormContext<WorkPreparation>();
  const rows = methods.watch('rows');
  const isDesktop = isPlatform('desktop');

  const getProjectNodes = () => {
    if (!selectedProject?.id) return;
    networking.get(`/api/v1/projects/${selectedProject?.id}/work_preparation_users_choices`)
      .then((res) => {
        const nodes = res.data.data.map((record: any) => record.sections);
        const flattenedSections = nodes.flat();
        setSections(flattenedSections);
        const rowStateFromForm = rows.reduce((acc: any, row: any) => {
          acc[row.id] = { isModalOpen: false };
          return acc;
        }, {});
        setRowState(rowStateFromForm);
        setSections(res.data.data);
      });
  };

  const toggleModal = (id: string, isOpen: boolean) => {
    setRowState({
      ...rowState,
      [id]: isOpen
    });
  };
  const handleAssessmentRedirect = (sectionID: string) => {
    if (selectedProject?.id) {
      setSelectedSectionId(sectionID);
      setOpenAssessmentModal(true);
    }
  };

  const handleRowChecked = (rowIndex: number, row: any) => {
    const newRows = [...rows];
    if (rowIndex === -1) {
      newRows.push({
        id: row.id,
        active: true,
        description: '',
        files: []
      });
    } else {
      newRows[rowIndex].active = !newRows[rowIndex].active;
    }
    methods.setValue('rows', newRows);
  };
  const items = (passedRows: any[], requiredNote: boolean) => passedRows.map((row: any, index) => {
    const existingRowIndex = rows.findIndex((r: any) => r.id === row.id);
    const existingRow = rows[existingRowIndex];
    const noteReq = requiredNote;
    return {
      id: row.id,
      content: (
        <>
          <div className={styles['section-container__row']}>
            <div className={styles['section-container__row-content']}>
              <BigUp.Checkbox
                style={{
                  '--checkbox-background-checked': 'var(--bigup-gradient-secondary',
                  '--border-color-checked': 'var(--ion-color-light)'
                }}
                labelPlacement="start"
                labelProps={{
                  label: (row.name ?? ''),
                  className: 'white-space-normal'
                }}
                checked={existingRow?.active}
                handleCheckbox={() => handleRowChecked(existingRowIndex, row)}
              />
              <br />
              {existingRow?.active && (
                <BigUp.Buttons.Icon
                  onClick={() => toggleModal(row.id, true)}
                  fill='clear'
                  className='ion-no-margin'
                  padding={20}
                  icon={{
                    icon: chatboxOutline,
                    color: existingRow?.description ? 'success' : noteReq ? 'danger' : 'primary',
                    size: 'large',
                  }}
                  color={'light'}
                />
              )}
            </div>
            <IonLabel color='medium' className={styles['section-container__label']}>
              {existingRow?.description as string}
            </IonLabel>
          </div>
          <BigUp.MediaUploader
            cameraProps={props.cameraProps}
            fileProps={props.fileProps}
            title={row.name}
            id={row.id}
            index={index}
            isOpen={rowState[row.id] ?? false}
            dismiss={() => toggleModal(row.id, false)}
          />
        </>
      )
    };
  });

  useEffect(() => {
    if (selectedProject?.id) {
      getProjectNodes();
    }
  }, [selectedProject?.id]);

  useIonViewWillEnter(() => {
    getProjectNodes();
  }, [sections]);
  return (
    <>
      {!selectedProject?.id && (
        <EmptyList
          message={t('Please select a project to continue.')}
          title={t('No project selected')}
        />
      )}

      {sections && sections.map((section) => (
        <React.Fragment key={section.id}>
          {section.rows.length === 0
            ? (
              <>
                <div>
                  <IonListHeader className={`${styles['section-container__header']} ion-no-padding`}>
                    <BigUp.Label.Thick className='ion-no-margin' label={section.name} color={'medium'} />
                  </IonListHeader>
                </div>
                <IonGrid>
                  <IonRow className='ion-justify-content-between'>
                    <IonCol size='auto'>
                      <BigUp.Label.Regular className='ion-no-margin' label={t('No rows has been selected')} />
                    </IonCol>
                    <IonCol size='auto' className='ion-no-padding ion-padding-vertical'>
                      <BigUp.Buttons.Regular
                        title={t('Add {section} assessment', 'Add {section} assessment', { section: section.name })}
                        onClick={() => handleAssessmentRedirect(section.id)}
                        fill='clear'
                        size='small'
                        icon={{
                          icon: addSharp,
                          slot: 'start'
                        }}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </>
            )
            : (
              <BigUp.ItemList
                header={{ title: t(section.name) }}
                items={items(section.rows, section.required_note)}
                actionButton={{
                  title: t('Add {section} assessment', 'Add {section} assessment', { section: section.name }),
                  onClick: () => {
                    handleAssessmentRedirect(section.id);
                  },
                }}
              />
            )}
          <IonModal
            isOpen={openAssessmentModal}
            onDidDismiss={() => {
              setOpenAssessmentModal(false);
              getProjectNodes();
            }}
            className={`${styles['section-container__modal']} ${isDesktop ? styles['section-container__modal--desktop'] : ''}`}
          >
            <IonContent scrollY className={styles['section-container__modal-content']}>
              <AssessmentList
                dismiss={() => setOpenAssessmentModal(false)}
                sectionId={selectedSectionId}
              />
            </IonContent>
          </IonModal>
        </React.Fragment>
      ))}
    </>
  );
};

export default Sections;
