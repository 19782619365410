import type { E2U } from '@techlove/easy2use-typings';
import { phone } from 'phone';

import type { InputProps } from '../../components/UI/Inputs/Input.interface';
import i18n from '../../i18n';
import { regex } from '../../regex/regex';
import { validatePersonNumber } from '../Onboarding/Components/form/Stages/StageOne/formlist';

export const validatePhoneNumber = (value: string) => {
  if (phone(value, { country: 'SWE', strictDetection: true }).isValid) {
    return true;
  }
  if (phone(value, { strictDetection: true }).isValid) {
    return true;
  }
  return i18n.t('Please enter a valid phone number');
};

const name_inputs: InputProps<E2U.V1.Models.User>[] = [
  {
    id: 'first_name',
    name: 'first_name',
    customLabel: i18n.t('First name'),
    type: 'text',
    inputMode: 'text',
    placeholder: i18n.t('First name'),
    autocomplete: 'given-name',
    register: 'first_name',
    enterkeyhint: 'next',
    showErrorBadge: true,
    validation: {
      required: i18n.t('Your first name is required') as keyof E2U.V1.Models.User,
      maxLength: { value: 50, message: i18n.t('Name is too long'), },
      minLength: { value: 1, message: i18n.t('Name is too short'), },
      pattern: { value: regex.regex_alphabetical, message: i18n.t('First name must be alphabetical') },
    }
  },
  {
    id: 'last_name',
    name: 'last_name',
    customLabel: i18n.t('Last name'),
    type: 'text',
    inputMode: 'text',
    autocomplete: 'family-name',
    placeholder: i18n.t('Last name'),
    register: 'last_name',
    enterkeyhint: 'next',
    showErrorBadge: true,
    validation: {
      required: i18n.t('Your last name is required') as keyof E2U.V1.Models.User,
      maxLength: { value: 50, message: i18n.t('Name is too long'), },
      minLength: { value: 1, message: i18n.t('Name is too short'), },
      pattern: { value: regex.regex_alphabetical, message: i18n.t('Last name must be alphabetical') },
    }
  },
];

const swedish_personal_number:InputProps<E2U.V1.Models.User> = {
  id: 'ssn',
  name: 'ssn',
  customLabel: i18n.t('Personal number'),
  type: 'number',
  inputMode: 'numeric',
  autocomplete: 'on',
  enterkeyhint: 'next',
  placeholder: i18n.t('Personal number'),
  disabled: true,
  register: 'ssn',
  showErrorBadge: true,
  validation: {
    required: i18n.t(`Your personal number is required`) as keyof E2U.V1.Models.User,
    maxLength: { value: 12, message: i18n.t(`Personal number is too long`), },
    minLength: { value: 2, message: i18n.t(`Personal number is too short`), },
    validate: (value: string) => validatePersonNumber(value) || i18n.t('Please enter a valid personal ID-number') as keyof E2U.V1.Models.User,
  }
};

const contact_inputs: InputProps<E2U.V1.Models.User>[] = [
  {
    id: 'phone_number',
    name: 'phone_number',
    customLabel: i18n.t('Phone number'),
    type: 'tel',
    autocomplete: 'tel',
    inputmode: 'tel',
    enterkeyhint: 'next',
    placeholder: i18n.t('Phone number'),
    register: 'phone_number',
    showErrorBadge: true,
    validation: {
      required: i18n.t('Your phone number is required') as keyof E2U.V1.Models.User,
      maxLength: { value: 50, message: i18n.t('Phone number is too long'), },
      minLength: { value: 1, message: i18n.t('Phone number is too short'), },
      pattern: { value: regex.regex_phone, message: i18n.t('Phone number can\'t contain letters') },
      validate: (value) => validatePhoneNumber(value) as boolean,
    }
  },
  {
    id: 'email',
    name: 'email',
    customLabel: i18n.t('Email'),
    type: 'email',
    inputmode: 'email',
    autocomplete: 'email',
    enterkeyhint: 'next',
    placeholder: i18n.t('E-mail'),
    register: 'email',
    showErrorBadge: true,
    validation: {
      required: i18n.t('Your email is required') as keyof E2U.V1.Models.User,
      max: { value: 50, message: i18n.t('Email is too long'), },
      min: { value: 1, message: i18n.t('Email is too short'), },
      pattern: { value: regex.regex_email, message: i18n.t('Please enter a valid email in format example@example.com') }
    }
  }
];

const registration_inputs = {
  name_inputs,
  contact_inputs,
  swedish_personal_number,
};

export default registration_inputs;
