import { IonCol, IonRow, IonText, useIonRouter } from '@ionic/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';

const WorksiteCodeAccess: React.FC = () => {
  const { t } = useTranslation();
  const methods = useForm();
  const router = useIonRouter();

  const getWorksiteAccessCode = () => {
    // Get worksite access code from URL
    const urlParams = new URLSearchParams(window.location.search);
    const worksiteCodeId = urlParams.get('worksite_access_code');

    networking.get(`/api/v1/........${worksiteCodeId}`)
      .then((response) => {
        methods.setValue('worksite_access_code', response.data.project_code);
      })
      .catch(() => {
        toasters.createToast({
          message: t('Failed to get the entered code. Please try again later or fill it in manually.')
        }, 'error');
      });
  };

  const submitWithWorksiteAccessCode = (data: any) => {
    networking.post('/api/v1/........', {
      worksite_access_code: data.worksite_access_code
    })
      .then(() => {
        // Redirect to correct page, site access requests I belive
        router.push('TO URL');
      })
      .catch(() => {
        toasters.createToast({
          message: t('Failed to submit the entered code. Please check if it is correctly entered.')
        }, 'error');
      });
  };

  return (
    <>
      <IonRow className='ion-justify-content-center ion-padding-horizontal ion-no-padding ion-align-items-center'>
        <IonCol size='auto' className='ion-no-padding ion-margin-top'>
          <BigUp.Label.Thick color={'medium'} label={t('Do you have a code for a construction site?')} />
        </IonCol>
        <IonCol size='auto' className='ion-margin-top'>
          <BigUp.Popovers.Help>
            <span>
              {t('If you have a code for a construction site, you can enter it here to get started with BigUp. Or if you have a QR code to scan it will be filled in automatically.')}
            </span>
          </BigUp.Popovers.Help>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-center ion-padding-horizontal ion-padding-bottom'>
          <IonText color={'medium'}>
            <span>
              {t('If the project administrator has given you a construction site code, you can enter it in the field below for direct access to the project')}
            </span>
          </IonText>
        </IonCol>
      </IonRow>
      <FormProvider {...methods}>
        <IonRow className='ion-justify-content-center'>
          <IonCol size='11'>
            <BigUp.OutlinedInput
              disabled
              placeholder={t('This feature is coming soon')}
              register={'project_code'}
              validation={{
                required: `${t('Worksite code is required for this.')}`
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow className='ion-justify-content-center ion-padding-horizontal ion-padding-bottom'>
          <IonCol size='11'>
            <BigUp.Buttons.Primary
              disabled
              title={t('Enter worksite')}
              expand='block'
              type='submit'
            />
          </IonCol>
        </IonRow>
      </FormProvider>
    </>
  );
};

export default WorksiteCodeAccess;
